import { Box, SelectChangeEvent, SxProps } from "@mui/material"
import { useEffect, useState } from "react"
import { XJSelect } from "UI Elements/XJSelect"

export const kLocalStorageSelectedValue = 'SelectedDeviceID'

interface CameraSelectorProps {

    devices: MediaDeviceInfo[]
    selectedID?: string

    sx?: SxProps
    disabled?: boolean

    onSelect: (deviceId: string) => void 
}

export default function CameraSelector(props: CameraSelectorProps) {

    const selectedDevice = props.devices.find(vd => vd.deviceId === props.selectedID)

    const [options, setOptions] = useState<string[]>([])
    const [titles, setTitles] = useState<string[]>([])

    useEffect(() => {
        setOptions(props.devices.map(vd => vd.deviceId))
        setTitles(props.devices.map(vd => formatTitle(vd.label)))
    }, [props.devices])

    const onDeviceSelected = (e: SelectChangeEvent) => {
        if (e.target.value !== selectedDevice?.deviceId) {
            props.onSelect(e.target.value)
        }
    }


    // MARK: - Utils 

    const formatTitle = (title: string) => {
        return removeCameraID(title)
    }

    const removeCameraID = (title: string) => {
        const regex = /\([0-9A-Fa-f]+:[0-9A-Fa-f]+\)/g
        return title.replace(regex, '')
    }


    // MARK: - Render 

    const styles = {
        root: {
            ...props.sx,
            width: "100%"
        } as SxProps,
        select: {
            width: '100%'
        }
    }

    return (
        <Box sx={styles.root}>
            <XJSelect 
                options={options}
                titles={titles}
                value={selectedDevice?.deviceId ?? ""}
                sx={styles.select}
                onChange={onDeviceSelected}
            />
        </Box>
    )
}