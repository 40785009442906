import { Backdrop, Box, Dialog, Fade, Modal as MuiModal, SxProps } from "@mui/material"


import { ReactNode } from "react"
import {  theme, whiteDefault } from "XJumpTheme"

type CloseCallback = (event: object, reason: string) => void

interface XJModalProps {
    children: ReactNode
    show: boolean
    onClose: CloseCallback,
    width?: string | number 
}

export function XJModal(props: XJModalProps) {

    const styles = {
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: props.width ? props.width : 'auto',
            marginX: 'auto',
            zIndex: theme.zIndex.modal,
            "& .MuiDialog-container": {
                width: '100%'
            }
           
        } as SxProps,
        alert: {
            minWidth: '100px', 
            minHeight: '100px',
            width: 'auto',
            height: 'auto',
            backgroundColor: whiteDefault,
            outlineStyle: "none",
            borderRadius: '2px',
            display: "flex", 
            flexDirection: "column",
            alignItems: "center",
            justifyContent: 'space-around',
            gap: 16,
            padding: 32
        } as SxProps
    }

    return (
        <Dialog
            sx={styles.modal}
            open={props.show}
            onClose={props.onClose}
            closeAfterTransition
            
            PaperProps={{
                style: {
                
                    width: '100%',
                    maxWidth: props.width ?  '100%' : 'auto'


            }}}
            
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500
            }}
        >
            <Fade in={props.show}>
                <Box sx={styles.alert}>
                    {props.children}
                </Box>
            </Fade>
        </Dialog>
    )
}