
// Attempts to turn on permissions for camera and mic access.  If it fails, or persmission are not there, shows and alert with an action button

import { Box, SxProps, Typography } from "@mui/material"
import { VideoDimensions } from "Common/Enums"
import { useEffect, useRef, useState } from "react"
import { UAMessageWarning } from "UI Elements/XJIcons"
import { whiteDefault } from "XJumpTheme"


const BannerStyle: SxProps =
{

    position: 'absolute',
    top: 8,
    left: 0,
    right: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
    backgroundColor: '#E05534',
    color: 'white',
    borderRadius: '2px',
    pl: 8,
    pr: 10,
    py: 4,
    width: 'fit-content'

}

export default function MicCameraPermissionAlert() {

    const [state, setState] = useState('')

    useEffect(() => {

        navigator.mediaDevices.getUserMedia({
            audio: true,
            video: {...VideoDimensions}
        })

            .then(val => {

               // stream.current = val
                val.getTracks().forEach(track => track.stop())   // close the camera and audio

            }, error => {

                switch (error.name) {
                    case 'NotAllowedError':
                        setState('Pitchura is blocked from accessing the camera. Please enable and try again.')
                        break

                    case 'NotReadableError':
                        setState('Unable to access camera, although permissions were granted.')
                        break

                    default:

                        setState('Unspecific error occured while accessing the camera.')
                }


            })

    }, [])


    return <>

        {state !== 'OK' && state !== '' && <Box sx={BannerStyle} display={'flex'} justifyContent={'center'} alignItems={'center'} gap={4} >

       <UAMessageWarning/>
           <Typography color={whiteDefault} variant='h3'> {state}</Typography>

        </Box>}
    </>

}
