import { OptionsObject, SnackbarKey, useSnackbar } from "notistack"



interface IAlerts {
    success: (message: string) => void
    warning: (message: string) => void
    error: (message?: string) => void
    working: (msg?: string) => void
    clearWorking: () => void
    APIError: () => void,
    successSave: () => void
}



const alertError: OptionsObject = {

    variant: 'error',
    persist: true

}

const alertSuccess: OptionsObject = {

    variant: 'success'

}

const alertWorking: OptionsObject = {

    variant: 'working',
    persist: true,
}

const ProcessingMsgs = Array<SnackbarKey>()


export function useAlert(): IAlerts {

    const { enqueueSnackbar, closeSnackbar } = useSnackbar()


    const working = (msg?: string) => {

        const key = enqueueSnackbar(msg ? msg : 'Working...', alertWorking)
        ProcessingMsgs.push(key)

    }


    const clearWorking = () => {

        closeSnackbar()

    }

    const success = (message: string) => {
        clearWorking()
        enqueueSnackbar(message, alertSuccess)
    }

    const warning = (message: string) => {
        return

    }

    const error = (message: string) => {
        clearWorking()
        enqueueSnackbar(message ? message : 'An error has occured', alertError)
    }

    const APIError = () => {
        clearWorking()
        error("An error happened on the server")

    }

    const successSave = () => {
        clearWorking()
        success ("Saved successfully")
    }

    

    return {

        success,
        warning,
        error,
        working,
        clearWorking,
        APIError,
        successSave 

    } as IAlerts

}