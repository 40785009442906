import { Autocomplete, Box, Chip, SxProps, TextField } from "@mui/material"
import { pitchTags } from "Common/PitchTags"

import { searchIconAdornment, selectBox, selectBoxAuto, copySecondaryBold, lightPrimaryOverlay8, secondaryDefault, copySecondaryRegular, SVGStyle } from "XJumpTheme"
import { ReactComponent as NoMatch } from '../svg/no-match.svg'
import { SyntheticEvent, useMemo } from "react"

export interface IPropsPitchTag {
    selectedTag: string[] | undefined
    onChangedSelectedTag: (value: string[] | undefined) => void
    autoWidth?: boolean
    autofocus?: boolean
}

export function XJPitchTag(props: IPropsPitchTag) {

    const onChange = (event: SyntheticEvent, value: string[] | undefined, reason: any) => {
        props.onChangedSelectedTag(value)
    }

    const selectedValues = useMemo(() => {
        return pitchTags.filter((v) => props.selectedTag?.contains(v))
    }, [pitchTags, props.selectedTag]);

    const autocompleteSX = {
        ...(props.autoWidth ? selectBoxAuto : selectBox),
        '.MuiChip-root':
        {
            ...copySecondaryBold,
            borderRadius: '2px',
            background: lightPrimaryOverlay8,
            borderWidth: 0
        },
        '.MuiOutlinedInput-root':
        {
            minHeight: '56px'
        }

    } as SxProps


    const NoOptions = () => <Box>

        <Box mt={12} mb={16} mx={16} display={'flex'} alignItems={'center'} gap={8} flexDirection={'column'}>
            <Box >
                <NoMatch style={SVGStyle} />
            </Box>

            <Box sx={{ ...copySecondaryRegular, color: secondaryDefault }}>

                No space matches your search.

            </Box>
        </Box>
    </Box>


    return <>

        <Autocomplete

            multiple
            
            sx={autocompleteSX}
            noOptionsText={<NoOptions />}
            id="tags-outlined"
            options={pitchTags.sort()}
            value={selectedValues}  
            onChange={(e, v, r) => {onChange(e, v, r)}}
            getOptionLabel={(option) => option}
            filterSelectedOptions
            groupBy={(option) => option.charAt(0)}
            renderTags={(value: readonly string[], getTagProps) =>
                value.map((option: string, index: number) => (
                    <Chip
                        variant="outlined"
                        label={option}
                        //   deleteIcon={<Icon18Close />}
                        {...getTagProps({ index })} 
                    />
                ))
            }
            renderInput={(params) => (
                <TextField
                    
                    autoFocus={props.autofocus}
                    placeholder={selectedValues.length === 0 ?  "Type to search or select" : undefined}
                
                    {...params}
                    InputProps={{
                        ...params.InputProps,
                        startAdornment: (<>
                            <Box ml={'5px'}> {searchIconAdornment} </Box>
                            {params.InputProps.startAdornment}
                        </>)
                    }}

                    color={'secondary'}
                />
            )}
        />
    </>

}