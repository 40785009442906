import { Box, Grid } from '@mui/material'
import { UserModel } from 'Model/UserModel'
import { useAuth } from 'hooks/UseAuth'
import { forwardRef, useContext, useEffect, useImperativeHandle, useState } from 'react'
import XJInputBox from 'UI Elements/XJInputBox'
import { valueOrEmpty } from 'tools/valueOrEmpty'
import { XJUserLogo } from 'UI Elements/XJUserLogo'
import XJStatusBadge, { BadgeColor } from 'UI Elements/XJStatusBadge'
import { ProfileVerifiedIcon } from 'UI Elements/XJIcons'
import BigAlertContext from 'UI Elements/XJBigAlerts'


interface IFounderProfileProps {

    onSaveSuccess: () => void,
    onSaveFailure: (msg: string) => void,
    onDataStatusChange?: (complete: boolean) => void
    onDataIsDirty?: (IsDirty: boolean) => void


}

export const LinkedInProfileURL = 'https://www.linkedin.com/in/'

const MobilePhone = 'Mobile Phone'
const LinkedIn = 'LinkedIn Profile'
const CompanyName = 'Company Name'
const CompanyLogo = 'Company Logo (URL)'
const LastName = 'Last Name'
const FirstName = 'First Name'

const countryList = [
    "Afghanistan",
    "Albania",
    "Algeria",
    "American Samoa",
    "Andorra",
    "Angola",
    "Anguilla",
    "Antarctica",
    "Antigua and Barbuda",
    "Argentina",
    "Armenia",
    "Aruba",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas (the)",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bermuda",
    "Bhutan",
    "Bolivia (Plurinational State of)",
    "Bonaire, Sint Eustatius and Saba",
    "Bosnia and Herzegovina",
    "Botswana",
    "Bouvet Island",
    "Brazil",
    "British Indian Ocean Territory (the)",
    "Brunei Darussalam",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Cabo Verde",
    "Cambodia",
    "Cameroon",
    "Canada",
    "Cayman Islands (the)",
    "Central African Republic (the)",
    "Chad",
    "Chile",
    "China",
    "Christmas Island",
    "Cocos (Keeling) Islands (the)",
    "Colombia",
    "Comoros (the)",
    "Congo (the Democratic Republic of the)",
    "Congo (the)",
    "Cook Islands (the)",
    "Costa Rica",
    "Croatia",
    "Cuba",
    "Curaçao",
    "Cyprus",
    "Czechia",
    "Côte d'Ivoire",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic (the)",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "Equatorial Guinea",
    "Eritrea",
    "Estonia",
    "Eswatini",
    "Ethiopia",
    "Falkland Islands (the) [Malvinas]",
    "Faroe Islands (the)",
    "Fiji",
    "Finland",
    "France",
    "French Guiana",
    "French Polynesia",
    "French Southern Territories (the)",
    "Gabon",
    "Gambia (the)",
    "Georgia",
    "Germany",
    "Ghana",
    "Gibraltar",
    "Greece",
    "Greenland",
    "Grenada",
    "Guadeloupe",
    "Guam",
    "Guatemala",
    "Guernsey",
    "Guinea",
    "Guinea-Bissau",
    "Guyana",
    "Haiti",
    "Heard Island and McDonald Islands",
    "Holy See (the)",
    "Honduras",
    "Hong Kong",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iran (Islamic Republic of)",
    "Iraq",
    "Ireland",
    "Isle of Man",
    "Israel",
    "Italy",
    "Jamaica",
    "Japan",
    "Jersey",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "Korea (the Democratic People's Republic of)",
    "Korea (the Republic of)",
    "Kuwait",
    "Kyrgyzstan",
    "Lao People's Democratic Republic (the)",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Macao",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Marshall Islands (the)",
    "Martinique",
    "Mauritania",
    "Mauritius",
    "Mayotte",
    "Mexico",
    "Micronesia (Federated States of)",
    "Moldova (the Republic of)",
    "Monaco",
    "Mongolia",
    "Montenegro",
    "Montserrat",
    "Morocco",
    "Mozambique",
    "Myanmar",
    "Namibia",
    "Nauru",
    "Nepal",
    "Netherlands (the)",
    "New Caledonia",
    "New Zealand",
    "Nicaragua",
    "Niger (the)",
    "Nigeria",
    "Niue",
    "Norfolk Island",
    "Northern Mariana Islands (the)",
    "Norway",
    "Oman",
    "Pakistan",
    "Palau",
    "Palestine, State of",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines (the)",
    "Pitcairn",
    "Poland",
    "Portugal",
    "Puerto Rico",
    "Qatar",
    "Republic of North Macedonia",
    "Romania",
    "Russian Federation (the)",
    "Rwanda",
    "Réunion",
    "Saint Barthélemy",
    "Saint Helena, Ascension and Tristan da Cunha",
    "Saint Kitts and Nevis",
    "Saint Lucia",
    "Saint Martin (French part)",
    "Saint Pierre and Miquelon",
    "Saint Vincent and the Grenadines",
    "Samoa",
    "San Marino",
    "Sao Tome and Principe",
    "Saudi Arabia",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Sint Maarten (Dutch part)",
    "Slovakia",
    "Slovenia",
    "Solomon Islands",
    "Somalia",
    "South Africa",
    "South Georgia and the South Sandwich Islands",
    "South Sudan",
    "Spain",
    "Sri Lanka",
    "Sudan (the)",
    "Suriname",
    "Svalbard and Jan Mayen",
    "Sweden",
    "Switzerland",
    "Syrian Arab Republic",
    "Taiwan",
    "Tajikistan",
    "Tanzania, United Republic of",
    "Thailand",
    "Timor-Leste",
    "Togo",
    "Tokelau",
    "Tonga",
    "Trinidad and Tobago",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Turks and Caicos Islands (the)",
    "Tuvalu",
    "Uganda",
    "Ukraine",
    "United Arab Emirates (the)",
    "United Kingdom of Great Britain and Northern Ireland (the)",
    "United States Minor Outlying Islands (the)",
    "United States of America",
    "Uruguay",
    "Uzbekistan",
    "Vanuatu",
    "Venezuela (Bolivarian Republic of)",
    "Viet Nam",
    "Virgin Islands (British)",
    "Virgin Islands (U.S.)",
    "Wallis and Futuna",
    "Western Sahara",
    "Yemen",
    "Zambia",
    "Zimbabwe",
    "Åland Islands"
];


const USStates = ['Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut', 'Delaware', 'District of Columbia', 'Florida', 'Georgia', 'Guam', 'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Maryland', 'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey', 'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma', 'Oregon', 'Pennsylvania', 'Puerto Rico', 'Rhode Island', 'South Carolina', 'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming']


interface formData {

    Email: string | undefined
    [FirstName]: string | undefined
    [LastName]: string | undefined
    Title: string | undefined
    [MobilePhone]: string | undefined
    [LinkedIn]: string | undefined
    [CompanyName]: string | undefined
    [CompanyLogo]: string | undefined
    City: string | undefined | undefined
    Country: string
    State: string | undefined



}
export const FounderProfile = forwardRef((props: IFounderProfileProps, ref) => {

    const auth = useAuth()
    //  const alert = useAlert()
    const [form, setForm] = useState<formData>()

    // const [formErrors, setFormErrors] = useState<formData>({} as formData)

    useImperativeHandle(ref, () => ({
        handleSave(ev?: MouseEvent) {
            Save()
        }
    }))

    const BigAlert = useContext(BigAlertContext)
    
    const founder = auth.user.User as UserModel

    const Save = () => {

        // save to DB 


        if (!isDataComplete()) {

            BigAlert.warning ("Some fields are missing. Please fill all required fields.", 'Missing data')          
            return

        }

        if (form) {
            if (!form.Email || !form.Email.isEmail()) {

                BigAlert.warning ("The email address is invalid", 'Invalid email')              
                return
            }

        }


        /* 
        
                if (hasErrors()) {
                    alert.error("There are errors in some fields")
                    return
                } */


        auth.UpdateUser(props.onSaveSuccess, props.onSaveFailure, founder.id, form?.Email, form?.[MobilePhone], form?.[FirstName], form?.[LastName], form?.Title, form?.[LinkedIn], form?.City, form?.[CompanyLogo], form?.[CompanyName], form?.Country, form?.State)

    }

    /*  const setErrorFlag = (name: string, flag: boolean) => {
 
         const updatedValue = { [name]: flag }
         setFormErrors(value => ({
 
             ...value, ...updatedValue
         })
 
         )
     }
 
     const hasErrors = () => {
 
         return Object.values(formErrors).includes(false)
     }
  */

    useEffect(() => {

        if (props.onDataStatusChange) props.onDataStatusChange(isDataComplete())


    }, [form])




    const isDataComplete = (): boolean => {

        const isEmpty = form?.Email?.isEmpty() ||
            form?.[FirstName]?.isEmpty() ||
            form?.[LastName]?.isEmpty() ||
            form?.Title?.isEmpty() ||
            form?.[MobilePhone]?.isEmpty() ||
            form?.[LinkedIn]?.isEmpty() ||
            form?.[CompanyName]?.isEmpty() ||
          //  form?.[CompanyLogo]?.isEmpty() ||
            form?.City?.isEmpty() ||
            form?.Country?.isEmpty()


        return isEmpty === undefined ? false : !isEmpty

    }

    const Dirty = () => {

        if (props.onDataIsDirty) props.onDataIsDirty(true)
    }

    useEffect(() => {

        setForm({
            Email: valueOrEmpty(founder.email),
            [FirstName]: valueOrEmpty(founder.firstName),
            [LastName]: valueOrEmpty(founder.lastName),
            Title: valueOrEmpty(founder.title),
            [MobilePhone]: valueOrEmpty(founder.phone),
            [LinkedIn]: valueOrEmpty(founder.linkedInProfileURL),
            [CompanyName]: valueOrEmpty(founder.companyName),
            [CompanyLogo]: valueOrEmpty(founder.companyLogo),
            City: valueOrEmpty(founder.companyCity),
            Country: valueOrEmpty(founder.companyCountry) === '' ? "United States of America" : founder.companyCountry!,
            State: valueOrEmpty(founder.companyState) === '' ? 'Alabama' : founder.companyState
        })

        if (props.onDataStatusChange) props.onDataStatusChange(isDataComplete())

    }, [founder])


    return <>
        <Grid container>
            <Grid item xs={12} display='flex'>
                <XJInputBox
                    name={'Email'}
                    value={form!}
                    SetDirty={Dirty}
                 //   validationError={'Not a valid email address'}
                    /* validator={(value: string) => {

                        let hasErrors = false

                        const regex = /^(([^<>()[\]\\., :\s@"]+(\.[^<>()[\]\\., :\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

                        if (regex.test(value)) hasErrors = true

                        setErrorFlag('Email', hasErrors)
                        return hasErrors

                    }} */
                    setValue={setForm}
                    placeholder={'email address'}

                />
                <Box
                    display={(founder.email === form?.Email && founder.emailVerified) ? 'block' : 'none'}
                    pl={'16px'}
                    my={'auto'}
                    top={'-4px'}
                    position={'relative'}

                >
                    <XJStatusBadge icon={<ProfileVerifiedIcon />} text={'Verified'} type={BadgeColor.green} />

                </Box>
            </Grid>
            <Grid item xs={12}>
                <Box display={'flex'} gap={16}>
                    <XJInputBox name={FirstName} value={form!} setValue={setForm} SetDirty={Dirty} />
                    <XJInputBox name={LastName} value={form!} setValue={setForm} SetDirty={Dirty} />
                    <XJInputBox name={'Title'} value={form!} setValue={setForm} SetDirty={Dirty} placeholder={'Founder, CEO, CTO'} />

                </Box>
            </Grid>

            <Grid item xs={12}>
                <Box display={'flex'} gap={16}>

                    <XJInputBox phoneInput name={MobilePhone} value={form!} setValue={setForm} SetDirty={Dirty} />

                    <XJInputBox
                        name={LinkedIn}
                        value={form!}
                        setValue={setForm}
                        placeholder={'Public linkedIn profile URL'}
                        SetDirty={Dirty} />

                </Box>
            </Grid>

            <Grid item xs={12}>
                <Box display={'flex'} gap={16}>
                    <XJInputBox name={CompanyName} value={form!} setValue={setForm} SetDirty={Dirty} />
                    <XJInputBox name={'City'} value={form!} setValue={setForm} SetDirty={Dirty} />
                    <XJInputBox name={'Country'} value={form!} setValue={setForm} options={countryList} SetDirty={Dirty} />
                    <Box display={form && form.Country === 'United States of America' ? 'box' : 'none'}  >
                        <XJInputBox name={'State'} value={form!} setValue={setForm} options={USStates} SetDirty={Dirty} />
                    </Box>

                </Box>
            </Grid>
            <Grid item xs={12}>
                <Box display={'flex'} gap={16}>
                    <XJInputBox name={CompanyLogo} value={form!} setValue={setForm} SetDirty={Dirty} />
                    <Box display={'flex'} alignItems={'center'}>
                        <Box sx={{ top: '-5px', position: 'relative' }}>
                            <XJUserLogo src={form?.[CompanyLogo]} />
                        </Box>

                    </Box>
                </Box>

            </Grid>


        </Grid >
    </>
})