
import { Box, SxProps } from "@mui/material"
import { forwardRef, Ref, useEffect, useImperativeHandle, useRef, useState } from "react"
import { darkPrimaryLight80, secondaryDefault, TeleprompterFont, whiteDefault } from "XJumpTheme"

interface ITeleprompterProps {
    content: string,
    speedDelay: number,
    inFullScreen: boolean,
    pauseDuration?: number
}

export interface TeleprompterAPI {

    reset: () => void
    start: () => void
    stop: () => void
}

export const Teleprompter = forwardRef((props: ITeleprompterProps, ref: Ref<TeleprompterAPI>) => {


    const Container: SxProps =
    {
        marginX: 'auto',
        width: '100%',
        height: '225px', //150px',
        backgroundColor: secondaryDefault,
        whiteSpace: 'break-spaces',
        paddingX: '44px'

    }

    const Item: SxProps =
    {
        paddingX: '5px',
        paddingY: '10px',
        color: darkPrimaryLight80,
        ...TeleprompterFont
    }

    const HighlightedItem: SxProps =
    {
        color: whiteDefault

    }

    const content = props.content
    const [Index, setIndex] = useState(0)


    const parentDiv = useRef<HTMLDivElement>(null)
    const ContentItems = content.split(' ')

    useImperativeHandle(ref, () => ({ reset, start, stop }))


    const timer = useRef<ReturnType<typeof setInterval> | null>(null)

    const speed = 200 * props.speedDelay


    const reset = () => {

        if (timer.current) clearInterval(timer.current)
        setIndex(0)
        
    }

    const start = () => {

    //    setIndex(0)

        if (timer.current) clearInterval(timer.current)

        timer.current = setInterval(UpdateIndex, speed)

    }

    const stop = () => {

        if (timer.current) clearInterval(timer.current)
    }

    useEffect(() => {

        if (!(props.inFullScreen)) stop()


    }, [props.inFullScreen])


    useEffect(() => {

        return () => {

            if (timer.current) clearInterval(timer.current)
        }

    }, [])

    const Placeholders = () => {

        const items = []

        for (let i = 0; i < 50; i++) {
            items.push(<Box key={`telePlaceholders${i}`} width={'50px'} component="span"> &nbsp;</Box>)
        }

        return items

    }

    const UpdateIndex = () => {
        if (Index < ContentItems.length) {

           // const delay = speed

            if (ContentItems[Index] === '<pause>') {

                // timer.current   delay = (props.pauseDuration ? props.pauseDuration : 2) * 1000
            }

            setIndex(current => current + 1)
        }
    }

    const scrollToElement = (smooth?: boolean) => {

        if (Index === 0) {
            const el = document.querySelector<HTMLElement>(`[data-index='0']`)

            if (el) {
                el.scrollIntoView({ block: 'center', behavior: smooth ? "smooth" : undefined })
               
                return
            }

        }

        const el = document.querySelector<HTMLElement>(`[data-index='${Index - 1}']`)

        if (el) {
            el.scrollIntoView({ block: 'center', behavior: smooth ? "smooth" : undefined })
        }

    }

    useEffect(() => {

        scrollToElement(true)

    }, [Index])

    return <Box sx={Container}
        display={"flex"}
        flexWrap={'wrap'}
        overflow='hidden'
        id='topDiv'
        ref={parentDiv}
        justifyContent={'space-between'} >
        
        <Box
            width={'100%'}
            component="span"
            key={`preitems`}
            sx={Item}
            data-index={-1}>
           
            &nbsp;
        </Box>

        {ContentItems.map((item, i) => {

            if (item.startsWith('<') && item.endsWith('>')) return null

            return <Box
                width={'auto'}
                component="span"
                key={`item${i}`}
                data-index={i}
                sx={[Item, i < Index ? HighlightedItem : {}]}>

                {item}

            </Box>
        }

        )}

        {Placeholders()}

    </Box>

})