import { Box, SxProps, Tooltip } from "@mui/material";
import { ReactElement } from "react";


export function XJTooltip(props: {
    children: ReactElement,
    title: string,
    placement?: 'right' | 'bottom' | 'top',
    disableHover?: boolean
    sx?: SxProps
}) {

    const sx: SxProps = {
        width: '100%', 
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent :'center',
        ...props.sx
    }

    return <Tooltip title={props.title} placement={props.placement ? props.placement : 'right' } enterDelay={1000} disableHoverListener={props.disableHover}>
        <Box sx={sx}>
            {props.children}
        </Box>
    </Tooltip>

}