import { OAuthProvider } from "Common/Enums"
import { useAlert } from "hooks/UseAlert"
import { useAuth } from "hooks/UseAuth"
import { useNetworkActivity } from "hooks/UseOverlay"
import { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import Login from "./Login"
import { RoutingController } from "Controllers/RoutingController"
import { useSpinner } from "UI Elements/XJSpinner"
import { truncate } from "fs/promises"


export function Oauth(props: { provider: string }) {

  const history = useHistory()
  const queryString = window.location.search
  const auth = useAuth()

  const spinner = useSpinner()

  const [showLogin, setShowLogin] = useState (false)
  

  // TODO: cancel network requequest on modal close if the user agrees 
  const onCancelLoading = () => {
    alert("Are you sure you want to cancel?")
  }
  //const [showLoader, hideLoader] = useNetworkActivity({title: "Logging in...", onClose: onCancelLoading})
  const showAlert = useAlert()



  useEffect(() => {
    
    spinner(true)

    switch (props.provider) {
      case 'google':
        auth.OauthCallback(OAuthProvider.Google, queryString, onSuccess, onError)
        break

      case 'linkedin':
        auth.OauthCallback(OAuthProvider.LinkedIn, queryString, onSuccess, onError)
        break

      case 'github':
        auth.OauthCallback(OAuthProvider.GitHub, queryString, onSuccess, onError)
        break

      case 'microsoft':
        auth.OauthCallback(OAuthProvider.Microsoft, queryString, onSuccess, onError)
        break
      
        case 'apple':
          auth.OauthCallback(OAuthProvider.Apple, queryString, onSuccess, onError)
          break

      default:
        spinner(false)
        break
    }

  }, [])


  const onSuccess = (redirectTo?: string | null) => {

    //spinner(true)
    history.push(redirectTo ? redirectTo : RoutingController.Home())

  }

  const onError = (error?: string) => {
    // hideLoader()
    spinner(false)
    setShowLogin (true)
    showAlert.error(error)
    
  }

  return (<>
    {showLogin && <Login />}
  </>)

}
