import { SvgIcon } from "@mui/material"
import { whiteDefault } from "XJumpTheme"
import { XJIcon } from "./XJIcon"

export class VisibilityOnIcon extends XJIcon {

    render() {
        super.render()

        return <svg width="20" height="19" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 2.00007C13.4455 2.00007 16.5182 3.93643 18.0182 7.00007C16.5182 10.0637 13.4455 12.0001 10 12.0001C6.55455 12.0001 3.48182 10.0637 1.98182 7.00007C3.48182 3.93643 6.55455 2.00007 10 2.00007ZM10 0.181885C5.45455 0.181885 1.57273 3.00916 0 7.00007C1.57273 10.991 5.45455 13.8182 10 13.8182C14.5455 13.8182 18.4273 10.991 20 7.00007C18.4273 3.00916 14.5455 0.181885 10 0.181885ZM10 4.72734C11.2545 4.72734 12.2727 5.74552 12.2727 7.00007C12.2727 8.25461 11.2545 9.27279 10 9.27279C8.74545 9.27279 7.72727 8.25461 7.72727 7.00007C7.72727 5.74552 8.74545 4.72734 10 4.72734ZM10 2.90916C7.74545 2.90916 5.90909 4.74552 5.90909 7.00007C5.90909 9.25461 7.74545 11.091 10 11.091C12.2545 11.091 14.0909 9.25461 14.0909 7.00007C14.0909 4.74552 12.2545 2.90916 10 2.90916Z" fill={this.color} />
        </svg>
    }
}

export class VisibilityOffIcon extends XJIcon {

    render() {
        super.render()

        return <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 4.02268C13.4455 4.02268 16.5182 5.95905 18.0182 9.02268C17.4818 10.1318 16.7273 11.0863 15.8273 11.859L17.1091 13.1409C18.3727 12.0227 19.3727 10.6227 20 9.02268C18.4273 5.03177 14.5455 2.2045 10 2.2045C8.84546 2.2045 7.73636 2.38632 6.69091 2.72268L8.19091 4.22268C8.78182 4.1045 9.38182 4.02268 10 4.02268ZM9.02727 5.05905L10.9091 6.94086C11.4273 7.16814 11.8455 7.58632 12.0727 8.1045L13.9545 9.98632C14.0273 9.67723 14.0818 9.34996 14.0818 9.01359C14.0909 6.75905 12.2545 4.93177 10 4.93177C9.66364 4.93177 9.34546 4.97723 9.02727 5.05905ZM0.918182 2.08632L3.35455 4.52268C1.87273 5.68632 0.7 7.23177 0 9.02268C1.57273 13.0136 5.45455 15.8409 10 15.8409C11.3818 15.8409 12.7091 15.5772 13.9273 15.0954L17.0364 18.2045L18.3182 16.9227L2.2 0.79541L0.918182 2.08632ZM7.73636 8.9045L10.1091 11.2772C10.0727 11.2863 10.0364 11.2954 10 11.2954C8.74545 11.2954 7.72727 10.2772 7.72727 9.02268C7.72727 8.97723 7.73636 8.94995 7.73636 8.9045ZM4.64545 5.81359L6.23636 7.4045C6.02727 7.9045 5.90909 8.44996 5.90909 9.02268C5.90909 11.2772 7.74545 13.1136 10 13.1136C10.5727 13.1136 11.1182 12.9954 11.6091 12.7863L12.5 13.6772C11.7 13.8954 10.8636 14.0227 10 14.0227C6.55455 14.0227 3.48182 12.0863 1.98182 9.02268C2.61818 7.72268 3.54545 6.64995 4.64545 5.81359Z" fill={this.color} />
        </svg>

    }

}

export class UploadIcon extends XJIcon {

    render() {
        super.render()

        return <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4 13.5H10V7.5H14L7 0.5L0 7.5H4V13.5ZM7 3.33L9.17 5.5H8V11.5H6V5.5H4.83L7 3.33ZM0 15.5H14V17.5H0V15.5Z" fill={this.color} />
        </svg>
    }
}

export class DeleteIcon extends XJIcon {

    render() {
        super.render()

        return <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11 6V16H3V6H11ZM9.5 0H4.5L3.5 1H0V3H14V1H10.5L9.5 0ZM13 4H1V16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V4Z" fill={this.color} />
        </svg>
    }
}

export class AppleIcon extends XJIcon {

    render() {
        super.render()

        return <SvgIcon>
            <path fillRule="evenodd" clipRule="evenodd" d="M14.7811 5.24687C15.4333 4.40487 15.9278 3.21478 15.7489 2C14.6829 2.07409 13.4368 2.75615 12.7097 3.64519C12.0469 4.45074 11.5022 5.64906 11.7149 6.8121C12.8804 6.84856 14.0832 6.15003 14.7811 5.24687ZM20.5418 16.6739C20.0754 17.7134 19.851 18.1779 19.2502 19.0987C18.412 20.3841 17.2302 21.9846 15.7644 21.9963C14.4634 22.0104 14.1279 21.1437 12.3616 21.1543C10.5953 21.1637 10.2271 22.0128 8.92375 21.9999C7.45907 21.9869 6.33923 20.5428 5.5011 19.2575C3.15621 15.666 2.90957 11.4502 4.35554 9.20757C5.38421 7.6153 7.00669 6.68392 8.53099 6.68392C10.0822 6.68392 11.0582 7.54004 12.3429 7.54004C13.589 7.54004 14.3476 6.68157 16.1419 6.68157C17.5003 6.68157 18.9392 7.42597 19.9632 8.71013C16.606 10.5611 17.1496 15.3838 20.5418 16.6739Z" fill={this.color} />
        </SvgIcon>

        //<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        //</svg>
    }
}

export class GoogleIcon extends XJIcon {

    render() {
        super.render()

        return <SvgIcon>
            <path fillRule="evenodd" clipRule="evenodd" d="M12.1377 10.0198V13.9802C12.1377 13.9802 15.9542 13.975 17.5082 13.975C16.6667 16.5425 15.3582 17.9406 12.1377 17.9406C8.87861 17.9406 6.33492 15.2809 6.33492 12C6.33492 8.71911 8.87861 6.05941 12.1377 6.05941C13.8609 6.05941 14.9737 6.66911 15.9945 7.51891C16.8116 6.69634 16.7434 6.57911 18.8223 4.60277C17.0575 2.98574 14.712 2 12.1377 2C6.65151 2 2.2041 6.47713 2.2041 12C2.2041 17.5228 6.65151 22 12.1377 22C20.3381 22 22.3424 14.8119 21.6779 10.0198C19.7367 10.0198 12.1377 10.0198 12.1377 10.0198Z" fill={this.color} />
        </SvgIcon>
    }
}

export class LinkedInIcon extends XJIcon {

    render() {
        super.render()

        return <SvgIcon>
            <path fillRule="evenodd" clipRule="evenodd" d="M21 20.1817H17.0218V14.4292C17.0218 12.9235 16.3995 11.8956 15.0311 11.8956C13.9844 11.8956 13.4023 12.5893 13.1314 13.2578C13.0298 13.4977 13.0456 13.832 13.0456 14.1662V20.1817H9.10444C9.10444 20.1817 9.15524 9.99176 9.10444 9.06549H13.0456V10.8101C13.2785 10.0473 14.5379 8.95862 16.5476 8.95862C19.0411 8.95862 21 10.5576 21 14.0007V20.1817ZM5.11877 7.6751H5.09337C3.82338 7.6751 3 6.82533 3 5.74818C3 4.65008 3.84772 3.81812 5.14311 3.81812C6.43744 3.81812 7.2333 4.64798 7.2587 5.74504C7.2587 6.82218 6.43744 7.6751 5.11877 7.6751ZM3.45396 9.06563H6.9623V20.1818H3.45396V9.06563Z" fill={this.color} />
        </SvgIcon>
    }
}

export class WindowsIcon extends XJIcon {

    render() {
        super.render()

        return <SvgIcon>
            <path fillRule="evenodd" clipRule="evenodd" d="M2 4.83164L10.1734 3.72193L10.177 11.5815L2.00761 11.6279L2 4.83164ZM10.1694 12.4871L10.1758 20.3537L2.00636 19.234L2.0059 12.4345L10.1694 12.4871ZM11.1604 3.5768L21.9975 2V11.4816L11.1604 11.5674V3.5768ZM22 12.5611L21.9975 22L11.1603 20.4752L11.1451 12.5435L22 12.5611Z" fill={this.color} />
        </SvgIcon>
    }
}

export class PapersIcon extends XJIcon {

    render() {
        super.render()

        return <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.81818 3.63636H0V18.1818C0 19.1818 0.818182 20 1.81818 20H16.3636V18.1818H1.81818V3.63636ZM18.1818 0H5.45455C4.45455 0 3.63636 0.818182 3.63636 1.81818V14.5455C3.63636 15.5455 4.45455 16.3636 5.45455 16.3636H18.1818C19.1818 16.3636 20 15.5455 20 14.5455V1.81818C20 0.818182 19.1818 0 18.1818 0ZM18.1818 14.5455H5.45455V1.81818H18.1818V14.5455Z" fill={this.color} />
        </svg>
    }
}

export class VisualAidSlideIcon extends XJIcon {

    render() {
        super.render()

        return <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16 2V16H2V2H16ZM16 0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H16C17.1 18 18 17.1 18 16V2C18 0.9 17.1 0 16 0ZM11.14 8.86L8.14 12.73L6 10.14L3 14H15L11.14 8.86Z" fill={this.color} />
        </svg>
    }
}

export class ArrowUpIcon extends XJIcon {

    render() {
        super.render()

        return <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6 0.294922L0 6.29492L1.41 7.70492L6 3.12492L10.59 7.70492L12 6.29492L6 0.294922Z" fill={this.color} />
        </svg>
    }
}

export class ArrowDownIcon extends XJIcon {

    render() {
        super.render()

        return <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.59 0.294922L6 4.87492L1.41 0.294922L0 1.70492L6 7.70492L12 1.70492L10.59 0.294922Z" fill={this.color} />
        </svg>
    }
}

export class ArrowRightIcon extends XJIcon {

    render() {
        super.render()

        return <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.20492 3L5.79492 4.41L10.3749 9L5.79492 13.59L7.20492 15L13.2049 9L7.20492 3Z" fill={this.color} />
        </svg>

    }
}

export class ArrowLeftIcon extends XJIcon {

    render() {
        super.render()

        return <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.2049 4.41L11.7949 3L5.79492 9L11.7949 15L13.2049 13.59L8.62492 9L13.2049 4.41Z" fill={this.color} />
        </svg>
    }
}

export class ArrowFullUpIcon extends XJIcon {

    render() {
        super.render()

        return <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 5.5L5 0.5L10 5.5H0Z" fill={this.color} />
        </svg>
    }
}

export class ArrowFullDownIcon extends XJIcon {

    render() {
        super.render()

        return <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 0.5L5 5.5L10 0.5H0Z" fill={this.color} />
        </svg>
    }
}

export class Rewind5Icon extends XJIcon {

    render() {
        super.render()

        return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M12 2V6C16.42 6 20 9.58 20 14C20 18.42 16.42 22 12 22C7.58 22 4 18.42 4 14H6C6 17.31 8.69 20 12 20C15.31 20 18 17.31 18 14C18 10.69 15.31 8 12 8V12L7 7L12 2ZM10.9399 12.73L10.6899 14.9L11.3599 15.06C11.3673 15.0526 11.3746 15.0447 11.3821 15.0366C11.4052 15.0116 11.4297 14.9851 11.4599 14.97C11.4799 14.96 11.4999 14.9475 11.5199 14.935C11.5399 14.9225 11.5599 14.91 11.5799 14.9C11.6199 14.88 11.6799 14.86 11.7299 14.85C11.7799 14.84 11.8499 14.83 11.9299 14.83C12.0399 14.83 12.1499 14.85 12.2299 14.88C12.3099 14.91 12.3799 14.96 12.4399 15.03C12.4999 15.1 12.5399 15.17 12.5699 15.27C12.5999 15.37 12.6099 15.47 12.6099 15.58C12.6099 15.69 12.6099 15.79 12.5799 15.89C12.5499 15.99 12.5099 16.07 12.4699 16.14C12.4299 16.21 12.3599 16.27 12.2899 16.31C12.2199 16.35 12.1299 16.37 12.0199 16.37C11.8499 16.37 11.7099 16.31 11.5999 16.22C11.4899 16.13 11.4299 15.99 11.4099 15.81H10.5699C10.5699 16.01 10.6199 16.19 10.6999 16.34C10.7799 16.49 10.8799 16.63 11.0199 16.73C11.1599 16.83 11.3099 16.92 11.4799 16.97C11.6499 17.02 11.8299 17.05 12.0099 17.05C12.2599 17.05 12.4699 17 12.6499 16.93C12.8299 16.86 12.9799 16.75 13.0999 16.62C13.2199 16.49 13.3099 16.34 13.3699 16.17C13.4299 16 13.4599 15.82 13.4599 15.63C13.4599 15.41 13.4199 15.21 13.3699 15.03C13.3199 14.85 13.2299 14.7 13.1199 14.58C13.0099 14.46 12.8699 14.37 12.7099 14.3C12.5499 14.23 12.3699 14.2 12.1599 14.2C12.0899 14.2 12.0199 14.21 11.9599 14.22C11.9299 14.225 11.8999 14.2325 11.8699 14.24C11.8399 14.2475 11.8099 14.255 11.7799 14.26C11.7199 14.27 11.6699 14.29 11.6299 14.31C11.6132 14.3183 11.5966 14.3249 11.5806 14.3313C11.5583 14.3401 11.5374 14.3483 11.5199 14.36L11.6299 13.44H13.3299V12.73H10.9399Z" fill={this.color} />
        </svg>
    }
}

export class Forward5Icon extends XJIcon {

    render() {
        super.render()

        return <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M10 18C13.31 18 16 15.31 16 12H18C18 16.42 14.42 20 10 20C5.58 20 2 16.42 2 12C2 7.58 5.58 4 10 4V0L15 5L10 10V6C6.69 6 4 8.69 4 12C4 15.31 6.69 18 10 18ZM9.42997 13.82C9.44997 14.07 9.58997 14.38 10.03 14.38C10.5888 14.38 10.6196 13.7789 10.6275 13.6236C10.6284 13.6066 10.629 13.5949 10.63 13.59C10.63 13.07 10.34 12.84 9.93997 12.84C9.64997 12.84 9.49997 12.94 9.36997 13.07L8.69997 12.9L8.94997 10.73H11.34V11.44H9.63997L9.52997 12.36C9.73997 12.25 9.96997 12.2 10.17 12.2C10.5 12.2 11.47 12.31 11.47 13.64C11.47 14.44 10.96 15.07 10.03 15.07C9.37997 15.07 8.61997 14.67 8.58997 13.82H9.42997Z" fill={this.color} />
        </svg>
    }
}

export class MuteIcon extends XJIcon {

    render() {
        super.render()

        return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.34005 2.93506L2.93005 4.34506L7.29005 8.70506L7.00005 9.00506H3.00005V15.0051H7.00005L12.0001 20.0051V13.4151L16.1801 17.5951C15.5301 18.0851 14.8001 18.4751 14.0001 18.7051V20.7651C15.3401 20.4651 16.5701 19.8451 17.6101 19.0151L19.6601 21.0651L21.0701 19.6551L4.34005 2.93506ZM10.0001 15.1751L7.83005 13.0051H5.00005V11.0051H7.83005L8.71005 10.1251L10.0001 11.4151V15.1751ZM19.0001 12.0051C19.0001 12.8251 18.8501 13.6151 18.5901 14.3451L20.1201 15.8751C20.6801 14.7051 21.0001 13.3951 21.0001 12.0051C21.0001 7.72506 18.0101 4.14506 14.0001 3.23506V5.29506C16.8901 6.15506 19.0001 8.83506 19.0001 12.0051ZM12.0001 4.00506L10.1201 5.88506L12.0001 7.76506V4.00506ZM16.5001 12.0051C16.5001 10.2351 15.4801 8.71506 14.0001 7.97506V9.76506L16.4801 12.2451C16.4901 12.1651 16.5001 12.0851 16.5001 12.0051Z" fill={this.color} />
        </svg>
    }
}

export class UnmuteIcon extends XJIcon {

    render() {
        super.render()

        return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3 8.99998V15H7L12 20V3.99998L7 8.99998H3ZM10 8.82998V15.17L7.83 13H5V11H7.83L10 8.82998ZM16.5 12C16.5 10.23 15.48 8.70998 14 7.96998V16.02C15.48 15.29 16.5 13.77 16.5 12ZM14 3.22998V5.28998C16.89 6.14998 19 8.82998 19 12C19 15.17 16.89 17.85 14 18.71V20.77C18.01 19.86 21 16.28 21 12C21 7.71998 18.01 4.13998 14 3.22998Z" fill={this.color} />
        </svg>
    }
}

export class ReplayIcon extends XJIcon {

    render() {
        super.render()

        return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 4.72727V2L15.6363 5.63636L12 9.27273V6.54545C8.99089 6.54545 6.54543 8.99091 6.54543 12C6.54543 12.9182 6.77271 13.7909 7.1818 14.5455L5.85452 15.8727C5.14543 14.7545 4.72725 13.4273 4.72725 12C4.72725 7.98182 7.9818 4.72727 12 4.72727ZM12 17.4545C15.0091 17.4545 17.4545 15.0091 17.4545 12C17.4545 11.0818 17.2273 10.2091 16.8182 9.45455L18.1454 8.12727C18.8545 9.24545 19.2727 10.5727 19.2727 12C19.2727 16.0182 16.0182 19.2727 12 19.2727V22L8.36361 18.3636L12 14.7273V17.4545Z" fill={this.color} />
        </svg>
    }
}

export class CheckMarkIcon extends XJIcon {

    render() {
        super.render()

        return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM16.59 7.58L10 14.17L7.41 11.59L6 13L10 17L18 9L16.59 7.58Z" fill={this.color} />
        </svg>
    }
}

export class InfoIcon extends XJIcon {

    render() {
        super.render()

        return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM13 7V9H11V7H13ZM13 11V17H11V11H13Z" fill={this.color} />
        </svg>
    }
}

export class ListIcon extends XJIcon {

    render() {
        super.render()

        return <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2 9.77775H3.55556V8.2222H2V9.77775ZM2 12.8889H3.55556V11.3333H2V12.8889ZM2 6.66664H3.55556V5.11108H2V6.66664ZM5.11111 9.77775H16V8.2222H5.11111V9.77775ZM5.11111 12.8889H16V11.3333H5.11111V12.8889ZM5.11111 5.11108V6.66664H16V5.11108H5.11111ZM2 9.77775H3.55556V8.2222H2V9.77775ZM2 12.8889H3.55556V11.3333H2V12.8889ZM2 6.66664H3.55556V5.11108H2V6.66664ZM5.11111 9.77775H16V8.2222H5.11111V9.77775ZM5.11111 12.8889H16V11.3333H5.11111V12.8889ZM5.11111 5.11108V6.66664H16V5.11108H5.11111Z" fill={this.color} />
        </svg>
    }
}

export class IconListCheckmark extends XJIcon {

    render() {
        super.render()

        return <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_3771_11347)">
                <g clipPath="url(#clip1_3771_11347)">
                    <path d="M8.79508 15.915L4.62508 11.745L3.20508 13.155L8.79508 18.745L20.7951 6.74496L19.3851 5.33496L8.79508 15.915Z" fill={this.color} />
                </g>
            </g>
            <defs>
                <clipPath id="clip0_3771_11347">
                    <rect width="24" height="24" fill="white" transform="translate(0 0.0400391)" />
                </clipPath>
                <clipPath id="clip1_3771_11347">
                    <rect width="24" height="24" fill="white" transform="translate(0 0.0400391)" />
                </clipPath>
            </defs>
        </svg>
    }
}

export class DropUpIconSmall extends XJIcon {

    render() {
        super.render()

        return <svg width="8" height="4" viewBox="0 0 8 4" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 4L4 0L0 4L8 4Z" fill={this.color} />
        </svg>
    }
}

export class DropDownIconSmall extends XJIcon {

    render() {
        super.render()

        return <svg width="8" height="4" viewBox="0 0 8 4" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 0L4 4L8 0H0Z" fill={this.color} />
        </svg>
    }
}

export class DropDownIcon extends XJIcon {

    render() {
        super.render()

        return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1548_14796)">
                <path d="M7 9.5L12 14.5L17 9.5H7Z" fill={this.color} />
            </g>
            <defs>
                <clipPath id="clip0_1548_14796">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    }
}

export class DropUpIcon extends XJIcon {

    render() {
        super.render()

        return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1548_14185)">
                <path d="M7 14.5L12 9.5L17 14.5H7Z" fill={this.color} />
            </g>
            <defs>
                <clipPath id="clip0_1548_14185">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    }
}


export class SegmentAIIcon extends XJIcon {

    render() {

        super.render()

        return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1747_3002)">
                <g clipPath="url(#clip1_1747_3002)">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.15252 5.84751L6.54545 2.90912L4.93839 5.84751L2 7.45457L4.93839 9.06163L6.54545 12L8.15252 9.06163L11.0909 7.45457L8.15252 5.84751ZM8.24906 7.45457L7.14777 6.85226L6.54545 5.75097L5.94314 6.85226L4.84185 7.45457L5.94314 8.05689L6.54545 9.15818L7.14777 8.05689L8.24906 7.45457ZM17.5924 10.9531L15.1818 6.54548L12.7712 10.9531L8.36364 13.3637L12.7712 15.7743L15.1818 20.1818L17.5924 15.7743L22 13.3637L17.5924 10.9531ZM19.1582 13.3637L16.5877 11.9578L15.1818 9.38733L13.776 11.9578L11.2055 13.3637L13.776 14.7695L15.1818 17.34L16.5877 14.7695L19.1582 13.3637ZM4.72727 15.6364L5.69151 17.3994L7.45455 18.3637L5.69151 19.3279L4.72727 21.0909L3.76304 19.3279L2 18.3637L3.76304 17.3994L4.72727 15.6364Z" fill="#3D4147" />
                </g>
            </g>
            <defs>
                <clipPath id="clip0_1747_3002">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
                <clipPath id="clip1_1747_3002">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>

    }

}

export class SegmentStatusCompleteIcon extends XJIcon {

    render() {
        super.render()

        return <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="20" height="20" rx="10" fill="#519A7E" />
            <path d="M7.44912 13.0841L4.13019 9.76521L3 10.8874L7.44912 15.3366L17 5.78568L15.8778 4.66345L7.44912 13.0841Z" fill="white" />
        </svg>
    }
}

export class VisualAidCardThreeDotsIcon extends XJIcon {

    render() {
        super.render()

        return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_716_2621)">
                <path d="M12 8C13.1 8 14 7.1 14 6C14 4.9 13.1 4 12 4C10.9 4 10 4.9 10 6C10 7.1 10.9 8 12 8ZM12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12C14 10.9 13.1 10 12 10ZM12 16C10.9 16 10 16.9 10 18C10 19.1 10.9 20 12 20C13.1 20 14 19.1 14 18C14 16.9 13.1 16 12 16Z" fill={this.color} />
            </g>
            <defs>
                <clipPath id="clip0_716_2621">
                    <rect width="24" height="24" fill={this.color} />
                </clipPath>
            </defs>
        </svg>
    }
}

export class VideoCameraIcon extends XJIcon {

    render() {
        super.render()

        return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_2007_31291)">
                <path d="M15 8V16H5V8H15ZM16 6H4C3.45 6 3 6.45 3 7V17C3 17.55 3.45 18 4 18H16C16.55 18 17 17.55 17 17V13.5L21 17.5V6.5L17 10.5V7C17 6.45 16.55 6 16 6Z" fill={this.color} />
            </g>
            <defs>
                <clipPath id="clip0_2007_31291">
                    <rect width="24" height="24" fill={this.color} />
                </clipPath>
            </defs>
        </svg>
    }
}

export class VisualAidPIP_UpperLeft extends XJIcon {

    render() {
        super.render()

        return <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M2 0H16C17.1 0 18 0.9 18 2V16C18 17.1 17.1 18 16 18H2C0.9 18 0 17.1 0 16V2C0 0.9 0.9 0 2 0ZM2 9V16H16V2H9V9H2Z" fill={this.color} />
        </svg>
    }
}

export class VisualAidPIP_UpperRight extends XJIcon {

    render() {
        super.render()

        return <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M2 0H16C17.1 0 18 0.9 18 2V16C18 17.1 17.1 18 16 18H2C0.9 18 0 17.1 0 16V2C0 0.9 0.9 0 2 0ZM2 2V16H16V9H9V2H2Z" fill={this.color} />
        </svg>
    }
}

export class VisualAidPIP_LowerLeft extends XJIcon {

    render() {
        super.render()

        return <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M2 0H16C17.1 0 18 0.9 18 2V16C18 17.1 17.1 18 16 18H2C0.9 18 0 17.1 0 16V2C0 0.9 0.9 0 2 0ZM9 16H16V2H2V9H9V16Z" fill={this.color} />
        </svg>
    }
}

export class VisualAidPIP_LowerRight extends XJIcon {

    render() {
        super.render()

        return <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M2 0H16C17.1 0 18 0.9 18 2V16C18 17.1 17.1 18 16 18H2C0.9 18 0 17.1 0 16V2C0 0.9 0.9 0 2 0ZM2 16H9V9H16V2H2V16Z" fill={this.color} />
        </svg>
    }
}

export class Search24Icon extends XJIcon {

    render() {
        super.render()

        return <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.755 11.2549H11.965L11.685 10.9849C12.665 9.84488 13.255 8.36488 13.255 6.75488C13.255 3.16488 10.345 0.254883 6.755 0.254883C3.165 0.254883 0.255005 3.16488 0.255005 6.75488C0.255005 10.3449 3.165 13.2549 6.755 13.2549C8.365 13.2549 9.845 12.6649 10.985 11.6849L11.255 11.9649V12.7549L16.255 17.7449L17.745 16.2549L12.755 11.2549ZM6.755 11.2549C4.26501 11.2549 2.255 9.24488 2.255 6.75488C2.255 4.26488 4.26501 2.25488 6.755 2.25488C9.245 2.25488 11.255 4.26488 11.255 6.75488C11.255 9.24488 9.245 11.2549 6.755 11.2549Z" fill={this.color} />
        </svg>
    }
}

export class Close18Icon extends XJIcon {

    render() {
        super.render()

        return <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
            <path d="M12 1.20857L10.7914 0L6 4.79143L1.20857 0L0 1.20857L4.79143 6L0 10.7914L1.20857 12L6 7.20857L10.7914 12L12 10.7914L7.20857 6L12 1.20857Z" fill="#3D4147" />
        </svg>
    }
}

export class Close48Icon extends XJIcon {

    render() {
        super.render()

        return <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="none" viewBox="0 0 48 48">
            <g clipPath="url(#a)">
                <path fill={this.color} d="M31 18.41 29.59 17 24 22.59 18.41 17 17 18.41 22.59 24 17 29.59 18.41 31 24 25.41 29.59 31 31 29.59 25.41 24 31 18.41Z" />
            </g>
            <defs>
                <clipPath id="a">
                    <path fill="#fff" d="M12 12h24v24H12z" />
                </clipPath>
            </defs>
        </svg>
    }
}

export class DoneIcon extends XJIcon {

    render() {
        super.render()

        return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
            <g clipPath="url(#a)">
                <path fill={this.color} d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2Zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8Zm4.59-12.42L10 14.17l-2.59-2.58L6 13l4 4 8-8-1.41-1.42Z" />
            </g>
            <defs>
                <clipPath id="a">
                    <path fill="#fff" d="M0 0h24v24H0z" />
                </clipPath>
            </defs>
        </svg>
    }
}

export class IconRestart extends XJIcon {

    render() {
        super.render()

        return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_2125_31467)">
                <path d="M6.35147 6.35C7.80238 4.9 9.79362 4 12.005 4C16.4278 4 20 7.58 20 12C20 16.42 16.4278 20 12.005 20C8.27267 20 5.16073 17.45 4.27017 14H6.35147C7.17198 16.33 9.39337 18 12.005 18C15.3171 18 18.0088 15.31 18.0088 12C18.0088 8.69 15.3171 6 12.005 6C10.344 6 8.86304 6.69 7.78236 7.78L11.0044 11H4V4L6.35147 6.35Z" fill={this.color} />
            </g>
            <defs>
                <clipPath id="clip0_2125_31467">
                    <rect width="24" height="24" fill={this.color} />
                </clipPath>
            </defs>
        </svg>
    }
}

export class IconRecord extends XJIcon {

    render() {
        super.render()

        return <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3 9C3 5.68629 5.68629 3 9 3C12.3137 3 15 5.68629 15 9C15 12.3137 12.3137 15 9 15C5.68629 15 3 12.3137 3 9Z" fill={this.color} />
        </svg>
    }
}

export class IconPause extends XJIcon {

    render() {
        super.render()

        return <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.857117 12H4.28569V0H0.857117V12ZM7.71426 0V12H11.1428V0H7.71426Z" fill={this.color} />
        </svg>
    }
}

export class IconRehearse extends XJIcon {

    render() {
        super.render()

        return <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.7273 0.909002C12.0209 0.686275 11.2445 0.59082 10.5 0.59082C9.25909 0.59082 7.92273 0.845366 7 1.54537C6.07727 0.845366 4.74091 0.59082 3.5 0.59082C2.25909 0.59082 0.922727 0.845366 0 1.54537V10.8681C0 11.0272 0.159091 11.1863 0.318182 11.1863C0.381818 11.1863 0.413636 11.1545 0.477273 11.1545C1.33636 10.7408 2.57727 10.4545 3.5 10.4545C4.74091 10.4545 6.07727 10.709 7 11.409C7.85909 10.8681 9.41818 10.4545 10.5 10.4545C11.55 10.4545 12.6318 10.6454 13.5227 11.1226C13.5864 11.1545 13.6182 11.1545 13.6818 11.1545C13.8409 11.1545 14 10.9954 14 10.8363V1.54537C13.6182 1.259 13.2045 1.06809 12.7273 0.909002ZM12.7273 9.49991C12.0273 9.27718 11.2636 9.18173 10.5 9.18173C9.41818 9.18173 7.85909 9.59537 7 10.1363V2.81809C7.85909 2.27718 9.41818 1.86355 10.5 1.86355C11.2636 1.86355 12.0273 1.959 12.7273 2.18173V9.49991Z" fill={this.color} />
        </svg>
    }
}

export class IconSmallRecord extends XJIcon {

    render() {
        super.render()

        return <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 13C10.7614 13 13 10.7614 13 8C13 5.23858 10.7614 3 8 3C5.23858 3 3 5.23858 3 8C3 10.7614 5.23858 13 8 13Z" fill="#9B5D4C" />
            <path d="M15 8C15 11.866 11.866 15 8 15C4.13401 15 1 11.866 1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8Z" stroke="#9B5D4C" strokeWidth="2" />
        </svg>
    }
}

export class IconLink extends XJIcon {

    render() {
        super.render()

        return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1194_11364)">
                <path d="M17 7H13V9H17C18.65 9 20 10.35 20 12C20 13.65 18.65 15 17 15H13V17H17C19.76 17 22 14.76 22 12C22 9.24 19.76 7 17 7ZM11 15H7C5.35 15 4 13.65 4 12C4 10.35 5.35 9 7 9H11V7H7C4.24 7 2 9.24 2 12C2 14.76 4.24 17 7 17H11V15ZM8 11H16V13H8V11Z" fill={this.color} />
            </g>
            <defs>
                <clipPath id="clip0_1194_11364">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    }
}

export class IconPlay extends XJIcon {

    render() {
        super.render()

        return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1190_11154)">
                <path d="M7.5 5V19L18.5 12L7.5 5Z" fill={this.color} />
            </g>
            <defs>
                <clipPath id="clip0_1190_11154">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    }
}

export class PlayerIconPlay extends XJIcon {

    render() {
        super.render()

        return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1441_17631)">
                <g clipPath="url(#clip1_1441_17631)">
                    <path d="M7.5 5V19L18.5 12L7.5 5Z" fill={this.color} />
                </g>
            </g>
            <defs>
                <clipPath id="clip0_1441_17631">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
                <clipPath id="clip1_1441_17631">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    }
}

export class PlayerIconPause extends XJIcon {

    render() {
        super.render()

        return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1441_17172)">
                <g clipPath="url(#clip1_1441_17172)">
                    <path d="M6 19H10V5H6V19ZM14 5V19H18V5H14Z" fill={this.color} />
                </g>
            </g>
            <defs>
                <clipPath id="clip0_1441_17172">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
                <clipPath id="clip1_1441_17172">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    }
}

export class PlayerIconStop extends XJIcon {

    render() {
        super.render()

        return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1441_16882)">
                <g clipPath="url(#clip1_1441_16882)">
                    <path d="M6 6H18V18H6V6Z" fill={this.color} />
                </g>
            </g>
            <defs>
                <clipPath id="clip0_1441_16882">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
                <clipPath id="clip1_1441_16882">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    }
}

export class PlayerIconReplay extends XJIcon {

    render() {
        super.render()

        return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1441_16891)">
                <g clipPath="url(#clip1_1441_16891)">
                    <path d="M12.0002 4.72727V2L15.6366 5.63636L12.0002 9.27273V6.54545C8.99113 6.54545 6.54568 8.99091 6.54568 12C6.54568 12.9182 6.77295 13.7909 7.18204 14.5455L5.85477 15.8727C5.14568 14.7545 4.72749 13.4273 4.72749 12C4.72749 7.98182 7.98204 4.72727 12.0002 4.72727ZM12.0002 17.4545C15.0093 17.4545 17.4548 15.0091 17.4548 12C17.4548 11.0818 17.2275 10.2091 16.8184 9.45455L18.1457 8.12727C18.8548 9.24545 19.2729 10.5727 19.2729 12C19.2729 16.0182 16.0184 19.2727 12.0002 19.2727V22L8.36386 18.3636L12.0002 14.7273V17.4545Z" fill={this.color} />
                </g>
            </g>
            <defs>
                <clipPath id="clip0_1441_16891">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
                <clipPath id="clip1_1441_16891">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    }
}

export class PlayerIconRestart extends XJIcon {

    render() {
        super.render()

        return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1441_16929)">
                <g clipPath="url(#clip1_1441_16929)">
                    <path d="M6.35147 6.35C7.80238 4.9 9.79362 4 12.005 4C16.4278 4 20 7.58 20 12C20 16.42 16.4278 20 12.005 20C8.27267 20 5.16073 17.45 4.27017 14H6.35147C7.17198 16.33 9.39337 18 12.005 18C15.3171 18 18.0088 15.31 18.0088 12C18.0088 8.69 15.3171 6 12.005 6C10.344 6 8.86304 6.69 7.78236 7.78L11.0044 11H4V4L6.35147 6.35Z" fill={this.color} />
                </g>
            </g>
            <defs>
                <clipPath id="clip0_1441_16929">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
                <clipPath id="clip1_1441_16929">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    }
}

export class RightArrowIcon extends XJIcon {

    render() {
        super.render()

        return <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.70501 4L6.29501 5.41L10.875 10L6.29501 14.59L7.70501 16L13.705 10L7.70501 4Z" fill={this.color} />
        </svg>
    }
}

export class Ellipse54Icon extends XJIcon {

    render() {
        super.render()

        return <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill={this.color}>
            <circle cx="16" cy="16" r="16" fill="#F7F7F7" />
            <circle cx="16" cy="16" r="16" fill="#B4BADF" fillOpacity="0.48" />
        </svg>
    }
}

export class Ellipse55Icon extends XJIcon {

    render() {
        super.render()

        return <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 56 56" fill="none">
            <circle cx="28" cy="28" r="28" fill="#F7F7F7" />
            <circle cx="28" cy="28" r="28" fill="#B4BADF" fillOpacity="0.48" />
        </svg>
    }
}

export class ZoomOutIcon extends XJIcon {

    render() {
        super.render()

        return <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.0057 11.305H11.3734L11.1492 11.0889C11.9337 10.1764 12.4059 8.99171 12.4059 7.70297C12.4059 4.82933 10.0766 2.5 7.20297 2.5C4.32933 2.5 2 4.82933 2 7.70297C2 10.5766 4.32933 12.9059 7.20297 12.9059C8.49171 12.9059 9.67639 12.4337 10.5889 11.6492L10.805 11.8734V12.5057L14.8073 16.5L16 15.3073L12.0057 11.305ZM7.20297 11.305C5.20983 11.305 3.60091 9.69611 3.60091 7.70297C3.60091 5.70983 5.20983 4.10091 7.20297 4.10091C9.19611 4.10091 10.805 5.70983 10.805 7.70297C10.805 9.69611 9.19611 11.305 7.20297 11.305ZM5.20183 7.30274H9.20412V8.1032H5.20183V7.30274Z" fill={this.color} />
        </svg>
    }
}

export class ZoomInIcon extends XJIcon {

    render() {
        super.render()

        return <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.0057 11.305H11.3734L11.1492 11.0889C11.9337 10.1764 12.4059 8.99171 12.4059 7.70297C12.4059 4.82933 10.0766 2.5 7.20297 2.5C4.32933 2.5 2 4.82933 2 7.70297C2 10.5766 4.32933 12.9059 7.20297 12.9059C8.49171 12.9059 9.67639 12.4337 10.5889 11.6492L10.805 11.8734V12.5057L14.8073 16.5L16 15.3073L12.0057 11.305ZM7.20297 11.305C5.20983 11.305 3.60091 9.69611 3.60091 7.70297C3.60091 5.70983 5.20983 4.10091 7.20297 4.10091C9.19611 4.10091 10.805 5.70983 10.805 7.70297C10.805 9.69611 9.19611 11.305 7.20297 11.305ZM7.6032 5.70183H6.80274V7.30274H5.20183V8.1032H6.80274V9.70412H7.6032V8.1032H9.20412V7.30274H7.6032V5.70183Z" fill={this.color} />
        </svg>
    }
}

export class Error24Icon extends XJIcon {

    render() {
        super.render()

        return <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18" fill="none">
            <path d="M10 3.99092L16.8455 15.8182H3.15455L10 3.99092ZM10 0.363647L0 17.6364H20L10 0.363647ZM10.9091 13.0909H9.09091V14.9091H10.9091V13.0909ZM10.9091 7.63637H9.09091V11.2727H10.9091V7.63637Z" fill={this.color} />
        </svg>
    }
}

export class PenIcon extends XJIcon {

    render() {
        super.render()

        return <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.0585 7.02L12.9785 7.94L3.91854 17H2.99854V16.08L12.0585 7.02ZM15.6585 1C15.4085 1 15.1485 1.1 14.9585 1.29L13.1285 3.12L16.8785 6.87L18.7085 5.04C19.0985 4.65 19.0985 4.02 18.7085 3.63L16.3685 1.29C16.1685 1.09 15.9185 1 15.6585 1ZM12.0585 4.19L0.998535 15.25V19H4.74854L15.8085 7.94L12.0585 4.19Z" fill={this.color} />
        </svg>
    }
}

export class CheckBoxSelectedIcon extends XJIcon {

    render() {
        super.render()

        return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1203_8152)">
                <path d="M19 3H5C3.89 3 3 3.9 3 5V19C3 20.1 3.89 21 5 21H19C20.11 21 21 20.1 21 19V5C21 3.9 20.11 3 19 3ZM10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z" fill={this.color} />
            </g>
            <defs>
                <clipPath id="clip0_1203_8152">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    }
}

export class CheckBoxUnselectedIcon extends XJIcon {

    render() {
        super.render()

        return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1203_8205)">
                <path d="M19 5V19H5V5H19ZM19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3Z" fill={this.color} />
            </g>
            <defs>
                <clipPath id="clip0_1203_8205">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    }
}

export class ProfileVerifiedIcon extends XJIcon {

    render() {
        super.render()

        return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1183_4264)">
                <g clipPath="url(#clip1_1183_4264)">
                    <path d="M23 11.99L20.56 9.2L20.9 5.51L17.29 4.69L15.4 1.5L12 2.96L8.6 1.5L6.71 4.69L3.1 5.5L3.44 9.2L1 11.99L3.44 14.78L3.1 18.48L6.71 19.3L8.6 22.5L12 21.03L15.4 22.49L17.29 19.3L20.9 18.48L20.56 14.79L23 11.99ZM19.05 13.47L18.49 14.12L18.57 14.97L18.75 16.92L16.85 17.35L16.01 17.54L15.57 18.28L14.58 19.96L12.8 19.19L12 18.85L11.21 19.19L9.43 19.96L8.44 18.29L8 17.55L7.16 17.36L5.26 16.93L5.44 14.97L5.52 14.12L4.96 13.47L3.67 12L4.96 10.52L5.52 9.87L5.43 9.01L5.25 7.07L7.15 6.64L7.99 6.45L8.43 5.71L9.42 4.03L11.2 4.8L12 5.14L12.79 4.8L14.57 4.03L15.56 5.71L16 6.45L16.84 6.64L18.74 7.07L18.56 9.02L18.48 9.87L19.04 10.52L20.33 11.99L19.05 13.47Z" fill="#519A7E" />
                    <path d="M10.09 13.75L7.77004 11.42L6.29004 12.91L10.09 16.72L17.43 9.36L15.95 7.87L10.09 13.75Z" fill={this.color} />
                </g>
            </g>
            <defs>
                <clipPath id="clip0_1183_4264">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
                <clipPath id="clip1_1183_4264">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    }
}

export class QAProgressSegmentDone extends XJIcon {

    render() {
        super.render()

        return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_4050_38928)">
                <path d="M8.79508 15.875L4.62508 11.705L3.20508 13.115L8.79508 18.705L20.7951 6.70504L19.3851 5.29504L8.79508 15.875Z" fill={this.color}/>
            </g>
            <defs>
                <clipPath id="clip0_4050_38928">
                    <rect width="24" height="24" fill="white"/>
                </clipPath>
            </defs>
        </svg>        
    }
}

export class QAProgressSegmentPending extends XJIcon {

    render() {
        super.render()

        return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="12" cy="12" r="9" stroke={this.color} strokeWidth="2" />
        </svg>
    }
}

export class QAProgressSegmentArrow extends XJIcon {

    render() {
        super.render()

        return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1548_14346)">
                <path d="M9.70492 6L8.29492 7.41L12.8749 12L8.29492 16.59L9.70492 18L15.7049 12L9.70492 6Z" fill={this.color} />
            </g>
            <defs>
                <clipPath id="clip0_1548_14346">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    }
}

export class IconUploadSuccess extends XJIcon {

    render() {
        super.render()

        return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1227_5155)">
                <g clipPath="url(#clip1_1227_5155)">
                    <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM16.59 7.58L10 14.17L7.41 11.59L6 13L10 17L18 9L16.59 7.58Z" fill={this.color} />
                </g>
            </g>
            <defs>
                <clipPath id="clip0_1227_5155">
                    <rect width="24" height="24" fill={whiteDefault} />
                </clipPath>
                <clipPath id="clip1_1227_5155">
                    <rect width="24" height="24" fill={whiteDefault} />
                </clipPath>
            </defs>
        </svg>
    }
}

export class IconUploadClose extends XJIcon {

    render() {
        super.render()

        return <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1227_2999)">
                <path d="M27 14.41L25.59 13L20 18.59L14.41 13L13 14.41L18.59 20L13 25.59L14.41 27L20 21.41L25.59 27L27 25.59L21.41 20L27 14.41Z" fill={this.color} />
            </g>
            <defs>
                <clipPath id="clip0_1227_2999">
                    <rect width="24" height="24" fill="white" transform="translate(8 8)" />
                </clipPath>
            </defs>
        </svg>
    }
}

export class MenuIconVideos extends XJIcon {

    render() {
        super.render()

        return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1282_111)">
                <path d="M4 6H2V20C2 21.1 2.9 22 4 22H18V20H4V6ZM20 2H8C6.9 2 6 2.9 6 4V16C6 17.1 6.9 18 8 18H20C21.1 18 22 17.1 22 16V4C22 2.9 21.1 2 20 2ZM20 16H8V4H20V16ZM12 5.5V14.5L18 10L12 5.5Z" fill={this.color} />
            </g>
            <defs>
                <clipPath id="clip0_1282_111">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    }
}

export class MenuIconProfile extends XJIcon {

    render() {
        super.render()

        return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1282_134)">
                <path d="M12 6C13.1 6 14 6.9 14 8C14 9.1 13.1 10 12 10C10.9 10 10 9.1 10 8C10 6.9 10.9 6 12 6ZM12 16C14.7 16 17.8 17.29 18 18H6C6.23 17.28 9.31 16 12 16ZM12 4C9.79 4 8 5.79 8 8C8 10.21 9.79 12 12 12C14.21 12 16 10.21 16 8C16 5.79 14.21 4 12 4ZM12 14C9.33 14 4 15.34 4 18V20H20V18C20 15.34 14.67 14 12 14Z" fill={this.color} />
            </g>
            <defs>
                <clipPath id="clip0_1282_134">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    }
}

export class MenuIconContactUs extends XJIcon {

    render() {
        super.render()

        return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_3785_8323)">
                <path d="M11.5 23.295V19.695C6.49 19.435 2.5 15.275 2.5 10.205C2.5 4.96502 6.76 0.705017 12 0.705017C17.24 0.705017 21.5 4.96502 21.5 10.205C21.5 15.155 18.06 20.135 12.93 22.605L11.5 23.295ZM12 2.70502C7.86 2.70502 4.5 6.06502 4.5 10.205C4.5 14.345 7.86 17.705 12 17.705H13.5V20.005C17.14 17.705 19.5 13.925 19.5 10.205C19.5 6.06502 16.14 2.70502 12 2.70502ZM11 14.205H13V16.205H11V14.205ZM13 12.705H11C11 9.45502 14 9.70502 14 7.70502C14 6.60502 13.1 5.70502 12 5.70502C10.9 5.70502 10 6.60502 10 7.70502H8C8 5.49502 9.79 3.70502 12 3.70502C14.21 3.70502 16 5.49502 16 7.70502C16 10.205 13 10.455 13 12.705Z" fill={this.color} />
            </g>
            <defs>
                <clipPath id="clip0_3785_8323">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    }
}

export class MenuIconLogout extends XJIcon {

    render() {
        super.render()

        return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_3662_27158)">
                <path d="M17 7L15.59 8.41L18.17 11H8V13H18.17L15.59 15.58L17 17L22 12L17 7ZM4 5H12V3H4C2.9 3 2 3.9 2 5V19C2 20.1 2.9 21 4 21H12V19H4V5Z" fill={this.color} />
            </g>
            <defs>
                <clipPath id="clip0_3662_27158">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    }
}

export class IconLogin extends XJIcon {

    render() {
        super.render()

        return <svg width="18" height="24" viewBox="0 0 18 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.22217 8.11111L7.13328 9.2L9.1555 11.2222H1.22217V12.7778H9.1555L7.13328 14.8L8.22217 15.8889L12.1111 12L8.22217 8.11111ZM15.2222 17.4444H8.99995V19H16.7777V5H8.99995V6.55556H15.2222V17.4444Z" fill={this.color} />
        </svg>

    }
}

export class InactiveLinkPicture extends XJIcon {

    render() {
        super.render()

        return <svg width="90" height="91" viewBox="0 0 90 91" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_4004_28715)">
                <path d="M42.9798 43.665H9.64981C4.48594 43.665 0.299805 47.8512 0.299805 53.015V80.795C0.299805 85.9589 4.48594 90.145 9.64981 90.145H42.9798C48.1437 90.145 52.3298 85.9589 52.3298 80.795V53.015C52.3298 47.8512 48.1437 43.665 42.9798 43.665Z" fill="#EAEBF3" />
                <path d="M76.7693 0.85498H60.6493C57.9818 0.85498 55.8193 3.01745 55.8193 5.68498V21.085C55.8193 23.7525 57.9818 25.915 60.6493 25.915H76.7693C79.4369 25.915 81.5993 23.7525 81.5993 21.085V5.68498C81.5993 3.01745 79.4369 0.85498 76.7693 0.85498Z" fill="#EAEBF3" />
                <path d="M87.0501 20.0649H78.2301C76.7665 20.0649 75.5801 21.2514 75.5801 22.7149V31.1349C75.5801 32.5985 76.7665 33.7849 78.2301 33.7849H87.0501C88.5136 33.7849 89.7001 32.5985 89.7001 31.1349V22.7149C89.7001 21.2514 88.5136 20.0649 87.0501 20.0649Z" fill="#EAEBF3" />
                <path d="M15.0794 2.99512H6.25938C4.79582 2.99512 3.60938 4.18156 3.60938 5.64512V14.0651C3.60938 15.5287 4.79582 16.7151 6.25938 16.7151H15.0794C16.5429 16.7151 17.7294 15.5287 17.7294 14.0651V5.64512C17.7294 4.18156 16.5429 2.99512 15.0794 2.99512Z" fill="#EAEBF3" />
                <path d="M83.9697 9.34463L85.0697 8.24463" stroke="#3D4147" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" />
                <path d="M79.2895 9.34486L78.1895 8.23486" stroke="#3D4147" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" />
                <path d="M78.1797 15.1249L79.2797 14.0249" stroke="#3D4147" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" />
                <path d="M85.07 15.1249L83.96 14.0249" stroke="#3D4147" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" />
                <path d="M10.88 83.6648C12.8848 83.6648 14.51 82.0396 14.51 80.0348C14.51 78.03 12.8848 76.4048 10.88 76.4048C8.87521 76.4048 7.25 78.03 7.25 80.0348C7.25 82.0396 8.87521 83.6648 10.88 83.6648Z" stroke="#3D4147" strokeWidth="2" strokeMiterlimit="10" />
                <path d="M47.0098 15.9951H30.1498C22.0798 15.9951 15.5498 22.5351 15.5498 30.5951V60.4551C15.5498 68.5251 22.0898 75.0551 30.1498 75.0551H60.0098C68.0798 75.0551 74.6098 68.5151 74.6098 60.4551V43.5951" stroke="#3D4147" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" />
                <path d="M39.2002 55.3852V35.6352C39.2002 34.4252 40.5502 33.7052 41.5502 34.3752L56.3802 44.2552C57.2802 44.8552 57.2802 46.1752 56.3802 46.7752L41.5502 56.6552C40.5502 57.3252 39.2002 56.6052 39.2002 55.3952V55.3852Z" fill="#3D4147" stroke="#3D4147" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" />
                <path d="M63.5098 18.0449V37.0749" stroke="#3D4147" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" />
                <path d="M54.96 25.915L62.36 18.515C62.99 17.885 64.01 17.885 64.65 18.515L72.05 25.915" stroke="#3D4147" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" />
            </g>
            <defs>
                <clipPath id="clip0_4004_28715">
                    <rect width="89.4" height="89.29" fill="white" transform="translate(0.299805 0.85498)" />
                </clipPath>
            </defs>
        </svg>

    }
}



export class UAMessageWarning extends XJIcon {

    render() {
        super.render()

        return <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" >
            <path d="M10 4.9909L16.8455 16.8182H3.15455L10 4.9909ZM10 1.36363L0 18.6364H20L10 1.36363ZM10.9091 14.0909H9.09091V15.9091H10.9091V14.0909ZM10.9091 8.63636H9.09091V12.2727H10.9091V8.63636Z" fill={this.color} />
        </svg >
    }
}


export class IconError extends XJIcon {

    render() {
        super.render()

        return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_4054_14246)">
                <g clipPath="url(#clip1_4054_14246)">
                    <path d="M12 6.99092L18.8455 18.8182H5.15455L12 6.99092ZM12 3.36365L2 20.6364H22L12 3.36365ZM12.9091 16.0909H11.0909V17.9091H12.9091V16.0909ZM12.9091 10.6364H11.0909V14.2727H12.9091V10.6364Z" fill={this.color} />
                </g>
            </g>
            <defs>
                <clipPath id="clip0_4054_14246">
                    <rect width="24" height="24" fill={this.color} />
                </clipPath>
                <clipPath id="clip1_4054_14246">
                    <rect width="24" height="24" fill={this.color} />
                </clipPath>
            </defs>
        </svg>
    }
}


export class ToastSuccess extends XJIcon {

    render() {
        super.render()

        return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1762_1052)">
                <g clipPath="url(#clip1_1762_1052)">
                    <path d="M8.79496 15.875L4.62496 11.705L3.20496 13.115L8.79496 18.705L20.795 6.70504L19.385 5.29504L8.79496 15.875Z" fill="white" />
                </g>
            </g>
            <defs>
                <clipPath id="clip0_1762_1052">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
                <clipPath id="clip1_1762_1052">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>

    }
}

export class ToastErrorClose extends XJIcon {

    render() {
        super.render()

        return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1762_4488)">
                <path d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z" fill="white" />
            </g>
            <defs>
                <clipPath id="clip0_1762_4488">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>

    }
}



