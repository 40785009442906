import { PitchStatus } from "Common/Enums"

import { RoutingController } from "Controllers/RoutingController"
import { useAlert } from "hooks/UseAlert"
import { usePitches } from "hooks/UsePitches"
import { PitchModel } from "Model/PitchModel"
import { useHistory, useParams } from "react-router-dom"
import { QaQuestion } from "./Qa_Question"
import { useSpinner } from "UI Elements/XJSpinner"
import { useEffect } from "react"
import { LogError } from "Controllers/Logging"


export function InterviewQuestion() {

    const { pid } = useParams<any>()        // Pitch ID 
    const { qid } = useParams<any>()       // question ID

    const errorAlert = useAlert().error
    const pitchId = pid

    const history = useHistory()
    const spinner = useSpinner()
    const pitchHook = usePitches()

    const pitch = pitchHook.getPitch(pitchId) as PitchModel
    useEffect(() => {
        // sets pitch to Q&A until the inrview is finished 
        if (pitch.status == PitchStatus.started)
            pitch.setStatusAndSave(PitchStatus.QandA)
    }, [pitch.id])


    const currentQuestion = pitch.getQuestion(Number(qid))
    useEffect(() => {
        // saves current interview progress 
        pitch.updateLastAskedQuestion (currentQuestion)
    }, [pitch, qid])

    const nextQuestion = async () => {

        try {

            spinner(true)
            const nextQuestion = await pitch.interviewGoNext(currentQuestion)
            
            if (nextQuestion === null) {
                if (pitch.id) {
                    await pitchHook.reloadPitch(pitch.id)
                }
                history.push(RoutingController.PitchEditor(pitch))

            } else {

                history.push(RoutingController.InterviewStep(nextQuestion))
            
            }
            spinner(false)
        }

        catch (err) {
            LogError(err)
            errorAlert(err)
        }

    }

    const prevQuestion = () => {

        const PrevQuestion = pitch.interviewGoBack(currentQuestion)

        if (PrevQuestion === null) {
            history.push(RoutingController.Home())
            return
        }

        history.push(RoutingController.InterviewStep(PrevQuestion))
        return

    }

    const FinishLater = () => {
        history.push(RoutingController.Home())
    }

    return <QaQuestion

        currentQuestion={currentQuestion}
        onNextQuestion={nextQuestion}
        onPrevQuestion={prevQuestion}
        onFinishLater={FinishLater}

    />







}