import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom'
import { Grid, Typography } from '@mui/material';
import { AxiosError } from 'axios';
import { HTTP_PITCHURASHORTCODE_INACTIVE, HTTP_PITCHURASHORTCODE_INVALID } from 'Common/HTTPCodes';
import { PublicPitchModel } from 'Model/PublicPitchModel';
import { PublicPitchDAO } from 'Common/PublicPitchDAO';
import { PitchView } from './PitchView';
import { useSpinner } from 'UI Elements/XJSpinner';
import { useAlert } from 'hooks/UseAlert';
import SelectInput from '@mui/material/Select/SelectInput';
import { LogError } from 'Controllers/Logging';
import { BadLink } from 'UI Elements/EmptyPageMessage/BadLink';
import { InactiveLink } from 'UI Elements/EmptyPageMessage/InactiveLink';
import { XJUserLogo } from 'UI Elements/XJUserLogo';
import { LoadingPitch } from 'UI Elements/EmptyPageMessage/LoadingPitch';
import { ErrorPage } from 'UI Elements/EmptyPageMessage/ErrorPage';
import { PitchStatus, PublicPitchStatus } from 'Common/Enums';


enum LinkState {
    loading = 0,
    goodLink = 1,
    badLink = 2,
    inactiveLink = 3,
    Error = 4
}

export function InvestorPitchView() {

    const [pitch, setPitch] = useState<PublicPitchDAO | null>(null)
    const [linkState, setLinkState] = useState(LinkState.loading)

    const location = useLocation()
    const params = new URLSearchParams(location.search)
    const shortLink = params.get('v');

    const spinner = useSpinner()

    const alert = useAlert()

    useEffect(() => {

        const asyncGetPitchByCode = async () => {
            try {
                if (shortLink) {
                    const pitchModel = new PublicPitchModel()

                    try {
                        const resPitch = await pitchModel.getPitchByShortCode(shortLink)
                        if (resPitch && resPitch.publicStatus === PublicPitchStatus.active) {
                            setPitch(resPitch)
                            setLinkState(LinkState.goodLink)
                        } else if (resPitch && (
                            resPitch.publicStatus === PublicPitchStatus.inactive ||         // all unavailable existing pitch treat as inactive 
                            resPitch.publicStatus === PublicPitchStatus.unpublished || 
                            resPitch.publicStatus === PublicPitchStatus.deleted)) 
                        {
                            setPitch(resPitch)
                            setLinkState(LinkState.inactiveLink)
                        } else {
                            // no data - bad link
                            setLinkState(LinkState.badLink)
                        }
                    } catch (err) {

                        if (err instanceof AxiosError) {

                            const statusCode = (err! as AxiosError).response?.status

                            if (statusCode === HTTP_PITCHURASHORTCODE_INACTIVE) setLinkState(LinkState.inactiveLink)
                            else if (statusCode === HTTP_PITCHURASHORTCODE_INVALID) setLinkState(LinkState.badLink)
                            else {
                                LogError (err)
                                setLinkState(LinkState.Error)
                            }
                        }

                        else {
                            LogError(err)
                            setLinkState(LinkState.Error)
                        }
                    }
                } else {
                    // no link - bad link
                    setLinkState(LinkState.badLink)
                }

            }
            catch (err) {
                LogError(err)
                setLinkState(LinkState.Error)
            }
        }

        asyncGetPitchByCode()
    }, [shortLink])


    // MARK: - Analytics 

    const analyticsPitchLoaded = (pitchId: number) => {

        return
    }

    const analyticsPitchStartedPlaying = (pitchId: number) => {
        return

    }

    const analyticsPitchProgress = (pitchId: number, secondsPlayed: number, percentPlayed: number) => {
        return

    }


    // MARK: - Render 

    const GoodLink = (props: {pitch: PublicPitchDAO}) => {

        return <>
            <Grid container p={16}>
                <Grid item xs={12}>
                    <PitchView
                        pitch={props.pitch}
                        analyticsPitchLoaded={analyticsPitchLoaded}
                        analyticsPitchStartedPlaying={analyticsPitchStartedPlaying}
                        analyticsPitchProgress={analyticsPitchProgress}  
                    />
                </Grid>
            </Grid>
        </>
    }

    return <>

        {linkState === LinkState.loading && <LoadingPitch />}
        {linkState === LinkState.goodLink && pitch && <GoodLink pitch={pitch}/>}
        {linkState === LinkState.inactiveLink && pitch && <InactiveLink pitch={pitch}/>}
        {linkState === LinkState.badLink && <BadLink />}
        {linkState === LinkState.Error && <ErrorPage/>}

    </>


}