import React, { ReactNode, useState } from 'react'
import XJConfirmationDialog from './PopUps/XJConfirmationDialog'

import { ReactComponent as SuccessIcon } from '../svg/successIcon.svg'
import { ReactComponent as InfoIcon } from '../svg/InfoIcon.svg'
import { ReactComponent as WarningIcon } from '../svg/warning-1.svg'
import { ReactComponent as ErrorIcon } from '../svg/Error.svg'


const BigAlertContext = React.createContext<IBigAlertContext>({} as IBigAlertContext)

enum AlertStatus {
    None,
    Error,
    Warning,
    Info,
    Success
}

interface IBigAlertContext {
    success: (text: string, title: string) => void
    info: (text: string, title: string) => void
    warning: (text: string, title: string) => void
    error: (text: string, title: string) => void
}

const BigAlertProvider = (props: { children: ReactNode }) => {

    const [alert, setAlert] = useState(AlertStatus.None)
    const [alertText, setAlertText] = useState('')
    const [alertTitle, setAlertTitle] = useState('')

    return (

        <BigAlertContext.Provider

            value={{
                success: (text: string, title: string) => {
                    setAlertText(text)
                    setAlertTitle(title)
                    setAlert(AlertStatus.Success)
                },

                info: (text: string, title: string) => {
                    setAlertText(text)
                    setAlertTitle(title)
                    setAlert(AlertStatus.Info)
                },

                warning: (text: string, title: string) => {
                    setAlertText(text)
                    setAlertTitle(title)
                    setAlert(AlertStatus.Warning)
                },

                error: (text: string, title: string) => {
                    setAlertText(text)
                    setAlertTitle(title)
                    setAlert(AlertStatus.Error)
                }
            }}
        >

            <XJSuccess
                open={alert === AlertStatus.Success}
                message={alertText}
                title={alertTitle}
                onClose={() => { setAlert(AlertStatus.None) }}
            />

            <XJInfo
                open={alert === AlertStatus.Info}
                message={alertText}
                title={alertTitle}
                onClose={() => { setAlert(AlertStatus.None) }}
            />

            <XJWarning
                open={alert === AlertStatus.Warning}
                message={alertText}
                title={alertTitle}
                onClose={() => { setAlert(AlertStatus.None) }}
            />

            <XJError
                open={alert === AlertStatus.Error}
                message={alertText}
                title={alertTitle}
                onClose={() => { setAlert(AlertStatus.None) }}
            />

            {props.children}

        </BigAlertContext.Provider>
    )
}

export interface IXBigErrorDialog {

    message: string
    title: string
    onClose: () => void
    open: boolean

}

function XJSuccess(props: IXBigErrorDialog) {

    return <XJConfirmationDialog open={props.open}

        onConfirm={() => { props.onClose && props.onClose() }}

        SingleButton

        message={props.message}
        title={props.title}
        SVGComponent={<SuccessIcon/>}
        ConfirmButtonText={'OK'}

    />

}

function XJInfo(props: IXBigErrorDialog) {

    return <XJConfirmationDialog open={props.open}

        onConfirm={() => { props.onClose && props.onClose() }}

        SingleButton

        message={props.message}
        title={props.title}
        SVGComponent={<InfoIcon />}
        ConfirmButtonText={'OK'}

    />

}

export function XJWarning(props: IXBigErrorDialog) {

    return <XJConfirmationDialog open={props.open}

        onConfirm={() => { props.onClose && props.onClose() }}

        SingleButton

        message={props.message}
        title={props.title}
        SVGComponent={<WarningIcon/>}
        ConfirmButtonText={'OK'}

    />
}

function XJError(props: IXBigErrorDialog) {

    return <XJConfirmationDialog open={props.open}

        onConfirm={() => { props.onClose && props.onClose() }}
        SingleButton
        message={props.message}
        title={props.title}
        SVGComponent={<ErrorIcon/>}
        ConfirmButtonText={'OK'}

    />
}

export { BigAlertProvider }
export default BigAlertContext