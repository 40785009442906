import { SxProps, Typography } from "@mui/material";
import { Box } from "@mui/material";
import { closeSnackbar, CustomContentProps, SnackbarContent } from "notistack"
import React from "react";
import XJIconButton from "UI Elements/Buttons/XJIconButton";
import { XJMenuButton } from "UI Elements/Buttons/XJMenuButton";
import { ToastErrorClose, ToastSuccess } from "UI Elements/XJIcons";
import { denotiveErrorDefault, HeadlineH3, secondaryDefault, whiteDefault } from "XJumpTheme";
import { styleToastComponent } from "./ToastSuccessComponent";



export const ToastErrorComponent = React.forwardRef<HTMLDivElement, CustomContentProps>((props, ref) => {
    const {
        id,
        message,
        ...other
    } = props

    return <SnackbarContent ref={ref} role="alert" {...other}>
        <Box sx={{ ...styleToastComponent, backgroundColor: denotiveErrorDefault, pr: '0px', gap: '24px' }} >

            <Box>
                <Typography sx={{ ...HeadlineH3 }} color={whiteDefault}>
                    {message}
                </Typography>
            </Box>
            <XJIconButton onClick={() => { closeSnackbar(id) }} transparent sx={{boxShadow: '-1px 0px 0px 0px rgba(255, 255, 255, 0.20)', borderRadius: 0}}>
                <ToastErrorClose />
            </XJIconButton>


        </Box>

    </SnackbarContent>
})

