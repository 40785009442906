
import { Box, SxProps, Typography } from '@mui/material'
import { theme } from 'XJumpTheme'
import { Forward5Icon, MuteIcon, PlayerIconPause, PlayerIconPlay, ReplayIcon, Rewind5Icon, UnmuteIcon } from 'UI Elements/XJIcons'
import { ActionCallback } from 'tools/Types'
import { playerMinWidth }  from './VideoPlayer'
import {  XJMenuButtonRound } from 'UI Elements/Buttons/XJMenuButtonRound'


interface PlayerControlsProps {
    duration: number
    progress: number 

    playing: boolean
    muted: boolean

    onPlay: ActionCallback
    onPause: ActionCallback
    onRestart: ActionCallback
    onRewind: ActionCallback
    onForward: ActionCallback
    onMute: ActionCallback
    onUnmute: ActionCallback
}

export const _durationToTimeString = (duration: number) => {
    const hours = Math.floor(duration / 3600)
    const minutes = Math.floor((duration - (hours * 3600)) / 60)
    const seconds = Math.round ( duration - (hours * 3600) - (minutes * 60))

    const hoursStr = hours < 10 ? "0" + hours.toString() : hours.toString()
    const minsStr = minutes < 10 ? "0" + minutes.toString() : minutes.toString()
    const secsStr = seconds < 10 ? "0" + seconds.toFixed(0) : seconds.toFixed(0)

    return (hours === 0 ? "" : hoursStr + ':') + minsStr + ':' + secsStr
}

export default function PlayerControls(props: PlayerControlsProps) {

    const videoLenght = _durationToTimeString(props.duration)
    const currentProgress = _durationToTimeString(props.progress)

    // Styles 

    const styles = {
        root: {
            width: "100%",
            minWidth: playerMinWidth,
            height: "48px",
            display: "flex",
            overflowX: 'hidden',
           // opacity: 0.5,
          //  backgroundColor: theme.palette.white.main,
            borderBottomRightRadius: '4px',
            borderBottomLeftRadius: '4px',
            px: theme.spacing(8),
           // py: theme.spacing(8),
            justifyContent: 'space-between',
            color: theme.palette.white.default

        } as SxProps,

        timer: {
            alignItems: 'center'
        } as SxProps,

        centerButtons: {
            display: "flex",
            justifyContent: 'space-between',
            alignItems: 'center'
        } as SxProps,

        rightButtons: {
            display: "flex",
            justifyContent: 'space-between',
            alignItems: 'center'
        } as SxProps
    }

    return (
        <Box sx={styles.root}>
  
            <Box display='flex' sx={styles.timer}> 
                <Box><Typography sx={{ ...theme.typography.secondary.bold }} >{currentProgress}</Typography></Box>
                <Box><Typography sx={{ ...theme.typography.secondary.regular }}>&nbsp;/&nbsp; {videoLenght}</Typography></Box>
            </Box>

            <Box sx={styles.centerButtons}>
                <XJMenuButtonRound onClick={props.onRewind} variant={'primary'}>
                    <Rewind5Icon />
                </XJMenuButtonRound>

                {/* {props.playing ?
                    <XJPlayerButton variant={PlayerButtonVariant.pause} onClick={props.onPause} /> :
                    <XJPlayerButton variant={PlayerButtonVariant.play} onClick={props.onPlay} />} */}

                {props.playing ?
                    <XJMenuButtonRound variant={'primary'} onClick={props.onPause}> 
                        <PlayerIconPause />
                    </XJMenuButtonRound> 
                    :
                    <XJMenuButtonRound variant={'primary'} onClick={props.onPlay}> 
                        <PlayerIconPlay />
                    </XJMenuButtonRound> 
                }

                <XJMenuButtonRound onClick={props.onForward} variant={'primary'} >
                    <Forward5Icon />
                </XJMenuButtonRound>
            </Box>

            <Box sx={styles.rightButtons}>

                <XJMenuButtonRound onClick={props.onRestart} variant={'primary'}>
                    <ReplayIcon />
                </XJMenuButtonRound>

                {props.muted ?
                    <XJMenuButtonRound onClick={props.onUnmute} variant={'primary'} >
                        <MuteIcon />
                    </XJMenuButtonRound>
                    :
                    <XJMenuButtonRound onClick={props.onMute} variant={'primary'} >
                        <UnmuteIcon />
                    </XJMenuButtonRound>
                }
            </Box>
        </Box>
    )
}