import { Box, SxProps, Typography, useTheme } from "@mui/material";
import { PitchModel } from "Model/PitchModel";
import { XJButton } from "./Buttons/XJButton";
import { RoutingController } from "Controllers/RoutingController";
import { ReactComponent as InfoIcon } from '../svg/InfoIcon.svg'

interface NotFoundBannerProps {
    text: string
    url: string 

    urlText?: string
}

export function NotFoundBanner(props: NotFoundBannerProps) {

    const urlText = props.urlText ?? "Back"

    const theme = useTheme()

    const styles = {
        noVideoBanner: {

            height: '100%',

            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 12
        } as SxProps
    }

    return (
        <Box sx={styles.noVideoBanner}>
            <Typography variant="h2" style={{margin: theme.spacing(8), textAlign: "center", whiteSpace: "pre-wrap"}}>
                {props.text}
            </Typography>
            <InfoIcon />
            <XJButton variant="secondary" to={props.url}>
                {urlText}
            </XJButton>
        </Box>
    )
}

interface NoSegmentBannerProps {
    noPitch: boolean 
    noSegment: boolean
    pitch?: PitchModel
}

export function NoSegmentBanner(props: NoSegmentBannerProps) {
    let text = `Sorry, something went wrong.\n\nTry start it over`
    let url = RoutingController.Landing()
    if (props.noPitch) {
        text = `Sorry, no pitch found.\n\nGet back to the dashboard`
        url = RoutingController.Home() // TODO: change to value from standart URLs
    } else if (props.noSegment && props.pitch) {
        text = `Sorry, no segment found.\n\nGet back to the pitch editor`
        url = RoutingController.PitchEditor(props.pitch)
    }

    return (
        <NotFoundBanner
            text={text}
            url={url}
        />
    )
}