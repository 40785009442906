import { Box, SxProps, Typography } from "@mui/material"
import { useEffect, useRef } from "react"
import { primaryDefault, theme, whiteOverlay60 } from "XJumpTheme"

interface IXJAudioMeterProps {
    stream: MediaStream | undefined,
    gap?: number | undefined,
    TicksCount?: number,
    TickHeight? : number
}

export default function XJAudioMeter(props: IXJAudioMeterProps) {

    const TicksRefs = useRef<HTMLElement[]>([])
    const timer = useRef<ReturnType<typeof setInterval> | null>(null)

    const TicksCount = props.TicksCount ? props.TicksCount : 20
    const Max = 0.20
    const VolumeTheshold = 0.08
    const gap = props.gap ? props.gap : 8 
    const timeout = 250

    useEffect(() => {

        if (props.stream)// && TicksRefs.current.length > 0) {
        {
            timer.current = setInterval(() => {
                refresh()
            }, timeout)
        }
    }, [props.stream])


    useEffect(() => {
        return () => {
           if (timer.current) clearInterval(timer.current)
        }
    }, [])

    if (!props.stream) return <><Box mx={'auto'} textAlign={'center'}> <Typography variant="h3">Initializing... </Typography></Box></>

    let audioContext: AudioContext
    let analyserNode: AnalyserNode
    let pcmData: Float32Array


    if (props.stream)
    {
        audioContext = new AudioContext()
        analyserNode = audioContext.createAnalyser()
        pcmData = new Float32Array(analyserNode.fftSize)

        const mediaStreamAudioSourceNode = audioContext.createMediaStreamSource(props.stream)
        mediaStreamAudioSourceNode.connect(analyserNode)
    }

    const getCurrentData = () => {

        analyserNode.getFloatTimeDomainData(pcmData)
        let sumSquares = 0.0
        for (const amplitude of pcmData) { sumSquares += amplitude * amplitude }
        return (Math.sqrt(sumSquares / pcmData.length))
    }

    const TickSX: SxProps =
    {
        width:  props.TickHeight ?  `${Math.round ( props.TickHeight / 3 )}px` : '8px',
        height:  props.TickHeight ? `${props.TickHeight}px` : '24px',
        borderRadius: props.TickHeight ? `${Math.round ( props.TickHeight / 3 )}px` : '8px',
        background: whiteOverlay60

    }

    const refresh = () => {

        const data = getCurrentData()
       // console.debug(data)
        const tickColor = data >= VolumeTheshold ? primaryDefault : theme.palette.denotive.warning.default

        if (TicksRefs.current.length > 0) {

            for (let i = 0; i < TicksCount; i++) {

                if (!TicksRefs.current[i]) {
                    clearInterval(timer.current!)
                    return

                }
                if (data / Max > (i + 1) / TicksCount) {
                    TicksRefs.current[i].style.background = tickColor
                }
                else {
                    TicksRefs.current[i].style.background = whiteOverlay60
                }
            }
        }
    }

    const RenderTicks = () => {
        const ticks = []
        for (let i = 0; i < TicksCount; i++) {
            ticks.push(<Box
                key={'audiometer' + i}
                sx={TickSX}
                ref={(e) => {
                    TicksRefs.current[i] = e as HTMLElement
                }}

            > &nbsp;</Box>)
        }

        return ticks

    }

    return <>

        <Box display={'flex'} gap={gap} justifyContent={'space-between'}>
            {RenderTicks()}
        </Box>
    </>


}