import { Box, IconButton, SxProps } from "@mui/material"
import React from "react"
import { lightPrimaryOverlay24, lightPrimaryOverlay48, primaryDefault, primaryDefaultOverlay16, primaryDefaultOverlay32, secondaryDefault, secondaryDefaultOverlay32, secondaryDefaultOverlay8, secondaryLight60, secondaryLight75, secondaryLight85, whiteDefault } from "XJumpTheme"





interface IXJIconButtonProps {
    children: React.ReactNode
    onClick: (event: React.MouseEvent<HTMLButtonElement>) => void
    disabled?: boolean
    variant?: 'primary' | 'secondary' | 'tertiary' | 'error'
    transparent?: boolean
    sx?: SxProps
}

export default function XJIconButton(props: IXJIconButtonProps) {

    const SXDisabled = () => {
        return {
            background: props.variant === 'tertiary' ? 'inherit' : secondaryLight85,
            color: secondaryLight75
        }
    }


    const withTransparent = (bgColor: string) => {

        return props.transparent ? 'auto' : bgColor


    }

    const SXPrimary = {

        background: withTransparent(primaryDefault),
        ':focus-visible': {
            background: withTransparent(primaryDefault),
            outline: '2px solid ' + secondaryLight60
        },
        ':focus-visible:hover': {
            background: withTransparent(primaryDefaultOverlay16),
            outline: '2px solid ' + secondaryLight60
        },
        ':hover': { background: withTransparent(primaryDefaultOverlay16) },
        ':active': { background: withTransparent(primaryDefaultOverlay32) },     // pressed
        ':disabled': SXDisabled(),
        color: whiteDefault
    }

    const SXSecondary = {

        background: withTransparent(secondaryDefault),
        ':focus-visible': {
            background: withTransparent(secondaryDefault),
            outline: '2px solid ' + secondaryLight60
        },
        ':focus-visible:hover': {
            background: withTransparent(secondaryDefaultOverlay32),
            outline: '2px solid ' + secondaryLight60
        },
        ':hover': { background: withTransparent(secondaryDefaultOverlay32) },
        ':active': { background: withTransparent(secondaryDefaultOverlay8) },    // pressed 
        ':disabled': SXDisabled(),
        color: whiteDefault
    }

    const SXTertiary = {

        background: 'inherit',
        ':focus-visible': {
            background: 'inherit',
            outline: '2px solid ' + secondaryLight60
        },
        ':focus-visible:hover': {
            background: withTransparent(lightPrimaryOverlay24),
            outline: '2px solid ' + secondaryLight60
        },
        ':hover': { background: withTransparent(lightPrimaryOverlay24) },
        ':active': { background: withTransparent(lightPrimaryOverlay48) },        // pressed
        ':disabled': SXDisabled(),
        color: secondaryDefault
    }

    const SX = () => {

        switch (props.variant) {

            case 'secondary':
                {
                    return SXSecondary
                }
            case 'tertiary':
                {
                    return SXTertiary
                }
            default:
                {
                    return SXPrimary
                }
        }

    }



    const DimensionSx = {



        // width: props.variant !== iconButtonVariant.tinyButton ? '48px' : '24px',
        // height: props.variant !== iconButtonVariant.tinyButton ? '48px' : 0,

        width: '40px',
        height: '40px',
        // marginLeft: '5px',
        // marginRight: '5px',
        borderRadius: '2px'

    }

    return <>
        <IconButton

            disabled={props.disabled}
            disableRipple={true}
            onClick={props.onClick}
            sx={{ ...DimensionSx, ...SX(), ...props.sx }}

        >
            


                {React.Children.map(props.children, child => {

                    if (React.isValidElement(child)) {
                        return React.cloneElement(child, { key: 'icon' })
                    }

                    return child

                })}
            
        </IconButton>
    </>

}

export function XJIconButtonTextFieldStepper(props: IXJIconButtonProps) {
    return <XJIconButton onClick={props.onClick}
        // variant={iconButtonVariant.tinyButton} 
        sx={{ p: 0, m: 0, width: '24px', height: 0 }}  >
        {props.children}
    </XJIconButton>

}
