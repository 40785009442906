import { PitchStatus } from "Common/Enums"

import { RoutingController } from "Controllers/RoutingController"
import { useAlert } from "hooks/UseAlert"
import { usePitches } from "hooks/UsePitches"
import { PitchModel } from "Model/PitchModel"
import { useHistory, useParams } from "react-router-dom"
import { QaTopic } from "./QaTopic"
import { useSpinner } from "UI Elements/XJSpinner"
import { useEffect } from "react"
import { LogError } from "Controllers/Logging"


export function InterviewTopic() {

    const { pid } = useParams<any>()        // Pitch ID 
    const { tid } = useParams<any>()       // question ID

    const pitchId = pid
    const history = useHistory()
    const spinner = useSpinner()
    const pitchHook = usePitches()

    const errorAlert = useAlert().error

    const pitch = pitchHook.getPitch(pitchId) as PitchModel

    useEffect(() => {
        // sets pitch to Q&A until the inrview is finished 
        if (pitch.status === PitchStatus.started)
            pitch.setStatusAndSave(PitchStatus.QandA)
    }, [pitch.id])

    const currentTopic = pitch.getTopic(Number(tid))

    useEffect(() => {
        // saves current interview progress 
        pitch.updateLastAskedQuestion (currentTopic)
    }, [pitch, tid])

    const startTopic = async () => {

        try {
            spinner(true)
            const nextStep = await pitch.interviewGoNext(currentTopic)
            
            if (nextStep) {
                // if (pitch.status !== PitchStatus.QandA)
                //     pitch.setStatusAndSave(PitchStatus.QandA)
                history.push(RoutingController.InterviewStep(nextStep))
            } else {
                if (pitch.id) {
                    pitchHook.reloadPitch(pitch.id)
                }
                history.push(RoutingController.PitchEditor(pitch))
            }

            return

        }
        catch (err) {
            LogError(err)
            errorAlert(err)

        } finally {
            spinner(false)
        }

    }

    const goBack = () => {


        const prevQuestion = pitch.interviewGoBack(currentTopic)

        if (prevQuestion === null) {

            history.push(RoutingController.Home())
        } 
        else 
        {
            // if (pitch.status !== PitchStatus.QandA)
            //     pitch.setStatusAndSave(PitchStatus.QandA)
            history.push(RoutingController.InterviewStep(prevQuestion))
            return
        }



    }

    const SkipTopic = async () => {

        try {

            spinner(true)
            const NextTopic = await pitch.interviewSkipTopic(currentTopic)
            if (!NextTopic) {
                if (pitch.id) {
                    await pitchHook.reloadPitch(pitch.id)
                }
                history.push(RoutingController.PitchEditor(pitch))
            }
            else {
                
                history.push(RoutingController.InterviewStep(NextTopic))
            }
            spinner(false)
        }
        catch (err) {
            LogError(err)
            errorAlert(err)


        }

    }


    const FinishLater = () => {
        history.push(RoutingController.Home())
    }

    return <>

        <QaTopic

            currentTopic={currentTopic}
            onStart={startTopic}
            onSkip={SkipTopic}
            onPrevious={goBack}
            onFinishLater={FinishLater} />

    </>









}