import { Box, Grid, SxProps, Typography } from "@mui/material";
import { XJUserLogo } from "UI Elements/XJUserLogo";
import { copyPrimaryRegular, copySecondaryRegular, darkPrimaryDefault, secondaryDefault, whiteDefault } from "XJumpTheme";
import { parsePhoneNumber } from "libphonenumber-js";
import { LinkedInButton } from "Components/XJLinkedInButton";
import { LinkedInProfileURL } from "Pages/Founder/Profile/FounderProfile";
import { PublicPitchDAO } from 'Common/PublicPitchDAO';
import { InactiveLinkPicture } from 'UI Elements/XJIcons';
import { XJButton } from 'UI Elements/Buttons/XJButton';

interface InactiveLinkProps {
    pitch: PublicPitchDAO
}

export function InactiveLink(props: InactiveLinkProps) {

    const pitch = props.pitch

    const FoudnerPhoneNumber = parsePhoneNumber('+' + pitch.founder.mobile)

    const onContact = () => {
        console.log('Contacted')
    }


    // MARK: - Render 

    const ProfileItem = (title: string, value: string | JSX.Element | undefined, reversed?: boolean) => {

        const styles = {
            root: {
                display: 'flex', 
                flexDirection: reversed ? 'column-reverse' : 'column', 
                gap: 4, 
                mr: 0
            } as SxProps, 
            title: {
                ...copySecondaryRegular, 
                color: darkPrimaryDefault
            } as SxProps, 
            value: {
                whiteSpace: 'none'
            } as SxProps
        }

        return (
            <Box sx={styles.root}>
                <Box sx={styles.title} >
                    {title}
                </Box>

                <Typography variant='h3' sx={styles.value}>
                    {value}
                </Typography>
            </Box>
        )
    }

    const Profile = () => {

        const styles = {
            root: {
                display: 'flex',
                flexDirection: 'column',
                gap: 16,
                backgroundColor: whiteDefault, 
                borderRadius: '2px',
                px: 12, 
                py: 8,
                mt: {xs: 0, sm: 8},
                mx: {xs: 8, sm: 16, lg: 0}
            } as SxProps,
            row: {
                display: 'flex', 
                gap: 16,
                flexWrap: 'wrap'
            } as SxProps,
            button: {
                cursor: 'pointer',
                mt: 0, 
                height: '100%'
            } as SxProps
        }

        return <>
            <Box sx={styles.root}>
                <Box sx={styles.row}>
                    {ProfileItem(pitch.founder.title, pitch.founder.name, true)}
                    {ProfileItem("Mobile", FoudnerPhoneNumber?.country === 'US' ? FoudnerPhoneNumber?.formatNational() : FoudnerPhoneNumber?.formatInternational(), true)}
                    {ProfileItem("Email", pitch.founder.email, true)}

                    <Box sx={styles.button}>
                        <LinkedInButton profileURL={LinkedInProfileURL + pitch.founder.linkedInURL} />
                    </Box>
                </Box>

                <Box sx={styles.row}>
                    {ProfileItem("Round", pitch.round)}
                    {ProfileItem("Stage", pitch.company.stage)}
                    {ProfileItem("Industry", pitch.company.industry + (pitch.company.subIndustry ? `, ${pitch.company.subIndustry}` : ''))}
                    {ProfileItem("Tag", pitch.tag)}
                </Box>
            </Box>
        </>
    }

    const styles = {
        logoline: {
            height: {xs: '32px', sm: '48px'},
            display: 'flex',
            alignItems: 'center',
            gap: 8,
            px: {xs: 8, sm: 16, lg: 0},
            mb: {xs: 4, lg: 12}
        } as SxProps,
        logo: {
            width: {xs: '32px', sm: '48px'},
            height: {xs: '32px', sm: '48px'}
        } as SxProps,
        placeholder: {
            width: '100%', 
            aspectRatio: {xs: undefined, sm: '16/9'}, 
            height: {xs: '180px', sm: 'auto'},
            backgroundColor: whiteDefault,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
        } as SxProps,
        icon: {
            mb: 16,
            display: {xs: 'none', sm: 'block'}
        } as SxProps,
        title: {
            mb: 8,
            color: secondaryDefault
        } as SxProps,
        text: {
            mb: {xs: 0, sm: 32},
            color: darkPrimaryDefault,
            textAlign: 'center',
            maxWidth: '60%',
            ...copyPrimaryRegular
        } as SxProps,
        button: {
            display: {xs: 'none', sm: 'block'}
        } as SxProps,
        buttonSmall: {
            display: {xs: 'block', sm: 'none'},
            py: 4, 
            px: 8,
            width: '100%'
        } as SxProps
    }

    return (
        <Box sx={{width: '100%'}}>
            <Grid container py={{xs: 4, sm: 8}} pl={{lg: 26}}>
                <Grid item xs={12}>
                    <Box sx={styles.logoline}>
                        <XJUserLogo sx={styles.logo} src={pitch.company.logo} />
                        <Typography variant='h2'>{pitch.company.name}</Typography>
                    </Box>
                </Grid>

                <Grid item xs={12}>    
                    <Grid item xs={12} lg={8}>
                        <Box sx={styles.placeholder}>
                            <Box sx={styles.icon}> 
                                <InactiveLinkPicture />
                            </Box>

                            <Box sx={styles.title}>
                                <Typography sx={{display: {xs: 'block', lg: 'none'}}} variant='h3'>
                                    Pitch is offline
                                </Typography>
                                <Typography sx={{display: {xs: 'none', lg: 'block'}}} variant='h2'>
                                    Pitch is offline
                                </Typography>
                            </Box>

                            <Box sx={styles.text}>
                                The owner of this pitch made it inactive, however you can contact them just by clicking on this button bellow 
                            </Box>

                            <Box sx={styles.button}>
                                <XJButton variant='secondary' onClick={onContact}>
                                    Contact founder
                                </XJButton>
                            </Box>
                        </Box> 

                        <Box sx={styles.buttonSmall}>
                            <XJButton variant='secondary' width='100%' onClick={onContact}>
                                Contact founder
                            </XJButton>
                        </Box>

                        <Profile />

                    </Grid>
                </Grid>
            </Grid>
        </Box>
    )
}