import { Box, SxProps, Typography } from "@mui/material";
import { ReactNode } from "react";
import { denotiveErrorDefault, denotiveErrorLight90, denotiveInfoDefault, denotiveInfoLight90, denotiveWarningDark50, denotiveWarningLight50, primaryDefault, primaryLight90 } from "XJumpTheme";




export enum BadgeColor {
    blue,
    red,
    green,
    amber
}


export default function XJStatusBadge(props: { text: string, type: BadgeColor, icon?: ReactNode, textVariant?: 'h1' | 'h2' | 'h3' }) {

    let backColor = primaryLight90
    let textColor = primaryDefault

    const style: SxProps =
    {

        borderRadius: '2px',
        paddingY: 2,
        paddingRight: props.icon ? 6 : 8,
        paddingLeft: 8,
        textTransform: 'uppercase',
        display: 'flex',
        gap: 4,
        alignItems: 'center',
        justifyContent: 'space-around'

    }

    switch (props.type) {

        case BadgeColor.green:
            backColor = primaryLight90
            textColor = primaryDefault
            break


        case BadgeColor.red:
            backColor = denotiveErrorLight90
            textColor = denotiveErrorDefault
            break

        case BadgeColor.blue:

            backColor = denotiveInfoLight90
            textColor = denotiveInfoDefault
            break

        case BadgeColor.amber:

            backColor = denotiveWarningLight50
            textColor = denotiveWarningDark50
            break



    }

    return <Box

        sx={{ ...style, backgroundColor: backColor, color: textColor }}>

        <Typography variant={props.textVariant ?? 'h2'} color={textColor}>
            {props.text}
        </Typography>

        {
            props.icon ? <Box display={'flex'} alignItems={'center'}>{props.icon}</Box> : null
        }

    </Box>


}