
export enum PitchStatus {
    
    started,
    QandA,              // Interview in progress or needs to be finished 
    script,             // Interview's finished, working on script 
    Recording,          // Script's finished, working on recording 
    RecordingComplete,  // All segments has video, ready to live 
    live,
    unpublished
}

export enum PublicPitchStatus {
    
    active,             // link active, pitch live 
    inactive,           // link disabled
    unpublished,        // pitch unpablished 
    deleted             // pitch deleted 

}

export enum PitchType {
    undefined,
    Saas,
    Marketplace,
    Service,
    Software,
    Hardware,
    Destination,
    Custom = 100
}


export enum PitchuraRole {
    founder,
    investor,
    pitchWriter,
    PitchuraAdmin
}


export enum TopicStatus {
    notStarted,
    started,
    skipped,
    complete
}
export enum QuestionType {

    open,
    select,
    openmultiline
}

export enum AnswerType {
    string,
    int,
    float
}

export enum MediaType {
    undefined = -1,
    video = 0,
    visualAidImage = 1,
    visualAidVideo = 2,
    link = 3
}

export enum CheckingStatus {
    undefined = -1,     // didn't started yet
    failed = 0,         // didn't go well 
    inProgress = 1,     // checking now 
    success = 2,        // went well 
    active = 3          // ongoing checking or no checking, has no result status 
}


export enum RecorderState {
    init = 0,
    records = 1,
    finished = 2
}


export enum SegmentType {

    undefined = 0,
    intro = 1,
    problem = 2,
    product = 3,
    team = 4,
    demo = 5

}

export enum SegmentStatus {

    New,                // Created 
    Pending,            // AI at work   
    Script,             // Script is done 
    Video               // Video is done 
}

export enum MediaStatus {
    undefinded = -1,
    init = 0,               // media created 
    recorded = 1,           // recorder (for videos)
    uploading = 2,          // uploading started 
    uploaded = 3,           // uploading finished 
    uploadComplete = 4,     // uploading completed (for videos) - backend started transcoding
    processing = 5,         // transcoding on the backend
    transcoded = 6,         // transcoding finished 
    uploadError = 7,        // uploading error (on the front / back)
    processingError = 8,     // transcoding error (on the backend)
    uploadAborted = 9
}

export enum AIStatus {
    init,
    sent,
    pendingManualReview,
    completed,
    error
}

export enum CompanyStage {
    Idea = 1,
    ProofOfConcept,
    Demo,
    MVPLaunch,
    ProductLaunch,
    FreeUsers,
    PayingUsers

}

export const StageToString = (stage: CompanyStage) => {

    switch (stage) {
        
        case CompanyStage.Idea:
            return "Idea"
        
        case CompanyStage.ProductLaunch:
            return "Product Launched (GA)"
            
        case CompanyStage.ProofOfConcept:
            return "Proof of Concept"
            
        case CompanyStage.Demo:
            return "Demo"
            
        case CompanyStage.MVPLaunch:
            return "MVP Launched"
            
        case CompanyStage.FreeUsers:
            return "Free users"
            
        case CompanyStage.PayingUsers:
            return "Paying users"

    }
}

export enum PitchRound {
    NotRaising = 1,
    FriendsAndFamily,
    PreSeed,
    Seed,
    SeriesA,
    SeriesB,
    SeriesC
}


export const RoundToString = (round: PitchRound) => {

    switch (round) {
        case PitchRound.NotRaising:
            return "Not raising"

        case PitchRound.FriendsAndFamily:
            return "Friends and Family"

        case PitchRound.PreSeed:
            return "Pre-seed"

        case PitchRound.Seed:
            return "Seed"

        case PitchRound.SeriesA:
            return "Series A"

        case PitchRound.SeriesB:
            return "Series B"

        case PitchRound.SeriesC:
            return "Series C"

    }

}

export enum OAuthProvider {
    Google,
    Apple,
    Microsoft,
    LinkedIn,
    GitHub
}


export const VideoDimensions = {
    width: 1280,
    height: 720,
    aspectRatio: 16 / 9
}

