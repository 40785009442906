import { PitchModel } from "./PitchModel"
import { QuestionModel } from "./QuestionModel"
import { VideoModel } from "./VideoModel"
import { TopicModel } from "Model/TopicModel"
import { SegmentDAO } from "Common/SegmentDAO"
import API from "api-axios"
import { MediaStatus, QuestionType, SegmentStatus, SegmentType, TopicStatus } from "Common/Enums"
import { QuestionSelection } from "Common/QuestionContentDAO"
import { QT, QT_Null } from "./Types"
import { LogError } from "Controllers/Logging"


export type MinsSecs =
    {
        mins: number;
        secs: number
    }


export class SegmentModel {

    constructor(
        _type: SegmentType,
        _pitch: PitchModel,
        _id?: number) {
        this.pitch = _pitch
        this.id = _id
        this.type = _type
        this.title = this.convertSegmentTypeToString(_type)
    }


    // MARK: - DATA 

    id: number | undefined
    title = ''
    type: SegmentType = SegmentType.undefined
    sequence = 0
    _status: SegmentStatus = SegmentStatus.New

    public automatedScript: string | null = null
    private manualScript: string | null = null
    showPitchuraScriptReady = false

    AIPrompt = ''

    pitch: PitchModel
    video: VideoModel | null = null
    lastUpdated: Date = new Date()

    public topics: TopicModel[] = []

    ScriptAIRequestTimestamp: Date | undefined
    ScriptAIRequestStatus: number

    ResetShowPitchuraScriptReady() {
        this.showPitchuraScriptReady = false
        this.save()
    }

    get isVideoRecorded(): boolean {
        if (!this.video) return false

        return this.video.mediaStatus === MediaStatus.uploaded ||
            this.video.mediaStatus === MediaStatus.transcoded ||
            this.video.mediaStatus === MediaStatus.uploadComplete ||
            this.video.mediaStatus === MediaStatus.processing
    }

    /* get SystemQuestions() {
        return this.pitch.systemQuestions
    } */

    get isComplete() {
        return this.CompletedTopics === this.TotalTopics
    }

    get TotalTopics() {
        return this.topics.length
    }

    get CompletedTopics() {
        return this.topics.filter(t => t.status === TopicStatus.complete).length
    }

    get displaySequence() {
        return this.sequence + 1
    }
    get status() {
        return this._status
    }

    InterviewIsComplete() {
        this.save()
    }

    // DATABASE IO

    // TODO:  REVIEW


    public updateStatus() {
        if (this.isVideoRecorded) {
            this._status = SegmentStatus.Video
        }
    }


    public async SetNewVideo(video: VideoModel): Promise<void> {
        video.segment = this
        this.video = video
        video.segment = this
        
        await video.save()
            .catch(err => {

                LogError(err)

            })

    }

    public async save() {
        this.updateStatus()
        this.pitch.updateStatus()

        const SegmentData = {
            status: this.status,
            title: this.title,
            sequence: this.sequence,
            // automatedScript: this.automatedScript,    // do not send automatedScript as it changes asynchronously and only on the backend 
            manualScript: this.manualScript,
            pitchID: this.pitch.id,
            type: this.type,
            firstTopicId: 0,//this.FirstTopicId,
            id: this.id,
            showPitchuraScriptReady: this.showPitchuraScriptReady,
            AIPrompt: this.AIPrompt,

            interviewSequence: this._InterviewQueue.map(o => {
                if (o instanceof TopicModel) {
                    return 'T' + o.id
                } else {
                    return 'Q' + o.id
                }
            }).join('|')
        } as SegmentDAO

        try {
            if (this.id) {
                await API.post(`/pitch/${this.pitch.id}/segment/${this.id}/update`, SegmentData)
            } else {
                this.id = await (await API.post(`/pitch/${this.pitch.id}/segment/create`, SegmentData)).data.id
            }
        } catch (error) {
            LogError(error)
            Promise.reject()
        }

        Promise.resolve()
    }

    private convertSegmentTypeToString(type: SegmentType): string {
        switch (type) {
            case (SegmentType.intro):
                return "Introduction"

            case (SegmentType.problem):
                return "Problem"

            case (SegmentType.product):
                return "Product"

            case (SegmentType.team):
                return "Team"

            default:
                return "undefined"
        }
    }

    get StatusLabel() {
        switch (this.status) {
            case SegmentStatus.New:
                return "New"
            case SegmentStatus.Pending:
                return "Pending"
            case SegmentStatus.Video:
                return "Video"
            case SegmentStatus.Script:
                return "Script"
            default:
                return "Unknown"
        }
    }

    get StatusDescription() {
        switch (this.status) {
            case SegmentStatus.New:
                return "New"
            case SegmentStatus.Pending:
                return "Script pending"
            case SegmentStatus.Video:
                return "Done"
            case SegmentStatus.Script:
                return "Script ready"
            default:
                return "Unknown"
        }
    }


    // MARK: - Questions

    /*   DebugSetAnswer(questionId: number, value: any) {
          this.getAllQuestions().find(x => x.id === questionId)?.setAnswer(value, false)
      } */


    // MARK: - Segment parts navigaton 

    getNextSegment(): SegmentModel | undefined {
        return this.pitch.getNextSegment(this)
    }

    getPrevSegment(): SegmentModel | undefined {
        return this.pitch.getPrevSegment(this)
    }

    goFirstTopic(): TopicModel {

        if (this.topics.length === 0) {
            throw (new Error('getFirstTopic didnt dfind any topics'))
        }

        const nextTopic = this.topics[0]
        this.InterviewQueuePush(nextTopic)
        return nextTopic

    }


    // MARK: - Interview navigation 

    private _InterviewQueue: Array<QT> = []

    public LoadInterviewSequence(interviewSequence: string) {

        this._InterviewQueue = interviewSequence.split('|').filter(f => (f.length > 0)).map(o => {

            const id = +o.slice(1)

            if (o.startsWith('T')) {
                return this.topics.find(t => t.id === id)
            } else {
                return this.getAllQuestions().find(q => q.id === id)
            }
        }) as (QuestionModel | TopicModel)[]

    }


    private InterviewQueuePush(Q: QT) {

        if (Q) {

            if (!this._InterviewQueue.find(x => x === Q)) {
                this._InterviewQueue.push(Q)
                this.save()
            } else {
                console.debug('InterviewQueuePush: ' + Q.id)
            }
        }
    }






    public skipTopic(T: TopicModel): QT_Null {

        return this.goNext(T, true)

    }

    public setCurrentInterviewPosition(Q: QT) {

        // TODO: RESET QUEUE TO THE P parameter 


    }



    public getCurrentInterviewStep(): QT_Null {

        if (this._InterviewQueue.length === 0) return null

        return this._InterviewQueue.slice().reverse()[0]

    }


    private getNextTopic(currentTopic: TopicModel): TopicModel | null {

        const RemainingTopics = currentTopic.segment.topics.filter(t => t.sequence > currentTopic.sequence).sort((a, b) => a.sequence < b.sequence ? -1 : 1)

        if (RemainingTopics.length === 0) return null

        const Topic = RemainingTopics[0]

        if (Topic) this.InterviewQueuePush(Topic)

        return RemainingTopics[0]

    }

    // gets next question 
    public goNext(Q: QT, skipTopic?: boolean): QT_Null {

        const currentTopic = Q instanceof QuestionModel ? Q.topic : Q

        if (skipTopic) return this.getNextTopic(currentTopic)

        if (Q instanceof QuestionModel && Q.endTopic) return this.getNextTopic(currentTopic)

        if (Q instanceof QuestionModel) {

            if (Q.type === QuestionType.select) {

                const NQid = (Q.qMultipleAnswers as QuestionSelection[])[Q.answer.value].NextQuestionId

                if (NQid) {

                    const NQ = this.pitch.Questions().find((x) => x.QuestionContentId === NQid) as QuestionModel
                    if (NQ) {
                        this.InterviewQueuePush(NQ)
                        return NQ
                    } else {
                        throw new Error ('SegmentModel::goNext Next question with content ID ' + NQid + ' not found. Referenced from question with ID ' + Q.id)
                    }

                }
            }
        }

        const Seq = Q instanceof QuestionModel ? Q.sequence : -1

        const RemainingQuestions = currentTopic.questions.filter(t => t.sequence > Seq).sort((a, b) => a.sequence < b.sequence ? -1 : 1)

        if (RemainingQuestions.length !== 0) {
            const Q = RemainingQuestions[0]
            this.InterviewQueuePush(Q)
            return Q
        }

        return this.getNextTopic(currentTopic)

    }


    public goBack(current: QT): QT_Null {

        const endIndex = this._InterviewQueue.findIndex(x => x.id === current.id)

        this._InterviewQueue = this._InterviewQueue.slice(0, endIndex)

        this.save()

        return this.getCurrentInterviewStep()

    }


    getAllTopics(): TopicModel[] {
        const AllTopics: TopicModel[] = []

        this.topics.map(x => {
            AllTopics.push(x)
            return x
        })

        return AllTopics
    }

    getAllAnsweredQuestions(): QuestionModel[] {
        const Questions: QuestionModel[] = []

        this.topics.map(x => {

            x.questions.map(x => {

                if (x.answer != null) {
                    Questions.push(x)
                }

                return x
            })
            return x
        })

        return Questions;
    }

    private _SegmentQuestions: QuestionModel[]

    getAllQuestions(): QuestionModel[] {

        if (!this._SegmentQuestions) {

            this._SegmentQuestions = []

            this.topics.map(x => {

                return x.questions.map(x => {

                    this._SegmentQuestions.push(x)
                    return x

                })

            })

        }

        return this._SegmentQuestions

    }


    // MARK: - Video operations 

    async setVideo(video: VideoModel) {
        this.video = video
        video.segment = this
        await video.save()
    }

    hasVideo(): boolean {
        return (this.video !== null) &&
            (this.video !== undefined)
    }

    eraseVideo() {
        this.video = null
    }


    // MARK: - Script   

    resetScript() {
        // this.automatedScript = null
        this.manualScript = null
    }

    set Script(value: string) {
        this.manualScript = value;
    }

    get Script(): string {
        let result = ""
        if (this.manualScript && !this.manualScript.isEmpty()) {
            result = this.manualScript
        } else if (this.automatedScript && 
            !this.automatedScript.isEmpty() && 
            this.status !== SegmentStatus.Pending) 
        {
            result = this.automatedScript
        }

        return result
    }

    public PitchuraScriptWordCount(): number {
        return this.automatedScript ? this.WordCount(this.automatedScript) : 0
    }

    /* public PitchuraScriptMinsLength(): number {
        return this.MinsLength(this.PitchuraScriptWordCount())
    } */

    public UserScriptWordCount(words?: string): number {
        return this.WordCount(words ? words : this.Script)
    }

    public UserScriptMinsLength(wordsCount?: number): string {
        return this.MinsLength(wordsCount ? wordsCount : this.UserScriptWordCount())
    }

    private WordCount(value: string): number {
        return value.trim().split(/\s+/).filter(s => s !== '').length
    }




    static convertTimeToMinsSecs(value: number): MinsSecs {

        const lengthInSeconds = Math.round(value)

        if (lengthInSeconds < 60) {
            return { mins: 0, secs: lengthInSeconds }
        }
        else {

            const minutes = Math.floor(lengthInSeconds / 60);
            const seconds = lengthInSeconds - minutes * 60;

            return { mins: minutes, secs: seconds }

        }


    }

    static videoDurationInMinsSecs(duration: number): MinsSecs {

        return this.convertTimeToMinsSecs(duration)

    }

    private MinsLength(value: number): string {

        const length = SegmentModel.convertTimeToMinsSecs(this.SpeechDuration(value))

        if (length.mins === 0) {
            return `${length.secs} seconds`
        }
        else {
            return `${length.mins} ${length.mins > 1 ? 'minutes' : 'minute'}, ${length.secs} seconds`
        }
    }

    private SpeechDuration(value: number): number {
        const kSpeechRate = 110  // words per minute 
        return value / kSpeechRate * 60.0
    }
}
