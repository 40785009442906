import { Router } from "@mui/icons-material"
import { Box, TextField, Typography } from "@mui/material"
import { RoutingController } from "Controllers/RoutingController"

import { useAlert } from "hooks/UseAlert"
import { useAuth } from "hooks/UseAuth"
import { classes } from "Pages/Founder/VideoRecording/Setup/VideoSetup"
import { ChangeEvent, useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import { XJButton } from "UI Elements/Buttons/XJButton"
import { XJPitchuraLogo } from "UI Elements/XJPitchuraLogo"
import LoginLayout, { LoginLayoutClasses } from "./LoginLayout"



export default function EmailLoginChangePassword() {

    const { email } = useParams<any>()
    const { challenge } = useParams<any>()

    const auth = useAuth()

    const history = useHistory()

    const alert = useAlert()

    const [form, setForm] = useState({ newPassword: '' , confirmNewPassword: ''})

    const onSubmit = () => {


        if (form.confirmNewPassword !== form.newPassword)
        {
            alert.error('Confirmed password is different than the new password')
            return
        }

        auth.EmailChangePassword(email, challenge, form.newPassword,
            () => {
                history.replace(RoutingController.Home())
            },

            (err: string) => {

                alert.error(err)
            })

    }

    const classes = LoginLayoutClasses

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>): void => {

        const value =  event.currentTarget.value.trim ()
        const name = event.currentTarget.name;

        setForm({
            ...form, [name]: value
        })
    }

    const inputBoxWidth = '250px !important';

    return <>
        <LoginLayout>
            <Box sx={classes.formHeader}>
                <Box sx={classes.title}>
                    <Box display={'flex'} gap={8} mb={8} alignItems={'center'}>
                        <XJPitchuraLogo variant='PNG+Type' />
                    </Box>

                    <Box display={'flex'} justifyContent={'center'} width={'100% !important'}>
                        <Typography variant='h2'>
                            Change password
                        </Typography>
                    </Box>

                </Box>
            </Box>
            <Box mb={24}>

            </Box>
            <Box sx={{ ...classes.FormBoxes, ...classes.form }}>
                <Box sx={{ mb: 16 }}>
                    <Box sx={{ ml: 0, mb: 8 }}  >
                        <Typography variant='h3'>New password:</Typography>
                    </Box>
                    <Box width={inputBoxWidth}  >
                        <TextField
                            name="newPassword"
                            color='secondary'
                            variant="outlined"
                            value={form.newPassword}
                            onChange={handleInputChange}
                            placeholder={'new password'}
                        />
                    </Box>

                </Box>
                <Box sx={{ mb: 16 }}>
                    <Box sx={{ ml: 0, mb: 8 }}  >
                        <Typography variant='h3'>Confirm password:</Typography>
                    </Box>
                    <Box width={inputBoxWidth}  >
                        <TextField
                            name="confirmNewPassword"
                            color='secondary'
                            variant="outlined"
                            value={form.confirmNewPassword}
                            onChange={handleInputChange}
                            placeholder={'confirm new password'}

                        />
                    </Box>

                </Box>

            </Box>
            <Box sx={{ mx: 'auto', mt: 24 }}>
                <XJButton variant="secondary" onClick={onSubmit}>Change password</XJButton>
            </Box>
        </LoginLayout>
    </>






}