import { valueOrEmpty } from "tools/valueOrEmpty";
import XJImage from "./XJImage";
import { SxProps } from "@mui/material";

export function XJUserLogo(props: { src: string | null | undefined, sx?: SxProps }) {

    const styles = {
        width: '40px', 
        height: '40px', 
        backgroundSize: 'contain', 
        backgroundPosition: 'center',
        ...props.sx
    } as SxProps

    return (valueOrEmpty(props.src) !== '' ? 
        <XJImage 
            sx={styles} 
            src={valueOrEmpty(props.src)} 
        /> 
        : 
        null
    )
}