

import { useAuth } from "hooks/UseAuth"

import { useEffect, useState } from "react"
import { useHistory, useParams } from "react-router-dom"

import { useSpinner } from "UI Elements/XJSpinner"
import { EmailVerified } from "UI Elements/EmptyPageMessage/EmailVerified"
import { ErrorPage } from "UI Elements/EmptyPageMessage/ErrorPage"


type RouteParams =
    {
        email: string
        challenge: string
    }

export default function VerifyEmail() {

    const { email } = useParams<RouteParams>()
    const { challenge } = useParams<RouteParams>()
    const user = useAuth()

    const [isError, setIsError] = useState(false)
    const [isSuccess, setIsSuccess] = useState (false)

    const spinner = useSpinner()

    const onSuccess = () => {

        spinner(false)
        setIsSuccess (true)
       

    }

    const onError = (err: string) => {
        spinner(false)
        setIsError (true)

    }

    useEffect(

        () => {
            spinner (true)
            user.VerifyEmail(email, challenge, onSuccess, onError)
        }, [])

    return <>
    
        {isSuccess && <EmailVerified />}
        {isError && <ErrorPage/>}
    </> 

}