import { CSSProperties, useEffect, useRef, useState } from 'react'
import { PitchModel } from 'Model/PitchModel'
import { Prompt, useHistory, useParams } from 'react-router-dom'
import { usePitches } from 'hooks/UsePitches'
import XJBreadcrumbs from '../../../UI Elements/XJBreadcrumbs'
import { Box, Fade, Grid2, Link, OutlinedInput, SxProps, Typography } from '@mui/material'
import { mapVideoModel, VideoPlayer } from 'Pages/Video/Player/VideoPlayer'
import { XJButton } from 'UI Elements/Buttons/XJButton'
import { ArrowLeftIcon, ArrowRightIcon, DropDownIconSmall, DropUpIconSmall, ListIcon, QAProgressSegmentDone, UploadIcon, VideoCameraIcon } from 'UI Elements/XJIcons'
import XJStatusBadge, { BadgeColor } from 'UI Elements/XJStatusBadge'
import XJTabs from 'UI Elements/XJTabs'
import { QuestionType, SegmentStatus } from 'Common/Enums'
import { useAlert } from 'hooks/UseAlert'
import { SegmentModel } from 'Model/SegmentModel'
import { BInfoBold, BInfoRegular, copyPrimaryBold, copyPrimaryRegular, darkPrimaryDefault, Header, HeaderRightButtons, lightPrimaryOverlay24, lightPrimaryOverlay32, lightPrimaryOverlay48, MainContent, PageTitle, secondaryDefault, secondaryLight60, secondaryLight75, secondaryLight85, SVGStyle, theme, whiteDefault, whiteOverlay40 } from 'XJumpTheme'
import { QuestionModel } from 'Model/QuestionModel'
import { RoutingController } from 'Controllers/RoutingController'
import { XJMenuButton } from 'UI Elements/Buttons/XJMenuButton'
import { ExplanationBox } from 'UI Elements/XJExplanationBox'
import { useXJMediaUploadContext } from 'Components/XJMediaUpload'
import { useSpinner } from 'UI Elements/XJSpinner'
import useExplainerShouldShow from 'hooks/UseExplainersShouldShow'
import { LogError } from 'Controllers/Logging'
import { ReactComponent as WorkinOnScript } from '../../../svg/working-on-script.svg'
import { ReactComponent as ReadyToRecord } from '../../../svg/ready-to-record.svg'
import { TopicModel } from 'Model/TopicModel'
import { XJDropdown } from 'UI Elements/XJDropdown'
import { LinkVariant } from 'UI Elements/XJLink'
import { XJLinkButton } from 'UI Elements/Buttons/XJLinkButton'
import { XJTooltip } from 'UI Elements/XJTooltip'
import { NotFoundBanner } from 'UI Elements/NotFoundBanner'

export function showSegmentBadge(segment: SegmentModel) {

    switch (segment.status) {

        case SegmentStatus.Video:

            return <XJStatusBadge type={BadgeColor.green} text={segment.StatusLabel} />

        case SegmentStatus.Script:

            return <XJStatusBadge type={BadgeColor.blue} text={segment.StatusLabel} />

        case SegmentStatus.Pending:

            return <XJStatusBadge type={BadgeColor.blue} text={segment.StatusLabel} />

        case SegmentStatus.New:

            return <XJStatusBadge type={BadgeColor.blue} text={segment.StatusLabel} />
        default:
            return <></>
    }
}

export function SegmentEditorPage() {

    const { pid } = useParams<any>()
    const { sid } = useParams<any>()

    const pitchId = Number(pid)
    const segmentId = Number(sid)

    const pitch = usePitches().getPitch(pitchId) as PitchModel  // PitchModel.getPitchById(props.pitches, pitchId)  

    let segment, nextSegment, prevSegment

    if (pitch) {
        segment = pitch.getSegmentById(segmentId)
        if (segment) {
            nextSegment = pitch?.getNextSegment(segment)
            prevSegment = pitch?.getPrevSegment(segment)
        }
    }

    return pitch && segment ? 
    <SegmentEditor 
        segment={segment}
        nextSegment={nextSegment}
        prevSegment={prevSegment}
    />
    : 
    <NotFoundBanner
        text={!pitch ? 'This pitch cannot be accessed' : "This segment cannot be accessed"}
        urlText='Go home'
        url={RoutingController.Home()}
    />
}

interface SegmentEditorProps {
    segment: SegmentModel
    nextSegment?: SegmentModel 
    prevSegment?: SegmentModel
}

const SegmentEditor = (props: SegmentEditorProps) => {

    const history = useHistory<any>()

    const segment = props.segment
    const nextSegment = props.nextSegment
    const prevSegment = props.prevSegment
    const pitch = segment.pitch
    const segments = pitch.segments

    const [pitchuraScriptValue, setPitchuraScriptValue] = useState("")
    const [pitchuraScriptWordCount, setPitchuraScriptWordCount] = useState(0)
    const [pitchuraScriptTimeLength, setPitchuraScriptTimeLength] = useState("")

    const alert = useAlert()
    const spinner = useSpinner()

    useEffect(() => {
        setPitchuraScriptValue(segment.automatedScript ? segment.automatedScript : '')
        setPitchuraScriptWordCount(segment.PitchuraScriptWordCount())
        // setPitchuraScriptTimeLength(segment.PitchuraScriptMinsLength())
        segment.ResetShowPitchuraScriptReady()
    }, [segment])


    const uploderRef = useXJMediaUploadContext()

    const refUserScript = useRef<string>()


    // MARK: - Actions 

    const [saving, setOnSaving] = useState(false)

    const onSaveClick = async () => {

        setOnSaving(true)
        segment.Script = refUserScript.current ? refUserScript.current : ''
        spinner(true)

        segment.save()
            .then(async (res) => {
                if (segment.video) {
                    await segment.video.save()
                }
            })
            .then(() => {
                alert.successSave()
                history.push(RoutingController.PitchEditor(pitch))
            })
            .catch(err => {

                spinner(false)
                LogError(err)
                alert.APIError()

            })
    }

    const onCancelClick = () => {
        uploderRef.current?.cancelUpload()
        history.push(RoutingController.PitchEditor(pitch))
    }

    const changeSegmentTo = (segment: SegmentModel) => {
        history.push(RoutingController.SegmentEditor(segment))
    }

    const onSegmentSelect = (segmentId: number) => {
        const segment = pitch.segments.find(s => s.id === segmentId)
        if (segment) {
            changeSegmentTo(segment)
        }
    }

    const Script = () => {

        const [userScriptValue, setUserScriptValue] = useState("")
        const [userScriptWordCount, setUserScriptWordCount] = useState(0)
        const [userScriptTimeLength, setUserScriptTimeLength] = useState('')

        const [showResetButton, setShowResetButton] = useState(true)
        const [IsDirty, setIsDirty] = useState(false)

        const handleUserScriptTextChange = (e: any) => {
            setUserScriptValue(e.target.value)
            setIsDirty(e.target.value !== segment.Script)
        }

        refUserScript.current = userScriptValue

        useEffect(() => {
            setUserScriptValue(segment.Script)
            setUserScriptWordCount(segment.UserScriptWordCount())
            setUserScriptTimeLength(segment.UserScriptMinsLength())
        }, [segment])

        useEffect(() => {

            const wordsCount = segment.UserScriptWordCount(userScriptValue)
            setUserScriptWordCount(wordsCount)
            setUserScriptTimeLength(segment.UserScriptMinsLength(wordsCount))

            setShowResetButton(userScriptValue === pitchuraScriptValue)

        }, [userScriptValue])


        // MARK: - Script Tab Render

        const ScriptLengthBox = (wordcount: number, length: string, lasUpdate: Date) => {
            
            const styles = {
                root: {
                    display: 'flex',
                    gap: 8,
                    alignItems: 'center'
                } as CSSProperties
            }

            return <Box sx={styles.root}>
                <Box display={'flex'}>
                    <Typography sx={{ ...BInfoBold }}> {wordcount} words: &nbsp; </Typography>
                    <Typography sx={{ ...BInfoBold }}>{length}</Typography>
                </Box>
                <Box>
                    <Typography sx={BInfoRegular}>{lasUpdate ? lasUpdate.toLocaleDateString() : ''}</Typography>
                </Box>
            </Box>
        }

        const TopicIncompleteIconPlaceholder = () => {
            return <Box width={'24px'} height={'24px'} minWidth={'24px'} minHeight={'24px'} />
        }

        const TopicTitle = (props: {t: TopicModel}) => {
            const topic = props.t

            const styles = {
                topic: {
                    display: 'flex', 
                    alignItems: 'center', 
                    gap: 6
                } as CSSProperties,
                statusIcon: {
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                } as CSSProperties
            }

            return ( 
                <Box sx={styles.topic}>
                    <Box sx={styles.statusIcon}>
                        {topic.isComplete() ? <QAProgressSegmentDone color={secondaryDefault}/> : <TopicIncompleteIconPlaceholder />}
                    </Box>

                    <XJLinkButton variant={LinkVariant.primary} to={RoutingController.QA_Edit(topic)!}>
                        {topic.title}
                    </XJLinkButton>
                </Box>
            )
        }

        const Separator = () => {
            return <Box sx={{width: '100%', height: '1px', my: 4, backgroundColor: lightPrimaryOverlay32}} />
        }

        const TopicWithAnswers = (props: {t: TopicModel}) => {

            const topic = props.t
            const answers = topic.questions.filter(x => x.answer)

            const onQuestionClick = (q: QuestionModel) => {
                history.push(RoutingController.QA_Edit(q)!)
            }

            const EditUserAnswerLink = (q: QuestionModel): JSX.Element => {

                let answer = ''
        
                if (q.answer) {
                    if (q.type === QuestionType.select) {
                        answer = q.qMultipleAnswers![Number(q.answer.value)].title
                    } else {
                        answer = q.answer.value
                    }
                }

                const styles = {
                    root: {
                        overflow: 'hidden', 
                        display: '-webkit-box',
                        WebkitBoxOrient: 'vertical', 
                        WebkitLineClamp: 1, 
                        wordBreak: 'break-all',
                        ':focus': {
                            borderRadius: '2px',
                            border: '2px solid ' + secondaryLight60
                        },
                        ':hover': {
                            backgroundColor: lightPrimaryOverlay24
                        } as CSSProperties,
                        ':active': {
                            backgroundColor: lightPrimaryOverlay48
                        } as CSSProperties,
                        ':disabled': {
                            color: secondaryLight75,
                            backgroundColor: secondaryLight85
                        }
                    } as CSSProperties
                }
        
                return (
                    <Link sx={styles.root}
                        underline={'none'}
                        onClick={() => onQuestionClick(q)} 
                    >
                        <XJTooltip title={answer} sx={{width: 'auto', height: 'auto'}}>
                            <Typography sx={{...copyPrimaryBold, color: secondaryDefault}}>
                                {answer}
                            </Typography>
                        </XJTooltip>
                    </Link>
                )
            }
            
            const styles = {
                root: {
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 8,
                    py: 4
                } as CSSProperties,
                answersBox: {
                    display: 'flex',
                    alignItems: 'center',
                    gap: 6
                } as SxProps,
                answers: { 
                    display: 'flex',
                    flexDirection: 'column',
                    flexGrow: 2,
                    gap: 4
                } as SxProps,
                question: {
                    display: 'flex', 
                    alignItems: 'top', 
                    justifyContent: 'space-between',
                    flexWrap: 'wrap',
                    flexGrow: 2,
                    rowGap: 4,
                    gap: 2,
                    minHeight: '40px',
                } as SxProps,
                questionText: {
                    flexGrow: 2,
                    cursor: 'pointer', 
                    display: 'flex', 
                    alignItems: 'center',
                } as SxProps,
                questionTypography: {
                    overflow: 'hidden', 
                    display: '-webkit-box',
                    WebkitBoxOrient: 'vertical', 
                    WebkitLineClamp: 1, 
                    ...copyPrimaryRegular, 
                    color: darkPrimaryDefault,
                    textAlign: 'left'
                } as CSSProperties,
                value: {
                    minWidth: '50px',
                    display: 'flex', 
                    justifyContent: 'center', 
                    alignItems: 'center',
                    marginLeft: 'auto',
                    height: '40px',
                    px: 4,
                    overflow: 'hidden',
                    background: whiteOverlay40, 
                    borderRadius: '2px', 
                    cursor: 'pointer',
                    ':focus': {
                        borderRadius: '2px',
                        border: '2px solid ' + secondaryLight60
                    },
                    ':hover': {
                        backgroundColor: lightPrimaryOverlay24
                    } as CSSProperties,
                    ':active': {
                        backgroundColor: lightPrimaryOverlay48
                    } as CSSProperties,
                    ':disabled': {
                        color: secondaryLight75,
                        backgroundColor: secondaryLight85
                    }
                } as CSSProperties
            }

            return (
                <Box sx={styles.root}>
                    <TopicTitle t={topic} />
                    <Box sx={styles.answersBox}> 
                        <TopicIncompleteIconPlaceholder />
                        <Box sx={styles.answers}> 
                            {answers.map(q => { return (        
                                <Box key={`topic${topic.id}Q${q.id}`} sx={styles.question}>
                                    <Box sx={styles.questionText} onClick={() => onQuestionClick(q)}>
                                        <XJTooltip title={q.title} sx={{width: 'auto', height: 'auto'}}>
                                            <Typography sx={styles.questionTypography}>
                                                {q.title}
                                            </Typography>
                                        </XJTooltip>
                                    </Box>

                                    <Box sx={styles.value}>
                                        {q.hasAnswer() ?  EditUserAnswerLink(q) : null}
                                    </Box>
                                </Box>
                            )})}
                        </Box>
                    </Box>
                </Box>
            )
        }

        const TopicWithoutAnswers = (props: {t: TopicModel}) => {
            const styles = {
                root: {
                    py: 4
                } as CSSProperties,
                noAnswers: {
                    pt: 6,
                    display: 'flex', 
                    alignItems: 'center', 
                    gap: 6
                },
                text: {
                    ...copyPrimaryRegular,
                    color: darkPrimaryDefault,
                    overflow: 'hidden',
                    display: '-webkit-box',
                    wordBreak: 'break-all',
                    textAlign: 'left',
                    WebkitBoxOrient: 'vertical', 
                    WebkitLineClamp: 1
                } as CSSProperties
            }

            return (
                <Box sx={styles.root}> 
                    <TopicTitle t={props.t} />
                    <Box sx={styles.noAnswers}>
                        <TopicIncompleteIconPlaceholder />
                        <Typography sx={styles.text}>
                            No answers yet, click above to start
                        </Typography>
                    </Box>
                </Box>
            )
        }

        const NoTopics = (props: {s: SegmentModel}) => {

            const styles = {
                root: {
                    ...copyPrimaryBold
                } as CSSProperties
            }

            return <Box sx={styles.root}>
                {/* // commented out until we figure out way to represent it  */}
                {/* {props.s.title + ' segment: there are no topics in this segment' } */} 
            </Box>
        }

        const styles = {
            itemLeft: {
                display: 'flex',
                flexDirection: 'column',
                borderRadius: '2px',
                background: whiteDefault,
                px: 12,
                py: 8,
                height: '100%',
                maxWidth: '100%'
            } as CSSProperties,
            topTitle: {
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                mb: 4,
                color: secondaryDefault
            } as CSSProperties,
            inputField: {
                display: 'block',
                flexGrow: 2,
                border: lightPrimaryOverlay48,
                borderImage: lightPrimaryOverlay48,
                ...theme.typography.primary.regular,
                color: theme.palette.secondary.default
            } as CSSProperties,
            scriptBottom: {
                display: 'flex',
                justifyContent: 'space-between',
                mt: 4,
                height: '40px'
            } as CSSProperties,
            scriptPending: {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: 8,
                px: 32,
                py: 32,
                mb: 20,
                borderRadius: '2px',
                border: '1px solid', 
                borderColor: lightPrimaryOverlay48
            } as CSSProperties,
            scriptPendingIcon: {
                display: 'flex',
                flexDirection: 'column',
                width: '90px',
                height: '90px'
            } as CSSProperties,
            scriptPendingContent: {
                display: 'flex', 
                gap: 16,
                flexDirection: 'column',
                alignItems: 'center'
            } as CSSProperties,
            itemRight: {
                overflow: 'clip',
                display: 'flex', 
                flexDirection: 'column', 
                height: '100%',
                borderRadius: '2px',
                background: whiteDefault,
                px: 12, 
                pt: 8,
                pb: 12,
                maxHeight: '512px',
                maxWidth: '100%'
            } as CSSProperties,
            scroll: {
                overflowY: 'auto'
            } as CSSProperties
        }

        return <Grid2 container columnSpacing={16} alignItems={'stretch'}>

            <Prompt
                message={'Script has unsaved changes. Are you sure you want to continue? all changes will be lost'}
                when={IsDirty && !saving} 
            />

            <Grid2 size={{xs: 6}} alignContent={'stretch'} flexGrow={2}>
                <Box sx={styles.itemLeft}>
                    <Box sx={styles.topTitle}>
                        <Typography variant='h2'>
                            Script
                        </Typography>
                    </Box>

                    {showPitchuraScript ?
                    <>
                        <OutlinedInput
                            id="outlined-multiline-static"
                            value={userScriptValue}
                            color='secondary'
                            multiline
                            autoFocus
                            key="outlined-multiline-static"
                            rows={10}
                            onChange={handleUserScriptTextChange}
                            fullWidth={true}
                            sx={styles.inputField}
                        />

                        <Box sx={styles.scriptBottom}>
                            {ScriptLengthBox(userScriptWordCount, userScriptTimeLength, segment.lastUpdated)}
                            {showPitchuraScript &&
                                <XJButton
                                    variant={'secondary'}
                                    disabled={showResetButton}
                                    sx={{ display: pitchuraScriptValue.length > 0 ? 'block' : 'none' }}
                                    onClick={() => {
                                        setUserScriptValue(pitchuraScriptValue)
                                        setIsDirty(true)
                                    }}
                                >
                                    Reset changes
                                </XJButton>}
                        </Box>
                    </>
                    :
                    <Box sx={styles.scriptPending}>
                        <Box sx={styles.scriptPendingIcon}>
                            <WorkinOnScript style={SVGStyle} />
                        </Box>

                        <Box sx={styles.scriptPendingContent}>
                            <Typography variant='h2'>
                                Working on it...
                            </Typography>
                            <ExplanationBox centered >
                                Our team is currently working on a script for this segment. <br /> We'll send you an email as soon as it's ready.
                            </ExplanationBox>
                        </Box>
                    </Box>}
                </Box>
            </Grid2>

            <Grid2 size={{xs: 6}}  sx={{maxHeight: `calc(100% - 40px)`}} >
                {segment.getAllQuestions().length > 0 ? 
                <>
                    <Box sx={styles.itemRight} key={'scriptBox'}>
                        <Box sx={styles.scroll}>
                            <Box sx={styles.topTitle}>
                                <Typography variant='h2'>
                                    Your answers
                                </Typography>
                            </Box>
                        
                            <Box id={'Your answers'} flexGrow={1}>
                                {segment.topics.map((t, index) => {
                                    return <Box key={`topic${t.id}`}>
                                        {t.hasAnswers() ? 
                                        <TopicWithAnswers t={t} /> 
                                        :
                                        <TopicWithoutAnswers t={t} />}
                                        {index !== segment.topics.length - 1 && <Separator />}
                                    </Box>
                                })}
                            </Box>
                        </Box>
                    </Box>
                </>
                :
                <NoTopics s={segment}/>}
            </Grid2>
        </Grid2>
    }


    // MARK: - Render video

    const shouldShow = useExplainerShouldShow().ShouldShow

    const Video = () => {
        return <>

            {segment.video && <RenderVideoPlayer />}

            <Grid2 display={segment.video ? 'none' : 'block'} size={{xs: 8}} >
                <RenderNoVideoPanel />
            </Grid2>
        </>
    }



    const onVideoError = () => {

        LogError(`error in SegmnetEditor loading video: ${segment.id}`)

    }

    const RenderVideoPlayer = () => {

        return <>
            <Grid2  size={{xs: 12, md: 8}} position='relative'>
                {segment.video && <VideoPlayer

                    id={segment.video.id.toString()}
                    // sx={playerStyle}
                    video={mapVideoModel(segment.video)}
                    //  onReady={onVideoReady}
                    onError={onVideoError}

                    showDefaultTimeline
                    showDefaultControls
                />}
            </Grid2>

            <Grid2  size={{xs: 12, md: 4}}   >
                <Box display={'flex'} justifyContent={'space-between'} >
                    <Box display={{ xs: 'none', md: 'block' }}></Box>

                    <Box display="flex" flexDirection='column' gap={8} mt={{ xs: 16, md: 0 }} width={{ xs: '100%', md: 'auto' }} >
                        <XJButton to={RoutingController.SegmentVideoRecordOrSetup(segment, shouldShow)} variant={'secondary'}>
                            Record
                        </XJButton>

                        <XJButton to={RoutingController.SegmentVideoUpload(segment)} variant={'secondary'}>
                            Upload video
                        </XJButton>

                        <XJButton to={RoutingController.VideoEditor(segment.video!)} variant={'secondary'}>
                            Edit video
                        </XJButton>
                    </Box>
                </Box>
            </Grid2>
        </>
    }

    const RenderNoVideoPanel = () => {
        const styles: { [id: string]: CSSProperties } = {
            root: {
                display: 'flex',
                flexDirection: 'column',
                //gap: 16,
                paddingTop: 32,
                paddingBottom  : 32,
                paddingLeft: 64,
                paddingRight: 64,
                //  aspectRatio: '16 / 9',
                alignItems: 'center',
                backgroundColor: whiteDefault,
                justifyContent: 'center'
            },

            buttons: {
                display: 'flex',
                gap: 16,
                alignItems: 'center',
                color: darkPrimaryDefault,
                marginBottom: 16
            }
        }

        return <Box sx={styles.root}>
            <Box mb={16}>
                <ReadyToRecord style={SVGStyle}/>
            </Box>
            <Box mb={32}>

                <Box mb={8} textAlign={'center'}>
                    <Typography variant='h2'>
                        Ready to record?
                    </Typography>
                </Box>

                <ExplanationBox centered>
                    Use your webcam and our teleprompter to record a video for this segment. <br />You can also upload your own recorded video, like a product demo for example.
                </ExplanationBox>

            </Box>

            <Box sx={styles.buttons}>
                <XJButton to={RoutingController.SegmentVideoUpload(segment)} variant={'tertiary'} iconLeft>
                    <UploadIcon /> Upload
                </XJButton>
                <XJButton to={RoutingController.SegmentVideoRecordOrSetup(segment, shouldShow)} variant={'secondary'} iconLeft>
                    <VideoCameraIcon /> Record
                </XJButton>
            </Box>
        </Box>
        // </Grid>
    }

    // const showVideoTab = segment.status === SegmentStatus.Done
    const showPitchuraScript = segment.status !== SegmentStatus.Pending



    // MARK: - Render other 

    const RenderBottomNav = () => {

        const [showList, setShowList] = useState(false)

        // MARK: - Render 

        const styles = {
            root: {
                display: 'flex',
                flexDirection: 'column-reverse',
                alignItems: 'flex-end',
                position: 'fixed',
                right: theme.spacing(30),
                bottom: theme.spacing(14),
                zIndex: theme.zIndex.appBar - 1
            },
            buttonsBox: {
                display: 'flex',
                flexDirection: 'row-reverse',
                gap: 12
            },
            container: {
                width: 'fit-content',
                height: showList ? 'auto' : "0px",
                visibility: showList ? 'visible' : 'hidden',
            },
            dropdown: {
                width: '222px',
                maxHeight: '500px'
            }
        }

        return (
            <Box sx={styles.root}>
                <Box sx={styles.buttonsBox}>
                    <XJButton
                        variant={'secondary'}
                        iconLeft
                        dropdown
                        onClick={() => setShowList((prev) => !prev)}
                    >
                        <ListIcon color={theme.palette.white.default} />
                        Segments
                        {!showList ? <DropUpIconSmall color={theme.palette.white.default} /> : <DropDownIconSmall color={theme.palette.white.default} />}
                    </XJButton>

                    <XJMenuButton
                        variant={'primary'}
                        disabled={(!nextSegment)}
                        onClick={() => {
                            if (nextSegment) changeSegmentTo(nextSegment)
                        }}
                    >
                        <ArrowRightIcon />
                    </XJMenuButton>

                    <XJMenuButton
                        variant={'primary'}
                        disabled={(!prevSegment)}
                        onClick={() => {
                            if (prevSegment) changeSegmentTo(prevSegment)
                        }}
                    >
                        <ArrowLeftIcon />
                    </XJMenuButton>
                </Box>

                <Fade in={showList}>
                    <Box sx={styles.container}>
                        <XJDropdown sx={styles.dropdown}
                            options={segments.map(s => {return {value: s.id, title: s.sequence + 1 + ': ' + s.title}})}
                            value={segment.id}
                            onSelect={onSegmentSelect}
                        />
                    </Box>
                </Fade>
            </Box>
        )
    }


    const showVideoTab = segment.status === SegmentStatus.Video

    return <>
        <Grid2 container>
            <Grid2 size={{xs: 12}}  sx={Header}>
                <XJBreadcrumbs />
                <Box sx={HeaderRightButtons}>
                    {/*                     <XJButton onClick={SaveSegment} Variant={'secondary'}>Save</XJButton> */}
                    <XJButton onClick={onCancelClick} variant={'tertiary'}>Back</XJButton>
                    <XJButton onClick={onSaveClick} variant={'secondary'}>Save</XJButton>
                </Box >
            </Grid2 >

            <Grid2  size={{xs: 12}} sx={PageTitle} gap={8}>
                <Typography variant='h1' color='secondary'>
                    {segment.title}
                </Typography>
                {/* <InfoIcon /> */}
                {showSegmentBadge(segment)}
            </Grid2>

            <Grid2 size={{xs: 12}} sx={MainContent}>
                <Box >
                    <XJTabs key={`main${segment.id}`} index={showVideoTab ? 1 : 0}>
                        <Box id='Script' key={'script'}>
                            <Script />
                        </Box>
                        <Box id='Video' key='video'>
                            <Grid2 container>
                                <Video />
                            </Grid2>
                        </Box>
                    </XJTabs>
                </Box>
            </Grid2>
        </Grid2 >

        <RenderBottomNav />
    </>
}

