import { Box, SxProps, Typography } from "@mui/material"
import { useAlert } from "hooks/UseAlert"
import { useAuth } from "hooks/UseAuth"
import { useContext } from "react"
import { XJButton } from "UI Elements/Buttons/XJButton"
import { XJLinkButton } from "UI Elements/Buttons/XJLinkButton"
import BigAlertContext from "UI Elements/XJBigAlerts"
import { IconError } from "UI Elements/XJIcons"
import { denotiveWarningDefault, whiteDefault } from "XJumpTheme"

const styles: SxProps =
{
    position: 'absolute',
    top: 8,
    display: 'flex',
    justifyItems: 'center',
    width: '100%',
    color: whiteDefault,
    mx: 'auto',
    
}

const innerStyle: SxProps = {

    backgroundColor: denotiveWarningDefault,
    mx: 'auto',
    display: 'flex',
    gap:  8 ,
    alignItems:'center',
    width: 'fit-content',
    py: 4,
    pl: 8,
    pr: 4,
    zIndex: 5000
}

export default function XJVerifyEmailBanner() {

    const sendEmail = useAuth().SendEmailVerification
    const emailVerified = useAuth().EmailVerifiedStatus
    const email = useAuth().user.User?.email

    const BigAlert = useContext(BigAlertContext)
    const alerts = useAlert()

    const onVerifyClick = () => {
        alerts.working()
        sendEmail(onVerifyEmailSent, errorSendingEmail)
    }

    const onVerifyEmailSent = () => {
        alerts.clearWorking()
        BigAlert.info(`We sent a verification email to ${email}. Click on the link in it to verify your email address.`, 'Email verification')
    }

    const errorSendingEmail = (err: string) => {
        alerts.clearWorking()
        alerts.error(err)
    }

    const ShowBanner = () => {

        return (
            <Box sx={styles}>
                <Box sx={innerStyle}>
                    <IconError />
                    <Typography variant="h3">
                        Email needs to be verified before pitches can go live
                    </Typography>
                    <Box ml={8}>
                        <XJButton variant={'tertiary'}  onClick={onVerifyClick}>
                            verify
                        </XJButton>
                    </Box>
                    {/* <XJLinkButton to={"#"} onClick={onVerifyClick}>
                        <Typography variant="h3">verify</Typography>
                    </XJLinkButton> */}
                </Box>

            </Box>
        )
    }

    return <>
        {emailVerified === false ? ShowBanner() : null}
    </>
}