import React, { useRef, useState } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import 'custom.css';

import Layout from './Pages/Layout';
import { PitchEditor } from './Pages/Founder/PitchStudio/PitchEditor';
import { SegmentEditorPage } from './Pages/Founder/SegmentEditor/SegmentEditor';
import VideoEditingPage from './Pages/Founder/VideoEditor/VideoEditing'

import NewPitch from './Pages/Founder/NewPitch';
import SharedLinks from './Pages/Founder/SharedLinks';
import { PitchGoLive } from './Pages/Founder/PitchGoLive';

import Dashboard from './Pages/Founder/Dashboard';
import Login from './Pages/auth/Login';
import { useAuth } from './hooks/UseAuth';

import { ProfilePage } from './Pages/Founder/Profile/ProfilePage';
import { ProfileGoLive } from './Pages/Founder/Profile/ProfileGoLive';
import { SegmentQuestionsEdit } from './Pages/Founder/SegmentEditor/SegmentQuestionsEdit';
import { InvestorPitchView } from './Pages/Investor/InvestorPitchView';
import { theme } from 'XJumpTheme';
import { SegmentEditTopic } from 'Pages/Founder/SegmentEditor/SegmentEditTopic';
import { CssBaseline, Box, StyledEngineProvider, Typography, SxProps } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';

import { Oauth } from 'Pages/auth/oauth';
import { TemplateEditor } from 'Pages/Admin/TemplateEditor';
import VideoRecord from 'Pages/Founder/VideoRecording/Recorder/VideoRecord';
import VideoSetupIntro from 'Pages/Founder/VideoRecording/Setup/VideoSetupIntro';
import VideoSetup from 'Pages/Founder/VideoRecording/Setup/VideoSetup';
import InjectAxiosInterceptors from 'InjectAxiosInterceptors';
import EmailLoginChangePassword from 'Pages/auth/EmailLoginChangePassword';
import VerifyEmail from 'Pages/auth/VerifyEmail';
import { SystemQuestions } from 'Pages/Admin/Questions/SystemQuestions';
import { RequiresData } from 'Components/RequiresData';

import { InterviewQuestion } from 'Pages/Founder/Interview/InterviewQuestion';
import { InterviewTopic } from 'Pages/Founder/Interview/InterviewTopic';
import { PitchuraRole } from 'Common/Enums';
import { ScriptsPendingReview } from 'Pages/Admin/ScriptsPendingReview';
import { ScriptReview } from 'Pages/Admin/ScriptReview';
import UploadVideo from 'Pages/Founder/SegmentEditor/UploadVideo';
import { XJSpinnerProvider } from 'UI Elements/XJSpinner';
import { Home } from 'Pages/public/Home';
import { Terms } from 'Pages/public/terms';
import { Privacy } from 'Pages/public/privacy';
import { SystemTopics } from 'Pages/Admin/Topics/SystemTopics';
import { BigAlertProvider } from 'UI Elements/XJBigAlerts';
import { useResizeObserver } from 'usehooks-ts';
import { Contact } from 'Pages/contact';
import { TestUIPage } from 'Test/Test';

import { TutorialsEditor } from 'Pages/Admin/TutorialsEditor';
import { Close48Icon } from 'UI Elements/XJIcons';
import XJIconButton from 'UI Elements/Buttons/XJIconButton';
import { ErrorPage } from 'UI Elements/EmptyPageMessage/ErrorPage';


function PrivateRoute({ children, ...rest }: { children: React.ReactNode, exact?: any, path: string }) {
    const user = useAuth().user
    return (
        <Route
            {...rest}
            render={({ location }) =>
                (user.Id) ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: '/login',
                            search: `return=${location.pathname + location.search}`
                        }}
                    />
                )
            }
        />
    );
}


function AdminRoute({ children, ...rest }: { children: React.ReactNode, exact?: any, path: string }) {

    const user = useAuth().user

    return (
        <Route

            {...rest}

            render={
                ({ location }) => {
                    if (user.Id && user.User?.role === PitchuraRole.PitchuraAdmin) {
                        return children
                    } else {
                        return <Redirect
                            to={{
                                pathname: '/login',
                                search: `return=${location.pathname}&logout=1`
                            }}
                        />
                    }
                }
            }
        />
    );
}



export default function App() {

    const SessionStorageVersionBannerHiddenKey = 'VersionBannerHidden'

    const urlParams = new URLSearchParams(window.location.search)
    const hideVersionBannerQuery = urlParams.get('hideVersionBanner')

    if (hideVersionBannerQuery) {
        if (hideVersionBannerQuery === '0') {
            sessionStorage.setItem(SessionStorageVersionBannerHiddenKey, 'false')
        } else {
            sessionStorage.setItem(SessionStorageVersionBannerHiddenKey, 'true')
        }
    }

    const hideVersionBanneValue = sessionStorage.getItem(SessionStorageVersionBannerHiddenKey)
    const [hideVersionBanner, setHideVersionBanner] = useState<boolean>(hideVersionBanneValue ? hideVersionBanneValue === 'true' : false)

    const versionBannerRef = useRef(null)
    const { width: bannerWidth = 0, height: bannerHeight = 0 } = useResizeObserver({
        ref: versionBannerRef,
        box: 'border-box'
    })

    const onVersionBannerClose = () => {
        setHideVersionBanner(true)
        sessionStorage.setItem(SessionStorageVersionBannerHiddenKey, 'true')
    }

    const styles = {
        root: {
            height: '100vh'
        } as SxProps,

        versionBanner: {
            top: 0,
            left: 0,
            right: 0,
            py: 8,
            pl: 40,
            pr: 10,
            backgroundColor: 'lightyellow',
            position: 'fixed',
            zIndex: theme.zIndex.appBar + 1,

            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'

        } as SxProps,

        versionBannerPlaceholder: {
            position: 'relative',
            py: 8,
            px: 40,
            height: `${bannerHeight}px`
        } as SxProps,

        body: {
            position: 'relative',
            display: 'flex',
            height: `calc(100% - ${bannerHeight}px)`
        } as SxProps
    }

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <XJSpinnerProvider>
                    <BigAlertProvider>
                        <Box sx={styles.root}>

                            {!hideVersionBanner && <Box ref={versionBannerRef} sx={styles.versionBanner}>
                                <Typography variant='h2'>
                                    Release: Alpha (0.1) - TESTING VERSION - NO COMMERCIAL USE
                                </Typography>
                                <XJIconButton variant='secondary' onClick={onVersionBannerClose}>
                                    <Close48Icon />
                                </XJIconButton>
                            </Box>}
                            {!hideVersionBanner && <Box sx={styles.versionBannerPlaceholder} />}

                            <Box sx={styles.body}>
                                <CssBaseline />
                                <InjectAxiosInterceptors />
                                <Switch>


                                    <Route path='/error'>
                                        <ErrorPage/>
                                    </Route>
                                    <Route path='/terms'>
                                        <Terms />
                                    </Route>

                                    <Route path='/privacy'>
                                        <Privacy />
                                    </Route>

                                    <Route path='/login/oauth/google'>
                                        <Oauth provider={'google'} />
                                    </Route>

                                    <Route path='/login/oauth/linkedin'>
                                        <Oauth provider={'linkedin'} />
                                    </Route>

                                    <Route path='/login/oauth/github'>
                                        <Oauth provider={'github'} />
                                    </Route>

                                    <Route path='/login/oauth/microsoft'>
                                        <Oauth provider={'microsoft'} />
                                    </Route>

                                    <Route path='/login/oauth/apple'>
                                        <Oauth provider={'apple'} />
                                    </Route>

                                    <Route path='/login/verifyemail/:email/:challenge' exact>
                                        <VerifyEmail />
                                    </Route>

                                    <Route path='/login/changePassword/:email/:challenge'>
                                        <EmailLoginChangePassword />
                                    </Route>

                                    <Route path='/login' >
                                        <Login />
                                    </Route>

                                    <Route path='/signup'>
                                        <Login />
                                    </Route>

                                    <Route exact path='/'>
                                        <Home />
                                    </Route>

                                    <Route path='/watch/'>
                                        <InvestorPitchView />
                                    </Route>

                                    <Layout bannerHeight={bannerHeight}>
                                        <Route path={'/support'}>
                                            <Contact />
                                        </Route>

                                        <AdminRoute path='/admin/questions' >
                                            <SystemQuestions />
                                        </AdminRoute>

                                         <AdminRoute path='/admin/tutorials' >
                                            <TutorialsEditor />
                                        </AdminRoute>


                                        <AdminRoute path='/admin/topics' >
                                            <SystemTopics />
                                        </AdminRoute>

                                        <AdminRoute exact={true} path='/admin/templates' >
                                            <TemplateEditor />
                                        </AdminRoute>

                                        <AdminRoute exact={true} path='/admin/manualreview/:pid' >
                                            <RequiresData pitch> <ScriptReview /></RequiresData>
                                        </AdminRoute>

                                        <AdminRoute exact={true} path='/admin/manualreview' >
                                            {/* <RequiresData pitch> */}
                                                <ScriptsPendingReview />
                                            {/* </RequiresData> */}
                                        </AdminRoute>

                                        <PrivateRoute path='/pitch/:pid/segmentedit/question/:qid/clarify'>
                                            <RequiresData pitch> <SegmentQuestionsEdit clarify={true} /></RequiresData>
                                        </PrivateRoute>

                                        <PrivateRoute exact={true} path='/pitch/:pid/segmentedit/question/:qid'>
                                            <RequiresData pitch> <SegmentQuestionsEdit /></RequiresData>
                                        </PrivateRoute>

                                        <PrivateRoute path='/pitch/:pid/segmentedit/topic/:tid'>
                                            <RequiresData pitch> <SegmentEditTopic /></RequiresData>
                                        </PrivateRoute>

                                        <PrivateRoute exact path='/pitch/:pid/question/:qid'>
                                            <RequiresData pitch> <InterviewQuestion /></RequiresData>
                                        </PrivateRoute>

                                        <PrivateRoute exact path='/pitch/:pid/topic/:tid'>
                                            <RequiresData pitch>  <InterviewTopic /></RequiresData>
                                        </PrivateRoute>

                                        <PrivateRoute path='/pitch/:pid/segment/:sid/edit'>
                                            <RequiresData pitch>
                                                <SegmentEditorPage />
                                            </RequiresData>
                                        </PrivateRoute>

                                        <PrivateRoute path='/pitch/:pid/segment/:sid/video/record/setup/intro'>
                                            <RequiresData pitch> <VideoSetupIntro /></RequiresData>
                                        </PrivateRoute>

                                        <PrivateRoute path='/pitch/:pid/segment/:sid/video/record/setup' exact>
                                            <RequiresData pitch><VideoSetup /></RequiresData>
                                        </PrivateRoute>

                                        <PrivateRoute path='/pitch/:pid/segment/:sid/video/record/' exact>
                                            <RequiresData pitch>
                                                <VideoRecord />
                                            </RequiresData>
                                        </PrivateRoute>

                                        <PrivateRoute path='/pitch/:pid/segment/:sid/video/upload/' exact>
                                            <RequiresData pitch>
                                                <UploadVideo />
                                            </RequiresData>
                                        </PrivateRoute>

                                        <PrivateRoute path='/pitch/:pid/segment/:sid/video/:vid/editor'>
                                            <RequiresData pitch>
                                                <VideoEditingPage />
                                            </RequiresData>
                                        </PrivateRoute>

                                        <PrivateRoute path='/pitch/:pid/links'>
                                            <RequiresData pitch>
                                                <SharedLinks />
                                            </RequiresData>
                                        </PrivateRoute>

                                        <PrivateRoute path='/pitch/:pid/edit'>
                                            <RequiresData pitch>
                                                <PitchEditor />
                                            </RequiresData>
                                        </PrivateRoute>

                                        <PrivateRoute path='/pitch/:pid/golive'>
                                            <PitchGoLive />
                                        </PrivateRoute>

                                        <PrivateRoute exact path='/profile'>
                                            <ProfilePage />
                                        </PrivateRoute>

                                        <PrivateRoute exact path='/pitch/:pid/profilegolive'>
                                            <ProfileGoLive />
                                        </PrivateRoute>

                                        <PrivateRoute exact path='/pitch'>
                                            <NewPitch />
                                        </PrivateRoute>

                                        <PrivateRoute exact path='/dashboard'>
                                            <Dashboard />
                                        </PrivateRoute>

                                        {/* <Route exact path='/testpage'>
                                            <TestUIPage />
                                        </Route> */}

                                    </Layout>

                                </Switch>
                            </Box>
                        </Box>
                    </BigAlertProvider>
                </XJSpinnerProvider>
            </ThemeProvider>
        </StyledEngineProvider>
    )
}