import React, { useState, ChangeEvent, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useAuth } from 'hooks/UseAuth'
import { LinkVariant, XJLink } from '../../UI Elements/XJLink'
import { XJButton } from '../../UI Elements/Buttons/XJButton'
import { theme } from '../../XJumpTheme'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'

import { Box, Typography, TextField, InputAdornment, IconButton } from '@mui/material'
import { WindowsIcon } from 'UI Elements/XJIcons'
import { GoogleIcon } from 'UI Elements/XJIcons'
import GitHubIcon from '@mui/icons-material/GitHub'
import { useAlert } from 'hooks/UseAlert'
import XJIconButton from '../../UI Elements/Buttons/XJIconButton'
import LoginLayout, { LoginLayoutClasses } from './LoginLayout'
import { RoutingController } from 'Controllers/RoutingController'
import { useSpinner } from 'UI Elements/XJSpinner'
import AppleIcon from '@mui/icons-material/Apple'
import {  XJPitchuraLogo } from 'UI Elements/XJPitchuraLogo'
import { XJLinkButton } from 'UI Elements/Buttons/XJLinkButton'

const classes = LoginLayoutClasses

interface LocationState {
    from: {
        pathname: string
    }
}

export default function Login() {

    const history = useHistory()
    const user = useAuth()
    const location = useLocation<LocationState>()
    const params = new URLSearchParams(location.search)
    const returnURL = params.get('return')
    const logout = params.get('logout')
    const [mode, setMode] = useState('redirect')

    useEffect(() => {

        if (logout === '1') {
            user.Logout(() => { return })
        } else {
            if (user.user.Id) {
                history.replace(returnURL || RoutingController.Home())
            }
            else {
                if (location.pathname.includes('signup')) setMode('signup'); else setMode('login')

            }
        }

    })

    // TODO: cancel network requequest on modal close if the user agrees 
    const onCancelLoading = () => {
        alert("Are you sure you want to cancel?")
    }


    const showAlert = useAlert()

    const inputBoxWidth = '250px !important';

    const [signupError, setSignupError] = useState('')
   
    const [form, setForm] = useState({
        loginEmail: '',
        loginPassword: '',
        signupEmail: '',
        signupPassword: '',
        signupPasswordConfirm: '',
        signupEmailConfirmationCode: ''
    })
    const [showPassword, setShowPassword] = React.useState(false);

    const spinner = useSpinner()

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>): void => {

        const value = event.currentTarget.value
        const name = event.currentTarget.name

        setForm({
            ...form, [name]: value
        })
    }

    const validateSignup = () => {
        let result = true

        if (form.signupEmail.isEmpty()) {
            result = false
            showAlert.error('Email is empty')
        } else if (!form.signupEmail.isEmail()) {
            result = false
            showAlert.error('Email is not valid')
        } else if (form.signupPassword.isEmpty()) {
            result = false
            showAlert.error('Password is empty')
        } else if (form.signupPasswordConfirm.isEmpty()) {
            result = false
            showAlert.error('Confirm password is empty')
        } else if (form.signupPassword !== form.signupPasswordConfirm) {
            result = false
            showAlert.error('Password and its confirmation do not match')
        }

        // TODO: add password strenght validation 

        return result
    }

    const SignupSubmit = () => {

        if (!validateSignup()) return

        spinner(true)
        user.EmailSignup(form.signupEmail, form.signupPassword, () => {

            history.replace(RoutingController.Home())
        }, (error: string) => {
            spinner(false)
            showAlert.error(error)
        })
    }

    (globalThis as any).onGoogleLogin = async (response: any) => {

        if (response.code) {
            console.debug(response.code)
        }

    }

    const validateLogin = () => {
        let result = true

        if (form.loginEmail.isEmpty()) {
            result = false
            showAlert.error('Email is empty')
        } else if (!form.loginEmail.isEmail()) {
            result = false
            showAlert.error('Email is not valid')
        } else if (form.loginPassword.isEmpty()) {
            result = false
            showAlert.error('Password is empty')
        }

        return result
    }

    const LoginSubmit = () => {

        if (!validateLogin()) return

        spinner(true)
        user.EmailLogin(form.loginEmail, form.loginPassword, () => {

            history.replace(returnURL || RoutingController.Home())
        }, (error: string) => {
            spinner(false)
            showAlert.error(error)
        })

    }

    const onChangePassword = () => {

        const email = form.loginEmail

        if (email.trim().length === 0) {
            showAlert.error("Please type an email address.")
            return
        }

        user.EmailRequestPasswordChange(email, () => {
            showAlert.success(`Change password email was sent to ${email} (if it's a valid Pitchura user email)`)
        }, (err: string) => {
            showAlert.error("An error occured: " + err)
        })

    }

    const getSocialLoginStateObjectURL = (): string => {

        const host = window.location.host
        const queryString = `?returnTo=${returnURL}&host=${host}`

        return queryString

    }

    const socialLogins = () => {
        return <Box
            sx={{ ml: 'auto', mr: 'auto' }}
            display='flex' gap={8}
            justifyContent='space-around'
            alignItems='center' >

            <XJIconButton
                variant={'tertiary'}

                onClick={() => {
                    spinner(true)
                    window.location.replace(`${process.env.REACT_APP_APIUrl}/auth/google/login` + getSocialLoginStateObjectURL())
                }} >

                <GoogleIcon />

            </XJIconButton>

            <XJIconButton
                variant={'tertiary'}

                onClick={() => {
                    spinner(true)
                    window.location.replace(`${process.env.REACT_APP_APIUrl}/auth/apple/login` + getSocialLoginStateObjectURL())
                }} >

                <AppleIcon />

            </XJIconButton>


            <XJIconButton
                variant={'tertiary'}
                onClick={() => {

                    window.location.replace(`${process.env.REACT_APP_APIUrl}/auth/microsoft/login` + getSocialLoginStateObjectURL())
                }} >
                <WindowsIcon />

            </XJIconButton>

            <XJIconButton
                variant={'tertiary'}
                onClick={() => { window.location.replace(`${process.env.REACT_APP_APIUrl}/auth/github/login` + getSocialLoginStateObjectURL()) }}> <GitHubIcon />
            </XJIconButton>

            {/* <XJIconButton
                variant={'tertiary'}
                onClick={() => { window.location.replace(`${process.env.REACT_APP_APIUrl}/auth/linkedin/login` + getSocialLoginStateObjectURL()) }}>
                <LinkedInIcon />
            </XJIconButton> */}
        </Box>
    }


    const LoginForm = () => {

        const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
            event.preventDefault()
        }

        const handleClickShowPassword = () => {
            setShowPassword(!showPassword);
        }

        return <>
            <Box sx={classes.formHeader}>
                <Box display={'flex'} gap={8} flexDirection={'column'}>
                    <Box display={'flex'} gap={8} alignItems={'center'}>
                        <XJPitchuraLogo variant='PNG+Type' />
                    </Box>

                    {/* <Box width={'100% !important'}>
                        <Typography variant='h2'>
                            Login
                        </Typography>
                    </Box> */}
                </Box>

                {socialLogins()}

                <Box mx='auto' mb={16} sx={classes.formCopy}>
                    or use your email:
                </Box>

            </Box>

            <Box sx={{ ...classes.FormBoxes, ...classes.form }}>

                <Box ml={0} mb={theme.spacing(8)} >
                    <Typography variant='h3'>email</Typography>
                </Box>

                <Box width={inputBoxWidth} mb={theme.spacing(16)} >
                    <TextField
                        color='secondary'
                        variant="outlined"
                        value={form.loginEmail}
                        onChange={handleInputChange}
                        name='loginEmail'
                        autoFocus={true}
                    />
                </Box>

                <Box mb={8}>
                    <Typography variant='h3'>password</Typography>
                </Box>

                <Box width={inputBoxWidth}>

                    <TextField
                        color='secondary'
                        variant="outlined"
                        type={showPassword ? "text" : "password"}
                        value={form.loginPassword}
                        onChange={handleInputChange}
                        name='loginPassword'
                        InputProps={{
                            endAdornment:
                                <InputAdornment position="end" >
                                    <IconButton size='small' color='secondary' disableRipple={true} edge="end"
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                    >
                                        {!showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                    </IconButton>
                                </InputAdornment>
                        }}
                    />
                </Box>

                <Box>
                    <XJLinkButton variant={LinkVariant.secondary} to={'?return=' + returnURL} onClick={onChangePassword} >Change password</XJLinkButton>
                </Box>

                <Box mx='auto' mt={32}>
                    <XJButton variant={'secondary'} onClick={LoginSubmit}>Login</XJButton>
                </Box>

                <Box width={'100% !important'} mt={16}>
                    <Box mx='auto' mt={16} display='flex' justifyContent='space-around' alignItems='center'>

                        <Box mr={2} sx={classes.formCopy} >New here?</Box>

                        <XJLinkButton variant={LinkVariant.primary} to={'/signup'}>Sign up for free</XJLinkButton>

                    </Box>
                </Box>
            </Box>
        </>
    }

    const SignupFormCreateAccount = () => {
        return <>
            <Box sx={classes.formHeader}>
                <Box sx={classes.title}>
                    <Box display={'flex'} gap={8} mb={8} alignItems={'center'}>
                        <XJPitchuraLogo variant='PNG+Type' />
                    </Box>

                    <Box display={'flex'} justifyContent={'center'} width={'100% !important'}>
                        <Typography variant='h2'>
                            Free Signup
                        </Typography>
                    </Box>

                    <Box sx={{ mt: 8, ...classes.formCopy }} display='flex' justifyContent='space-around' alignItems='center'>
                        <Box mr={theme.spacing(2)}> Already have an account? </Box>

                        <XJLinkButton variant={LinkVariant.primary} to={'/login'}>Login</XJLinkButton>
                    </Box>

                </Box>
                {socialLogins()}
                <Box sx={{ mx: 'auto', mb: 24, ...classes.formCopy }}>
                    or use email:
                </Box>

            </Box>

            <Box sx={{ ...classes.FormBoxes, ...classes.form }}>
                <Box sx={{ mb: 16 }}>
                    <Box sx={{ ml: 0, mb: 8 }}  >
                        <Typography variant='h3'>email</Typography>
                    </Box>

                    <Box width={inputBoxWidth}  >
                        <TextField
                            color='secondary'
                            variant="outlined"
                            value={form.signupEmail}
                            onChange={handleInputChange}
                            name='signupEmail'
                            autoFocus={true}
                            error={signupError !== ''}
                            helperText={signupError}
                        />
                    </Box>
                </Box>

                <Box display='flex' flexDirection={{ xs: 'column', md: 'row' }} gap={16} >
                    <Box width={inputBoxWidth} mr={theme.spacing(4)} alignItems='flex-start' display='flex' flexDirection='column'>
                        <Box ml={0} mb={8} >
                            <Typography variant='h3'>New Password</Typography>
                        </Box>

                        <TextField
                            color='secondary'
                            variant="outlined"
                            type="password"
                            value={form.signupPassword}
                            onChange={handleInputChange}
                            name='signupPassword'
                        />
                    </Box>

                    <Box width={inputBoxWidth} alignItems='flex-start' display='flex' flexDirection='column' >
                        <Box ml={0} mb={8} >
                            <Typography variant='h3'>Confirm Password</Typography>
                        </Box>

                        <TextField
                            color='secondary'
                            variant="outlined"
                            type="password"
                            value={form.signupPasswordConfirm}
                            onChange={handleInputChange}
                            name='signupPasswordConfirm'
                        />
                    </Box>
                </Box>
            </Box>

            <Box sx={{ mx: 'auto', mt: 24 }}>
                <XJButton variant={'secondary'} onClick={SignupSubmit}>Sign up</XJButton>
            </Box>

            <Box sx={{ mx: 'auto', mt: 16, ...classes.formCopy }} >
                By clicking Sign Up I agree to the <XJLink newWindow variant={LinkVariant.primary} to={'/terms'}>terms and conditions</XJLink>
            </Box>
        </>
    }

    return <>
        {mode !== 'redirect' && <LoginLayout>
            {mode === 'login' && LoginForm()}
            {mode === 'signup' && SignupFormCreateAccount()}
        </LoginLayout>}
    </>

}