import { Box, MenuItem, Select, SelectChangeEvent, SxProps, Typography } from "@mui/material"
import { theme, copySecondaryBold, copySecondaryRegular, darkPrimaryDefault, secondaryDefault, secondaryLight60, whiteOverlay32 } from "XJumpTheme"
import { IconListCheckmark } from "./XJIcons"
import { useCallback, useRef } from "react"

interface XJSelectProps {
    sx?: SxProps
    fullwidth?: boolean 

    value: string
    options: string[]
    titles?: string[] 
    placeholder?: string

    allowNoSelection?: boolean

    onChange: (e: SelectChangeEvent) => void
}

export function XJSelect(props: XJSelectProps) {

    let titles: string[] = props.titles ?? []
    if (!props.titles || (props.titles && props.titles.isEmpty())) {
        titles = props.options
    }

    // make sure titles has the same amount of elements as options 
    titles = titles.slice(0, props.options.length)

    const titleForValue = (value: string) => {
        let result = ""
        const index = props.options.indexOf(value)
        if (index >= 0 ) {
            result = titles[index]
        } 

        return result
    }

    // prevents rerendering of inner custom component 
    const renderItem = useCallback((title: string, isSelected: boolean) => {
        return <DropdownItem title={title} selected={isSelected} />
    }, [])
    
    const DropdownItem = (props: {title: string, selected?: boolean, isValue?: boolean, placeholder?: string}) => {

        const isEmpty = props.title.isEmpty()
        const placeholder = props.placeholder ?? 'Select option'

        const styles = {
            root: {
                width: props.isValue ? 'auto' : '100%',     // prevents overflowing in selected element 
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                minHeight: '40px',
                my: 2, 
                mx: props.isValue ? 0 : 6,
                px: 8,
                borderRadius: 1,
                ':hover': {
                    backgroundColor: props.isValue ? 0 : whiteOverlay32
                }
            } as SxProps,
            placeholder: {
                ...copySecondaryRegular,
                color: darkPrimaryDefault
            } as SxProps,
            overflow: {
                overflow: 'hidden', 
                WebkitBoxOrient: 'vertical', 
                WebkitLineClamp: 1, 
                wordBreak: 'break-all',
                textOverflow: 'ellipsis'
            } as SxProps
        }

        return <Box sx={{...styles.root, ...styles.overflow} as SxProps}>
            {isEmpty ? <Typography sx={styles.placeholder}>{placeholder}</Typography>
            :
            <Typography sx={props.selected || props.isValue ? {...copySecondaryBold, ...styles.overflow} : {...copySecondaryRegular, ...styles.overflow}}>{props.title}</Typography>}
            {props.selected ? <Box>
                <IconListCheckmark />
            </Box> : null}
        </Box>
    }

    const selectRef = useRef<HTMLDivElement>()

    const styles = {
        root: {
            ...props.sx,
            borderRadius: '2px', 
            // focused state override
            '&.MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                    border: '1px solid ' + secondaryDefault,
                },
            },
            // internal element padding, height and focus
            '& .MuiOutlinedInput-input': {
                '&.MuiSelect-select': {
                    padding: 0,
                    minHeight: '48px'
                },
                ':focus-visible': {
                    outline: '2px solid ' + secondaryLight60,
                    borderRadius: '2px' 
                },
            },
            // icon color 
            '& .MuiSelect-icon': {
                color: secondaryDefault,
                right: '12px'
            },
            // custom paddings 
            '& .MuiList-root': {
                px: 6,
                py: 4
            }
        } as SxProps,
        dropdownList: {
            mt: 1,
            boxShadow: theme.dropshadow.dropdown.default,
            maxWidth: selectRef.current ? selectRef.current.clientWidth : '100%'
        } as SxProps,
        option: {
            '&.Mui-selected': {
                backgroundColor: 'inherit',
                '&.Mui-focusVisible': {
                    backgroundColor: 'inherit'
                }, 
            },
            ':hover': {
                backgroundColor: 'inherit'
            },
            ':focus': {
                backgroundColor: 'inherit'
            },
            '&.Mui-selected:hover': {
                backgroundColor: 'inherit'
            },
            '&.MuiMenu-paper': {
                px: 6,
                py: 4
            },
            backgroundColor: 'inherit',
            p: 0
        } as SxProps
    }

    return (
        <Select 
            ref={selectRef}
            sx={styles.root}
            fullWidth={props.fullwidth}

            value={props.options.contains(props.value) ? props.value : ''}
            onChange={props.onChange}

            displayEmpty
            renderValue={(value) => <DropdownItem isValue title={titleForValue(value)} placeholder={props.placeholder} />}

            MenuProps={{PaperProps:    
                {sx: styles.dropdownList},
                container: document.fullscreenElement
            }}
        >
            {props.allowNoSelection ? <MenuItem value='null' key={'nullkey'}>(none)</MenuItem> : null}

            {props.options.map(
                (option, i) => {
                    return <MenuItem value={option} key={`itemkey${i}`} sx={styles.option}>
                        {renderItem(titles[i], option === props.value)}
                    </MenuItem>
                })
            }
        </Select>
    )
}