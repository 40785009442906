import { useCallback, useEffect, useRef, useState } from 'react';
import { Box, Grid, SxProps, Typography, useMediaQuery } from '@mui/material';
import { PublicPitchDAO } from 'Common/PublicPitchDAO'
import { copySecondaryRegular, darkPrimaryDefault, secondaryDefault, theme, whiteDefault } from 'XJumpTheme';
import { ArrowLeftIcon, ArrowRightIcon } from 'UI Elements/XJIcons';
import { IPublicPitch, IVideoSegment, PitchPlayer, PitchPlayerAPI } from 'Pages/Video/Player/PitchPlayer';
import { ArrayElement } from 'tools/Types';
import { IVisualAid } from 'Pages/Video/Player/VideoPlayer';
import { useKeyPress } from 'hooks/UseKeyPress';
import { XJUserLogo } from 'UI Elements/XJUserLogo';
import { SegmentModel } from 'Model/SegmentModel';
import XJIconButton from 'UI Elements/Buttons/XJIconButton';
import { LinkedInProfileURL } from 'Pages/Founder/Profile/FounderProfile';
import parsePhoneNumber from 'libphonenumber-js'
import { LinkedInButton } from 'Components/XJLinkedInButton';


export interface IInvestorViewProps {

    pitch: PublicPitchDAO
    ActiveSegmentSequence?: number | null
    analyticsPitchLoaded?: (pitchId: number) => void;
    analyticsPitchStartedPlaying?: (pitchId: number) => void;
    analyticsPitchProgress?: (pitchId: number, secondsPlayed: number, percentPlayed: number) => void;

}

type PublicSegmentDAO = ArrayElement<PublicPitchDAO["segments"]>
type PublicAidDAO = ArrayElement<PublicSegmentDAO["visualAids"]>

export function PitchView(props: IInvestorViewProps) {

    // MARK: - State 

    const pitch = props.pitch
    const [activeSegment, setActiveSegment] = useState(-1)  // sequence 

    const playerRef = useRef<PitchPlayerAPI>()
    const player = useCallback(() => {
        return playerRef.current
    }, [])

    useEffect(() => {
        if (props.ActiveSegmentSequence) {
            setActiveSegment(props.ActiveSegmentSequence)
        }
    }, [props.ActiveSegmentSequence])


    // MARK: - Private methods 

    const getActiveSegment = () => {
        return pitch.segments.find(x => x.seq === activeSegment)
    }

    const onSegmentChanged = useCallback((currentSegmentSequence: number, prevSegmentId?: number | null) => {
        setActiveSegment(currentSegmentSequence)
    }, [])

    const onPlayerReady = () => {
        player()?.preload()
    }

    const onSegmentListItemClicked = (clickedSegmentSequence: number) => {
        player()?.seekToSegment(clickedSegmentSequence)
    }

    const nextSegment = useCallback(() => {

        setActiveSegment((value) => {

            const ActiveSegmnetIndex = pitch.segments.findIndex(x => x.seq === value)

            if (ActiveSegmnetIndex + 1 === pitch.segments.length) {
                return value
            }

            const newSequence = pitch.segments[ActiveSegmnetIndex + 1].seq
            player()?.seekToSegment(newSequence)
            return newSequence

        })


    }, [pitch, player, activeSegment])

    const prevSegment = useCallback(() => {

        const ActiveSegmnetIndex = pitch.segments.findIndex(x => x.seq === activeSegment)

        if (ActiveSegmnetIndex > 0) {

            setActiveSegment((value) => {

                const prevSequence = pitch.segments[ActiveSegmnetIndex - 1].seq
                player()?.seekToSegment(prevSequence)
                return prevSequence
            })
        }

    }, [player, activeSegment])


    // MARK: - Keyboard

    const kSpaceKey = " "
    const kArrowUpKey = "ArrowUp"
    const kArrowDownKey = "ArrowDown"
    const kArrowLeftKey = "ArrowLeft"
    const kArrowRightKey = "ArrowRight"

    const handleArrowUpDown = useCallback((up: boolean) => {
        up ? prevSegment() : nextSegment()
    }, [prevSegment, nextSegment])

    const onKeyDown = useCallback((key: string) => {
        switch (key) {
            case kSpaceKey:
                player()?.state().playing ? player()?.pause() : player()?.play()
                break

            case kArrowUpKey:
                handleArrowUpDown(true)
                break

            case kArrowDownKey:
                handleArrowUpDown(false)
                break

            case kArrowLeftKey:
                player()?.rewind(5)
                break

            case kArrowRightKey:
                player()?.forward(5)
                break

            default:
                break
        }
    }, [player, handleArrowUpDown])

    useKeyPress({ char: kSpaceKey }, onKeyDown)
    useKeyPress({ char: kArrowUpKey }, onKeyDown)
    useKeyPress({ char: kArrowDownKey }, onKeyDown)
    useKeyPress({ char: kArrowLeftKey }, onKeyDown)
    useKeyPress({ char: kArrowRightKey }, onKeyDown)


    const FoudnerPhoneNumber = parsePhoneNumber('+' + pitch.founder.mobile)

    // MARK: - Render 

    const isXS = !useMediaQuery(theme.breakpoints.not('xs'))
    const isMedium = useMediaQuery(theme.breakpoints.up('md'))
    const isLarge = useMediaQuery(theme.breakpoints.up('lg'))
    const isLandscape = useMediaQuery('(orientation: landscape)')
    const isMobileLandscape = isLandscape && !isMedium      // uses to turn on player fullscreen and 
    // turn off other elements on the page on fullscreen
    const showWide = ((isMedium && isLandscape) || isLarge || isMedium)
    const showAbout = !showWide && !isXS

    const renderPitchPlayer = (pitch: PublicPitchDAO) => {

        const _mapVisualAid = (aid: PublicAidDAO) => {
            return {
                duration: aid.duration,
                PIPPosition: aid.PIPPosition,
                showPIP: aid.showPIP,
                startAt: aid.startAt,
                type: aid.type,
                URL: aid.URL
            } as IVisualAid
        }

        const _mapSegmentDAO = (segment: PublicSegmentDAO) => {
            return {
                seq: segment.seq,
                title: segment.title,
                duration: segment.duration,
                url: segment.videoPlayListURL,
                visualAids: segment.visualAids.map((aid) => _mapVisualAid(aid))
            } as IVideoSegment
        }

        const pitchData = {
            id: pitch.id,
            segments: pitch.segments.map((segment) => _mapSegmentDAO(segment))
        } as IPublicPitch

        return <PitchPlayer
            ref={(ref) => { playerRef.current = ref! }}
            pitch={pitchData}

            hideControls


            hideTimeline
            showOnScreenControls
            fullscreen={isMobileLandscape}

            onReady={onPlayerReady}
            onSegmentChange={onSegmentChanged}
        />
    }


    const orderedSegments = pitch.segments.sort((s1, s2) => {return s1.seq - s2.seq})
    const index = orderedSegments.findIndex(s => s.seq === activeSegment)
    const isFirstSegment = index === 0 || index === -1
    const isLastSegment = index === orderedSegments.length - 1

    const Segments = () => {

        const renderSegmentItem = (segment: PublicSegmentDAO) => {

            const styles = {
                root: {
                    display: 'flex',
                    py: 8,
                    px: 12,
                    backgroundColor: theme.palette.white.default,
                    color: theme.palette.darkPrimary.default,
                    justifyContent: 'space-between',
                    marginTop: 8,
                    cursor: 'pointer',
                    ...theme.typography.secondary.regular
                } as SxProps,
                active: (isActive: boolean) => {
                    return isActive ?
                        {
                            ...theme.typography.secondary.bold,
                            color: theme.palette.secondary.default
                        }
                        :
                        {}
                }
            }

            const minsSecs = SegmentModel.videoDurationInMinsSecs(segment.duration)


            return (
                <Box sx={styles.root} key={`segment${segment.seq}`} onClick={() => onSegmentListItemClicked(segment.seq)}>
                    <Box sx={styles.active(activeSegment === segment.seq)}>
                        {segment.seq + 1}. {segment.title}
                    </Box>

                    <Box>
                        {`${minsSecs.mins}:${String(minsSecs.secs).padStart(2, '0')}`}
                    </Box>
                </Box>
            )
        }

        const styles = {
            root: {
                display: 'flex',
                flexDirection: 'column',
                [theme.breakpoints.only('sm')]:
                    { marginTop: 8 },

            } as SxProps,
            content: {
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
            },
            buttons: {
                display: 'flex',
                gap: 8
            },
            button: {
                backgroundColor: theme.palette.lightPrimary.overlay24
            }
        }

        return <>
            <Box sx={styles.root}>
                <Box sx={styles.content}>
                    <Typography variant='h2'>Segments</Typography>

                    <Box sx={styles.buttons}>
                        <XJIconButton variant={'secondary'} disabled={isFirstSegment} onClick={prevSegment}>
                            <ArrowLeftIcon />
                        </XJIconButton>

                        <XJIconButton variant={'secondary'} disabled={isLastSegment} onClick={nextSegment}>
                            <ArrowRightIcon />
                        </XJIconButton>
                    </Box>
                </Box>
                {pitch.segments.map((x) => renderSegmentItem(x))}
            </Box>
        </>
    }

    const MiniSegments = () => {

        const segment = getActiveSegment()

        const styles = {
            root: {
                mt: 8,
                display: 'flex',
                alignItems: 'stretch',
                gap: 4
            },
            menu: {
                backgroundColor: theme.palette.lightPrimary.overlay24
            },
            titleBox: {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexGrow: 1,
                color: whiteDefault,
                backgroundColor: secondaryDefault
            },
            menuButton: {
                backgroundColor: theme.palette.lightPrimary.overlay24
            }
        }

        return <>
            {segment && <Box sx={styles.root}>
                <XJIconButton variant={'secondary'} disabled={isFirstSegment} onClick={prevSegment}>
                    <ArrowLeftIcon />
                </XJIconButton>

                <Box sx={styles.titleBox}>
                    <Typography variant='h2'>{`${segment.seq + 1}. ${segment.title}`} </Typography>
                </Box>

                <XJIconButton variant={'secondary'} disabled={isLastSegment} onClick={nextSegment}>
                    <ArrowRightIcon />
                </XJIconButton>
            </Box>
            }
        </>
    }

    const profileItem = (title: string, value: string | JSX.Element | undefined, reversed?: boolean) => {

        return (<Box display={'flex'} flexDirection={reversed ? 'column-reverse' : 'column'} gap={4} mr={0} >
            <Box sx={{ ...copySecondaryRegular, color: darkPrimaryDefault }} >
                {title}
            </Box>

            <Typography variant='h3' whiteSpace={'none'}>
                {value}
            </Typography>
        </Box>
        )
    }

    const Profile = () => {

        return <>

            <Box px={12} py={8} sx={{ backgroundColor: whiteDefault, borderRadius: '2px' }} mt={8} display={'flex'} flexDirection={'column'} gap={16}>

                <Box display={'flex'} gap={16} flexWrap={'wrap'} >
                    {profileItem(pitch.founder.title, pitch.founder.name, true)}
                    {profileItem("Mobile", FoudnerPhoneNumber?.country === 'US' ? FoudnerPhoneNumber?.formatNational() : FoudnerPhoneNumber?.formatInternational(), true)}
                    {profileItem("Email", pitch.founder.email, true)}

                    <Box sx={{ cursor: 'pointer' }} mt={0} height={'100%'}  >
                        <LinkedInButton profileURL={LinkedInProfileURL + pitch.founder.linkedInURL} />
                    </Box>
                </Box>

                <Box display={'flex'} gap={16} flexWrap={'wrap'} >
                    {/*  <Box sx={{ ...styles.logoLine, mb: 0 }} >
                        <Box sx={styles.logo}  >
                            <XJUserLogo src={pitch?.company.logo} />
                            <Box display={'flex'} flexDirection={'column'} gap={4} >
                                <Typography variant='h3'> {pitch?.company.name} </Typography>
                                <Box sx={{ ...copySecondaryRegular, whiteSpace: 'nowrap', color: darkDarkPrimaryDefault }}> {CompanyLocation} </Box>

                            </Box>

                        </Box>
                    </Box> */}

                    <Box display={'flex'} flexWrap={'wrap'} gap={16}>

                        {profileItem("Round", pitch.round)}
                        {profileItem("Stage", pitch.company.stage)}
                        {profileItem("Industry", pitch.company.industry + (pitch.company.subIndustry ? `, ${pitch.company.subIndustry}` : ''))}
                        {profileItem("Tag", pitch.tag)}

                    </Box>
                </Box>
            </Box>
        </>
    }

    const styles = {
        logoLine: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginBottom: 8,
            gap: 8
        } as SxProps,
        logo: {
            display: 'flex',
            alignItems: 'center',
            gap: 8
        } as SxProps,
        playerMobile: {
            position: 'relative',
            width: '100vw',
            left: `calc((100vw - 100%) / 2 * -1)`,
            right: `calc((100vw - 100%) / 2 * -1)`
        } as SxProps,
        playerFullscreen: {
            width: '100%',
            height: '100vh',
            position: "absolute",
            top: 0,
            left: 0,
            overflow: "hidden"
        } as SxProps,
        profileWide: {
            width: "100%"
        },
        desktopMiniSegments: {
            display: { sm: 'none' }
        },
        desktopProfile: {
            display: { xs: 'block', md: 'none' }
        },
        desktopSegments: {
            display: { xs: 'none', sm: 'block' }
        }
    }

    const CompanyLocation = `${pitch.company.city} ${pitch.company.state ? ', ' + pitch.company.state : ''} ${pitch.company.country.toLowerCase().startsWith('united states') ? '' : ',' + pitch.company.country}`

    const subTitlesGray: SxProps = {
        ...copySecondaryRegular,
        color: darkPrimaryDefault

    }

    return (<>
        {!isMobileLandscape &&
            <Grid xs={12} item>
                <Box sx={styles.logoLine}>
                    <Box sx={styles.logo}>
                        <XJUserLogo src={pitch?.company.logo} />
                        <Typography variant='h1'> {pitch?.company.name} </Typography>
                    </Box>
                    {/* {isXS && <Box>Powered by Pitchura</Box>} */}
                </Box>
            </Grid>}
        <Grid xs={12} item>
            <Grid container spacing={{ xs: 8, sm: 8, md: 16, lg: 26 }}>
                <Grid item xs={12} md={8}>
                    <Box sx={isXS ? styles.playerMobile : {}}>
                        <Box sx={isMobileLandscape ? styles.playerFullscreen : {}}>
                            {renderPitchPlayer(pitch)}
                        </Box>
                    </Box>

                    {showWide &&
                        <Box sx={styles.profileWide}>
                            <Profile />
                        </Box>}
                </Grid>

                {!isMobileLandscape &&
                    <Grid item xs={12} sx={styles.desktopMiniSegments}>
                        <MiniSegments />
                    </Grid>}

                {!isMobileLandscape &&
                    <Grid item xs={12} sm={6} sx={styles.desktopProfile}>
                        <Profile />
                    </Grid>}

                {!isMobileLandscape &&
                    <Grid item xs={0} sm={6} md={4} sx={styles.desktopSegments}>
                        <Segments />
                    </Grid>}
            </Grid>
        </Grid>
    </>)
}
