import { Box, Grid, SxProps, Typography } from "@mui/material"
import { useAlert } from "hooks/UseAlert"
import { CSSProperties, MouseEvent, useCallback, useEffect, useRef, useState } from "react"
import { XJButton } from "UI Elements/Buttons/XJButton"
import { Teleprompter } from "./Teleprompter"
import Countdown from "./Countdown"
import { useKeyPress } from "hooks/UseKeyPress"
import {  VideoMonitor } from "./Recorder"
import { Handle } from "tools/Types"
import { mapVideoModel, VideoPlayer } from "Pages/Video/Player/VideoPlayer"
import { VideoModel } from "Model/VideoModel"
import { Prompt, useHistory } from "react-router-dom"
import { useParams } from "react-router-dom"
import { usePitches } from "hooks/UsePitches"
import {  Header, HeaderRightButtons, lightPrimaryDefault, PageTitle, theme } from "XJumpTheme"
import XJBreadcrumbs from "UI Elements/XJBreadcrumbs"
import { isNull } from "lodash"
import { IconRestart } from "UI Elements/XJIcons"
import { VideoDimensions } from "Common/Enums"
import { RoutingController } from 'Controllers/RoutingController'
import { useXJMediaUploadContext } from "Components/XJMediaUpload"
import { useSpinner } from "UI Elements/XJSpinner"
import XJExplainer, { ExplainerStepCopy, ExplainerStepSx } from "UI Elements/PopUps/XJExplainer"
import { ExplainerID } from "hooks/UseExplainersShouldShow"
import { LogError } from "Controllers/Logging"
import { RecordButtonType, XJSpecificRecordButton } from "UI Elements/Buttons/XJRecordButton"
import { usePitchuraLocalStorage } from "hooks/UsePitchuraLocalStorage"
import MicCameraPermissionAlert from "Components/MicCameraPermissionAlert"

export const kVideoRatioWidth = VideoDimensions.width
export const kVideoRatioHeight = VideoDimensions.height
export const kVideoRecorderMinHeight = 300
export const kVideoRecorderMinWidth = kVideoRecorderMinHeight / kVideoRatioHeight * kVideoRatioWidth

const kDefaultCountdownSeed = 3

const classes = {
    TopContainer: {
        display: 'flex',
        marginX: 16,
        justifyContent: 'center'
    } as CSSProperties,

    VideoMonitorContainer: {
        position: 'absolute',
        left: 32,
        height: 'fit-content',
        boxShadow: theme.dropshadow.card.default
    } as CSSProperties,

    TeleprompterRecordingControls :{
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '44%'
    } as CSSProperties,

    DoneButton: {
        position: 'absolute',
        right: 32
    } as CSSProperties,

    fullscreenElement: {
        ':fullscreen': {
            backgroundColor: lightPrimaryDefault,
            paddingTop: 16
        }
    } as CSSProperties
}

const controlSX: SxProps = {
    position: 'relative',
    paddingY: '16px',
    paddingX: '28px',
    height: '56px',
    borderRadius: '100px'
}



export enum RecordingStep {
    init,
    rehearse,
    preRecord,
    start,
    pause,
    restart,
    complete,
    videoReady,
    VideoUploadFailed,
    VideoUploading
}

export default function VideoRecord() {

    const history = useHistory()

    const { pid } = useParams<any>()
    const { sid } = useParams<any>()

    const pitch = usePitches().getPitch(pid)
    const segment = pitch.getSegmentById(parseInt(sid))


    // MARK: - Setup

    const localStorage = usePitchuraLocalStorage()

    const [video, setVideo] = useState<VideoModel | null>(null)
    const [blob, setBlob] = useState<Blob | null>(null)
    const [hasVideo, setHasVideo] = useState(false)
    const [isRecording, setIsRecording] = useState(false)
    const [recorderKey, setRecorderKey] = useState(0)
 

    const [selectedCamera, setSelectedCamera] = useState<string>(localStorage.selectedCameraId)

    useEffect(() => {
        // happens when camera is switching 
        if (isCameraSwitching.current) {
            RedoRecording()
        }

        localStorage.updateSelectedCameraId(selectedCamera)
    }, [selectedCamera])

    const spinner = useSpinner()


    const isRestarsting = useRef(false)
    const isCameraSwitching = useRef(false)

    useEffect(() => {

        const hasVideo = !isNull(blob)// && !isNull(video)
        setHasVideo(hasVideo)

    }, [blob])

    // const alertsRef = useRef(useAlert())
    // const alerts = alertsRef.current


    // MARK: - Recording 

    const FullScreenElement = useRef<HTMLDivElement>()

    // const [StopOnFinishCheck, setStopOnFinishCheck] = useState(false)
    const [countdownSeed, setCountdownSeed] = useState<number | null>(null)
    const [inFullScreen, setInFullScreen] = useState(false)
    const [speed, setSpeed] = useState(2) // later we need to read this from the user's profile     

    const errorAlert = useAlert().error
    const teleprompterRef = useRef<Handle<typeof Teleprompter>>()
    let teleprompter = teleprompterRef.current
    const recorderRef = useRef<Handle<typeof VideoMonitor>>()
    let recorder = recorderRef.current

    const uploaderRef = useXJMediaUploadContext()

   

    const [step, setStep] = useState<RecordingStep>(RecordingStep.init)

    const [isUploading, setIsUploading] = useState(false)
    const [isSaving, setIsSaving] = useState(false)

    const RecordingExists = useRef(false)


    // MARK: - Actions 

    const onSave = async () => {

        spinner(true)
        setIsSaving(true)

        if (video) {
            segment.SetNewVideo(video)
                .then(res => {
                    history.push(RoutingController.VideoEditor(video))
                })
                .catch(err => {
                    spinner(false)
                    errorAlert("Error while saving the video")
                })
                .finally(() => {
                    spinner(false)
                    setIsSaving(false)
                })
        }
        else {
            spinner(false)
            errorAlert("Error while saving the video")
            LogError("coudn't find Video object in VideoRecord::Save")

        }
        /*  else if (blob) {
             TryUploadVideo(blob)
                 .then(res => {
                     if (video) {
                         segment.SetNewVideo(video)
                             .then(res => {
                                 history.push(RoutingController.VideoEditor(video))
                             })
                             .catch(err => {
                                 errorAlert("Error while saving the video")
                             })
                     }
                 })
         } */
    }

    const onCancel = () => {
        // drop upload 
        // TODO: cancel
        /* if (uploadEntry) {
            UploadController.cancelUpload(uploadEntry)
        } */

        uploaderRef.current?.cancelUpload()
        // go back to segment editor 
        if (segment) {
            history.push(RoutingController.SegmentEditor(segment))
        }
    }

    /*     const StopOnFinish = () => {
            return (<>
                <Box color={'white !important'} display={'flex'} flexDirection={'row'} alignItems={'baseline'}>
    
                    <Checkbox sx={{ color: 'white !important' }} checked={StopOnFinishCheck} onChange={(e) => { setStopOnFinishCheck(e.target.checked) }} />
                    <Typography>alwsy stop when something happens </Typography>
    
                </Box>
            </>)
        } */

    useEffect(() => {
        document.onfullscreenchange = (ev) => {

            if (!document.fullscreenElement) {
                setInFullScreen(false)
                if (step !== RecordingStep.init) {
                    DoneRecording()
                }
                else {
                    setCountdownSeed(null)
                }

            } else {
                setInFullScreen(true)
            }
        }
    }, [])

    const UploadingInProgress = (inProgress: boolean) => {
        setIsUploading(inProgress)
    }

    useEffect(() => {

        uploaderRef.current?.setonUploadInProgressFunction(UploadingInProgress)

    }, [])


    const Rehearse = () => {

        teleprompter?.reset()
        if (step === RecordingStep.rehearse) {
            // reset rehearse 
            setStep(RecordingStep.init)
        } else {
            setStep(RecordingStep.rehearse)
            teleprompter?.start()
        }
    }

    const StartPreRecording = () => {

        const onFullscreen = () => {
            setStep(RecordingStep.preRecord)
            StartCountdown()
            teleprompter?.reset()
        }

        if (inFullScreen) {
            onFullscreen()
        } else {
            FullScreenElement.current?.requestFullscreen()
                .then(onFullscreen)
                .catch((err) => {
                    // unable to go into fuill screen 
                    // log and error
                    // tell something to the user 
                    LogError(err)
                    errorAlert("couldn't go into full screen, don't know why")
                })
        }
    }

    const StartCountdown = () => {

        teleprompter?.reset()
        setCountdownSeed(kDefaultCountdownSeed)

        // when this is finishe, the compponent calls StartRecording ()
    }

    const StartRecording = () => {

        if (recorder) {



            isCameraSwitching.current = false
            setCountdownSeed(null)
            setStep(RecordingStep.start)
            setBlob(null)
            RecordingExists.current = false
            setVideo(null)
            teleprompter?.start()

            recorder.start()

        } else {
            LogError("VideoRecorder - RedoRecording:: didn't have a recorder object ")
        }

    }

    const [cameraIsReady, setCameraIsReady] = useState(false)

    const onCameraOn = () => {

        if (isRestarsting.current) {
            isRestarsting.current = false
            RedoRecording()
        }

        setCameraIsReady(true)
    }

    const onCameraOff = () => {
        setCameraIsReady(false)
    }

    const RestartRecording = () => {

        // flag on - to avoid going to ending of recordign 
        isRestarsting.current = true

        // reset everything 
        setStep(RecordingStep.start)
        setIsRecording(false)
        teleprompter?.reset()
      
        setCountdownSeed(null)

        // triggers re-render recorder and camera activation - which calls redoRecording 
        setRecorderKey(value => value + 1)
    }

    const RedoRecording = () => {

        if (recorder) {
            setBlob(null)
            
            RecordingExists.current = false

            setCountdownSeed(null)

            recorder?.turnOnCamera(selectedCamera.isEmpty() ? null : selectedCamera)
            StartPreRecording()

        } else {
            LogError("VideoRecorder - RedoRecording:: didn't have a recorder object ")
        }
    }

    const PauseRecording = () => {

        if (recorder) {
            //   if (recorder.duration() > MINIMUM_RECORD_DURATION) {
            setStep(RecordingStep.pause)
            recorder.pause()
            //     }
        }
        else {
            LogError("VideoRecorder - PauseRecording:: didn't have a recorder object ")
        }
    }

    const ResumeRecording = () => {

        if (recorder) {
            setStep(RecordingStep.start)
            recorder?.resume()
        }
        else {
            LogError("VideoRecorder - ResumeRecording:: didn't have a recorder object ")
        }
    }

    const DoneRecording = () => {

        if (RecordingExists.current && recorder) recorder.finish()

        teleprompter?.stop()
        setCountdownSeed(null)
        setStep(RecordingStep.complete)

        if (document.fullscreenElement !== null) document.exitFullscreen()

    }


    // MARK: - Handle keyboard 

    const KEY_SPACE = " "
    const KEY_ENTER = "Enter"

    const buttonIsFocusedRef = useRef<boolean>(false)       // used to opt out space key action when a button is selected via focus  
    const buttonIsFocused = buttonIsFocusedRef.current

    const onFocus = () => {
        buttonIsFocusedRef.current = true
    }

    const onBlur = () => {
        buttonIsFocusedRef.current = false
    }

    const onSpace = (key: string) => {
        if (!buttonIsFocused) {
            if (step === RecordingStep.pause) {
                ResumeRecording()
            } else if (step === RecordingStep.start) {
                PauseRecording()
            }
        }
    }

    const onEnter = (key: string) => {
        if (step === RecordingStep.start || step === RecordingStep.pause) {
            DoneRecording()
        }
    }

    useKeyPress({ char: KEY_ENTER }, onEnter)
    useKeyPress({ char: KEY_SPACE }, onSpace)


    // MARK: - Recorder 

    const UploadVideo = async (blob: Blob): Promise<void> => {

        const video = new VideoModel()
        video.uploaderRef = uploaderRef
        setStep(RecordingStep.VideoUploading)
        // video.segment = this
        setVideo(video)

        const duration = recorder?.duration()

        video.setMedia(blob, true, new Date(), duration)

            .then((res) => {
                setStep(RecordingStep.videoReady)
                return
            })

            .catch(err => {

                errorAlert("An error occured while uploading video")
                setStep(RecordingStep.VideoUploadFailed)
                LogError(err)
            })
    }

    const onRecorderReady = () => {
        // setCameraIsReady(true)
    }

    const onRecorderStarted = () => {

        RecordingExists.current = true
        setIsRecording(true)
        teleprompter?.start()
    }

    const onRecorderStopped = () => {
        setIsRecording(false)
        teleprompter?.stop()
    }

    const onCameraSwitch = (selectedCameraID: string) => {
        setSelectedCamera(selectedCameraID) // triggers useEffect 
        recorder?.reset()
        setIsRecording(false)
        isCameraSwitching.current = true
        setCountdownSeed(null)
    }

    const onRecordFinished = async (videoBlob: Blob) => {

        if (isRestarsting.current || isCameraSwitching.current) {
            return
        } else {

            teleprompter?.stop()

            setBlob(videoBlob)
            setIsRecording(false)

            UploadVideo(videoBlob)
        }
    }

    const onRestartClick = (event: MouseEvent<HTMLButtonElement>) => {
        RestartRecording()
        event.currentTarget.blur()
    }

    const onPauseClick = (event: MouseEvent<HTMLButtonElement>) => {
        PauseRecording()
        event.currentTarget.blur()
    }

    const onResumeClick = (event: MouseEvent<HTMLButtonElement>) => {
        ResumeRecording()
        event.currentTarget.blur()
    }

    const onDoneClick = (event: MouseEvent<HTMLButtonElement>) => {
        DoneRecording()
        event.currentTarget.blur()
    }


    const timestampChanged = useCallback ((newTimestamp: string) => {

       // setTimestamp(newTimestamp)       

    },[])
    

    const TeleprompterSpeedmarks = [
        {
            value: 1,
            label: 'Slow',
        },
        {
            value: 2,
            label: 'Normal',
        },
        {
            value: 3,
            label: 'Faster',
        },
        {
            value: 4,
            label: 'Fastest',
        }
    ]


    // MARK: - Render 

    const VideoControls = () => {
        return <>
            <Box display={'flex'} justifyContent='space-between' mt={16}>
                <Box width={'100%'} display={inFullScreen ? 'none' : 'flex'} justifyContent={'space-evenly'}>
                    <XJSpecificRecordButton type={step === RecordingStep.rehearse ? RecordButtonType.rehearseOn : RecordButtonType.rehearse} onClick={Rehearse} />
                    <XJSpecificRecordButton type={RecordButtonType.record} onClick={StartPreRecording} disabled={!cameraIsReady} />
                </Box>

                <Box display={inFullScreen ? 'flex' : 'none'} justifyContent={'space-evenly'} width={'100%'}>
                    <Box display={(step === RecordingStep.start || step === RecordingStep.pause) ? 'block' : 'none'}>
                        <XJSpecificRecordButton type={RecordButtonType.redo} onClick={onRestartClick} onFocus={onFocus} onBlur={onBlur} />
                    </Box>

                    <Box display={step === RecordingStep.start ? 'block' : 'none'}>
                        <XJSpecificRecordButton type={RecordButtonType.pause} onClick={onPauseClick} onFocus={onFocus} onBlur={onBlur} />
                    </Box>

                    <Box display={step === RecordingStep.pause ? 'block' : 'none'}>
                        <XJSpecificRecordButton type={RecordButtonType.resume} onClick={onResumeClick} onFocus={onFocus} onBlur={onBlur} disabled={!cameraIsReady} />
                    </Box>
                </Box>
            </Box>
        </>
    }



    return <Grid container>

        <Prompt
            message={'This page has unsaved video. Are you sure you want to continue? All changes will be lost'}
            when={hasVideo && !isSaving}
        />
        <MicCameraPermissionAlert />

        <XJExplainer
            id={ExplainerID.Teleprompter}
            title={'Video recording'}
            minHeight={'128px'}
            welcomeText={"You are about to start recording the segment's video. <br/> Learn how to use the teleprompter:"}
            explainerSteps={[

                <Box sx={ExplainerStepSx}>
                    <Box sx={ExplainerStepSx}>
                        <Typography variant='h2'>
                            1. Practice
                        </Typography>
                        <Box sx={ExplainerStepCopy}>
                            The "practice" button starts the teleprompter without recording a video. You may click many times to rehearse the script before actually recording.
                        </Box>
                    </Box>
                </Box>,

                <Box sx={ExplainerStepSx}>
                    <Typography variant='h2'>
                        2. Record
                    </Typography>
                    <Box sx={ExplainerStepCopy}>
                        When ready to record, click the "Record" button which starts a short countdown. The "Restart" button lets you quickly redo if you made a mistake.
                    </Box>
                </Box>,

                <Box sx={ExplainerStepSx}>
                    <Typography variant='h2'>
                        3. Post Recording
                    </Typography>
                    <Box sx={ExplainerStepCopy}>
                        Once recording is finished, click the "Done" button to review the clip before you save it.
                    </Box>
                </Box>,
            ]
            }
        />

        <Grid item xs={12} sx={Header} >
            <XJBreadcrumbs />
            <Box sx={HeaderRightButtons} >
                <XJButton variant={'tertiary'} onClick={onCancel}>
                    Cancel
                </XJButton>

                <XJButton hidden={step == RecordingStep.VideoUploadFailed} disabled={step !== RecordingStep.videoReady || isUploading} variant={'secondary'} onClick={onSave}>
                    Save
                </XJButton>

                {blob && <XJButton hidden={step !== RecordingStep.VideoUploadFailed} variant={'secondary'} onClick={() => { UploadVideo(blob) }}>
                    Retry
                </XJButton>}
            </Box>
        </Grid>

        <Grid item xs={12}>
            <Box sx={PageTitle}>
                <Typography variant='h1'>
                    Record
                </Typography>
            </Box>
        </Grid>

        <Grid display={!hasVideo ? 'block' : 'none'} item xs={12}>
            <Box ref={FullScreenElement} sx={classes.fullscreenElement}>
                <Box sx={classes.TopContainer}>
                    <Box display={inFullScreen ? 'block' : 'none'} sx={classes.VideoMonitorContainer}>
                        {/* Video component for the record showing real time */}
                        <Box sx={{
                            width: '200px',
                            height: '130px',
                            backgroundColor: 'white !important'
                        }}>
                            <VideoMonitor
                                ref={(ref) => { if (ref) recorder = ref }}
                                cameraID={selectedCamera}
                                onReady={onRecorderReady}
                                onComplete={onRecordFinished}
                                onCameraOn={onCameraOn}
                                onCameraOff={onCameraOff}

                                onRecordingStarted={onRecorderStarted}
                                onRecordingStopped={onRecorderStopped}
                                onCameraSwitch={onCameraSwitch}

                                key={`recorder${recorderKey}`}
                              
                                isRecording={isRecording}
                                step={step}

                            />
                           
                        </Box>
                    </Box>

                    <Box sx={classes.TeleprompterRecordingControls }>
                        <Teleprompter
                            content={segment.Script}
                            // eslint-disable-next-line 
                            key='teleprompter'
                            speedDelay={speed}
                            ref={c => { if (c) teleprompter = c }}
                            inFullScreen={inFullScreen}
                        />

                        {/*   <Box display={'flex'} gap={8}>
                            <Slider
                                aria-label="Custom marks"
                                max={4}
                                min={1}
                                valueLabelDisplay={'off'}
                                value={speed}
                                onChangeCommitted={(e, v) => {

                                    if (typeof v === 'number') {

                                        setSpeed(v)

                                    }
                                }}
                                marks={TeleprompterSpeedmarks} />

                            <XJButton Variant={'tertiary'} onClick={() => { teleprompterHandle.check() }}>Check</XJButton>

                        </Box> */}
                        {VideoControls()}
                        <Box display={inFullScreen ? 'block' : 'none'} >
                            {/* <StopOnFinish></StopOnFinish> */}
                            <Countdown seed={countdownSeed} onCountdownFinished={StartRecording} key={'countdown'} ></Countdown>
                        </Box>
                    </Box>

                    <Box display={inFullScreen ? 'block' : 'none'} sx={classes.DoneButton}>
                        <XJButton variant={'secondary'} onClick={onDoneClick} onFocus={onFocus} onBlur={onBlur}>
                            Done
                        </XJButton>
                    </Box>
                </Box>
            </Box>
        </Grid>

        <Grid item xs={3}></Grid>
        <Grid item xs={6}>
            {hasVideo && <Box display={inFullScreen ? 'none' : 'block'}>   {/* // && step === Step.videoReady */}
                <VideoPlayer
                    id={"RecordPlayer"}
                    sx={{ width: "100%" }}
                    video={mapVideoModel(video!)}

                    showDefaultControls
                    showDefaultTimeline
                />

                <Box mx={'auto'} mt={16} width={'fit-content'}>
                    <XJButton
                        variant={'secondary'}
                        sx={controlSX}
                        iconLeft
                        onClick={RedoRecording}
                    >
                        <IconRestart />
                        Record again
                    </XJButton>
                </Box>
            </Box>}
        </Grid>
        <Grid item xs={4} />
    </Grid >

}