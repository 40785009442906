import { SxProps, Typography } from "@mui/material";
import { Box } from "@mui/material";
import { CustomContentProps, SnackbarContent } from "notistack"
import React from "react";
import { ToastSuccess } from "UI Elements/XJIcons";
import { HeadlineH3, secondaryDefault, secondaryLight60, whiteDefault } from "XJumpTheme";


export const styleToastComponent: SxProps =
{
    px: '24px',
    borderRadius: '2px',
    borer: '2px solid ' + secondaryLight60,
    display: 'flex' ,
    gap: '8px',
    alignItems: 'center',
    height: '40px',
    pl: '16px'
}


export const ToastSuccessComponent = React.forwardRef<HTMLDivElement, CustomContentProps>((props, ref) => {
    const {
        id,
        message,
        ...other
    } = props

    return <SnackbarContent ref={ref} role="alert" {...other}>
        <Box sx={{ ...styleToastComponent, backgroundColor: secondaryDefault, pl: '16px' }} >
            <ToastSuccess />
            <Box>
                <Typography sx={{...HeadlineH3}} color={whiteDefault}>
                    {message}
                </Typography>
            </Box>
        </Box>

    </SnackbarContent>
})

