import API from "api-axios"
import { PublicPitchDAO } from "Common/PublicPitchDAO"
import { LogError } from "Controllers/Logging"

export class PublicPitchModel {


  pitchId: number
  shortcode: string

  getPitchByShortCode = async (shortCode: string): Promise<PublicPitchDAO> => {

    try {

      const req =  await API.get('/pitch/link/', { params: { "linkid": shortCode } })

      const pitchData = req.data as PublicPitchDAO
      // let pitchData = testPublicPitchResponse
      this.pitchId = pitchData.id 
      this.shortcode = shortCode
      return pitchData

    }
    catch (err) {
      LogError(err)
      throw err
    }
  }


  getPitchById = async (id: number): Promise<PublicPitchDAO> => {

    try {

      const pitchData = await (await API.get(`/pitch/${id}/public/`)).data as PublicPitchDAO
      return pitchData

    }
    catch (err) {

      LogError(err)
      throw (err)
    }
  }

  static IsURLSecure = (url: string): boolean =>
  {

    const path = (new URL (url)).pathname
      return path.startsWith ('/secure-')
     

    }

  // Analytics will go in here too


}
/* 
const testPublicPitchResponse = {
  "id": 75,
  "title": "Tenfold",
  "tag": "",
  "round": "Test",
  "roundAsk": 0,
  "company": {
    "name": "Endure And Survive",
    "city": "Istanbul",
    "country": "Turkey",
    "state": "Iowa",
    "stage": "kek",
    "industry": "test",
    "subIndustry": "",
    "logo": "https://cdn.dribbble.com/users/975402/screenshots/18147403/media/6c59dd7adf39f43a5ae7e938a5a5f4bb.png?compress=1&resize=400x300"
  },
  "founder": {
    "name": "Alexey Metelkin",
    "linkedInURL": "https://www.linkedin.com/in/alexey-metelkin",
    "mobile": "+79614011093",
    "email": "alexey.v.metelkin@gmail.com",
    "title": "Coder"
  },
  "segments": [
    {
      "seq": 0,
      "duration": 42.72,
      "title": "Intro",
      "videoPlayListURL": "https://dev.video.pitchura.com/0-38d5d5fb-5a09-4591-86a4-7f56467ac3a7-date-15-2-2023-time-10-35-30.m3u8",
      "visualAids": [
        {
          "type": 1,
          "startAt": 6.087626,
          "duration": 7,
          "URL": "https://dev.visualaid.pitchura.com/1-a1d7932e-2344-4c7a-abba-7e21f69272bb-date-15-2-2023-time-19-22-51.JPG",
          "PIPPosition": 1,
          "showPIP": true
        },
        {
          "type": 1,
          "startAt": 28.92137,
          "duration": 4.77,
          "URL": "https://dev.visualaid.pitchura.com/1-de8b0ae8-4259-41b1-8118-ce0a0350462b-date-15-2-2023-time-19-23-33.JPG",
          "PIPPosition": 3,
          "showPIP": true
        }
      ]
    },
    {
      "seq": 1,
      "duration": 29.34,
      "title": "Problem",
      "videoPlayListURL": "https://dev.video.pitchura.com/0-a217163d-5b91-44da-95aa-c511f9255988-date-15-2-2023-time-10-37-40.m3u8",
      "visualAids": [
        {
          "type": 1,
          "startAt": 16.66547,
          "duration": 8,
          "URL": "https://dev.visualaid.pitchura.com/1-ae4e0c48-aed1-4dce-8f0c-59c956a57343-date-15-2-2023-time-19-26-5.JPG",
          "PIPPosition": 0,
          "showPIP": true
        },
        {
          "type": 1,
          "startAt": 2.762959,
          "duration": 5,
          "URL": "https://dev.visualaid.pitchura.com/1-cf8e43c0-cec6-46cb-9404-6dbb31fdc443-date-15-2-2023-time-19-25-33.JPG",
          "PIPPosition": 2,
          "showPIP": false
        }
      ]
    },
    {
      "seq": 2,
      "duration": 34.32,
      "title": "Problem 2",
      "videoPlayListURL": "https://dev.video.pitchura.com/0-38906969-6868-400c-ba79-86993627c24a-date-15-2-2023-time-10-39-40.m3u8",
      "visualAids": [
        {
          "type": 1,
          "startAt": 19.76127,
          "duration": 7,
          "URL": "https://dev.visualaid.pitchura.com/1-b4138b1a-4a25-4449-8663-58617a6b25e2-date-15-2-2023-time-19-27-17.JPG",
          "PIPPosition": 2,
          "showPIP": false
        },
        {
          "type": 1,
          "startAt": 4.514439,
          "duration": 6,
          "URL": "https://dev.visualaid.pitchura.com/1-7c0ffb48-88cc-4704-a1df-74e01d648a8f-date-15-2-2023-time-19-27-50.JPG",
          "PIPPosition": 2,
          "showPIP": false
        }
      ]
    },
    {
      "seq": 3,
      "duration": 35.642,
      "title": "Problem 3",
      "videoPlayListURL": "https://dev.video.pitchura.com/0-8652a28b-5c24-4289-9e93-050fbb271cad-date-15-2-2023-time-10-43-31.m3u8",
      "visualAids": [
        {
          "type": 1,
          "startAt": 22.32287,
          "duration": 5,
          "URL": "https://dev.visualaid.pitchura.com/1-6b175e94-c2f8-4b6b-99a5-36f83a1e8074-date-15-2-2023-time-19-32-15.png",
          "PIPPosition": 2,
          "showPIP": false
        },
        {
          "type": 1,
          "startAt": 2.248946,
          "duration": 3,
          "URL": "https://dev.visualaid.pitchura.com/1-547d13a3-e2f1-4806-82c7-9f3fb28754f3-date-15-2-2023-time-19-29-34.gif",
          "PIPPosition": 3,
          "showPIP": true
        },
        {
          "type": 1,
          "startAt": 12.41343,
          "duration": 4,
          "URL": "https://dev.visualaid.pitchura.com/1-82d008cd-27c7-40b6-bfda-48ee7b89f553-date-15-2-2023-time-19-30-33.png",
          "PIPPosition": 2,
          "showPIP": false
        }
      ]
    },
    {
      "seq": 4,
      "duration": 37.288,
      "title": "Problem 4",
      "videoPlayListURL": "https://dev.video.pitchura.com/0-eae48921-37de-473c-b0fb-98080e7aefd8-date-15-2-2023-time-10-45-44.m3u8",
      "visualAids": [
        {
          "type": 1,
          "startAt": 25.88939,
          "duration": 3,
          "URL": "https://dev.visualaid.pitchura.com/1-fa346f99-58a0-4241-8472-22b4f6a29fbb-date-15-2-2023-time-20-20-55.jpeg",
          "PIPPosition": 2,
          "showPIP": false
        },
        {
          "type": 1,
          "startAt": 14.36952,
          "duration": 2,
          "URL": "https://dev.visualaid.pitchura.com/1-955cae8e-7271-40b3-97db-10a5f5a1bc8e-date-15-2-2023-time-20-20-23.png",
          "PIPPosition": 0,
          "showPIP": true
        },
        {
          "type": 1,
          "startAt": 20.06883,
          "duration": 2,
          "URL": "https://dev.visualaid.pitchura.com/1-81758473-af06-4392-8de7-e3ada57e46df-date-15-2-2023-time-20-20-39.jpeg",
          "PIPPosition": 2,
          "showPIP": true
        },
        {
          "type": 1,
          "startAt": 2,
          "duration": 5,
          "URL": "https://dev.visualaid.pitchura.com/1-54a3bd9d-6281-4eaf-bf9c-227c86790f09-date-15-2-2023-time-20-8-37.jpeg",
          "PIPPosition": 3,
          "showPIP": true
        }
      ]
    },
    {
      "seq": 5,
      "duration": 36.548,
      "title": "Problem 5",
      "videoPlayListURL": "https://dev.video.pitchura.com/0-5551f58c-4209-467b-8f79-03b78edd86cb-date-15-2-2023-time-10-47-5.m3u8",
      "visualAids": [
        {
          "type": 1,
          "startAt": 11.94496,
          "duration": 4,
          "URL": "https://dev.visualaid.pitchura.com/1-b55c8972-0f73-491b-846a-b0b33d8804f0-date-15-2-2023-time-20-23-13.jpeg",
          "PIPPosition": 1,
          "showPIP": true
        },
        {
          "type": 1,
          "startAt": 1,
          "duration": 3,
          "URL": "https://dev.visualaid.pitchura.com/1-7ecec3ed-a368-4e97-b72e-7ac4f9b47d55-date-15-2-2023-time-20-22-51.png",
          "PIPPosition": 3,
          "showPIP": true
        },
        {
          "type": 1,
          "startAt": 25.61331,
          "duration": 5,
          "URL": "https://dev.visualaid.pitchura.com/1-bfd1fbe8-4285-4304-8263-082081b0c2b7-date-15-2-2023-time-20-23-35.jpeg",
          "PIPPosition": 2,
          "showPIP": false
        }
      ]
    },
    {
      "seq": 6,
      "duration": 35.58,
      "title": "Problem 6",
      "videoPlayListURL": "https://dev.video.pitchura.com/0-a6a71f9e-e105-4201-bff8-f4a99e80214a-date-15-2-2023-time-10-48-14.m3u8",
      "visualAids": [
        {
          "type": 1,
          "startAt": 25.05063,
          "duration": 5,
          "URL": "https://dev.visualaid.pitchura.com/1-dfa34030-6944-4630-a0e4-d0b5b3c3f587-date-15-2-2023-time-20-25-15.jpeg",
          "PIPPosition": 2,
          "showPIP": false
        },
        {
          "type": 1,
          "startAt": 3.760487,
          "duration": 4,
          "URL": "https://dev.visualaid.pitchura.com/1-78c4fb0d-c354-4cae-9fff-5b2f14b13fe2-date-15-2-2023-time-20-24-29.jpeg",
          "PIPPosition": 2,
          "showPIP": true
        },
        {
          "type": 1,
          "startAt": 13.71132,
          "duration": 4,
          "URL": "https://dev.visualaid.pitchura.com/1-f70f5145-f57d-4c58-a629-88e50a78b6e9-date-15-2-2023-time-20-24-55.jpeg",
          "PIPPosition": 1,
          "showPIP": true
        }
      ]
    },
    {
      "seq": 7,
      "duration": 33.091,
      "title": "Problem 7",
      "videoPlayListURL": "https://dev.video.pitchura.com/0-047ec63d-a152-4169-aec0-fd5ba3e0aecd-date-15-2-2023-time-10-49-17.m3u8",
      "visualAids": [
        {
          "type": 1,
          "startAt": 4.681165,
          "duration": 3,
          "URL": "https://dev.visualaid.pitchura.com/1-38d52b2f-c735-4ade-8780-a2603e52f4cc-date-15-2-2023-time-20-26-3.jpeg",
          "PIPPosition": 0,
          "showPIP": true
        },
        {
          "type": 1,
          "startAt": 23.1906,
          "duration": 5,
          "URL": "https://dev.visualaid.pitchura.com/1-7f3d4fbb-b22c-4457-9118-85200f0f7eda-date-15-2-2023-time-20-26-56.jpeg",
          "PIPPosition": 1,
          "showPIP": true
        },
        {
          "type": 1,
          "startAt": 13.29021,
          "duration": 3,
          "URL": "https://dev.visualaid.pitchura.com/1-8ab152e6-2893-46eb-9f4a-4d26bbbae036-date-15-2-2023-time-20-26-31.jpeg",
          "PIPPosition": 2,
          "showPIP": false
        }
      ]
    },
    {
      "seq": 8,
      "duration": 32.658,
      "title": "Problem 8",
      "videoPlayListURL": "https://dev.video.pitchura.com/0-e08d07a5-47ae-48b0-a753-a733cc20847f-date-15-2-2023-time-10-50-18.m3u8",
      "visualAids": [
        {
          "type": 1,
          "startAt": 5.522653,
          "duration": 4,
          "URL": "https://dev.visualaid.pitchura.com/1-69b24b58-47e5-4917-a787-65dfc4cb7577-date-15-2-2023-time-20-29-3.jpeg",
          "PIPPosition": 0,
          "showPIP": true
        },
        {
          "type": 1,
          "startAt": 25.59538,
          "duration": 4,
          "URL": "https://dev.visualaid.pitchura.com/1-59387134-e947-473c-b357-d8f1ea876f36-date-15-2-2023-time-20-32-22.png",
          "PIPPosition": 2,
          "showPIP": true
        },
        {
          "type": 1,
          "startAt": 15.93073,
          "duration": 4,
          "URL": "https://dev.visualaid.pitchura.com/1-7fad092b-9a45-4342-a1c0-95a34cba9f98-date-15-2-2023-time-20-29-32.jpeg",
          "PIPPosition": 3,
          "showPIP": true
        }
      ]
    },
    {
      "seq": 9,
      "duration": 37.14,
      "title": "Problem 9",
      "videoPlayListURL": "https://dev.video.pitchura.com/0-118028d0-1590-47d7-bc9e-86e629f9c0e4-date-15-2-2023-time-10-51-21.m3u8",
      "visualAids": [
        {
          "type": 1,
          "startAt": 1.748253,
          "duration": 5,
          "URL": "https://dev.visualaid.pitchura.com/1-d42b253b-9e74-4a47-9730-6715d86f4f59-date-15-2-2023-time-20-33-28.jpeg",
          "PIPPosition": 2,
          "showPIP": false
        },
        {
          "type": 1,
          "startAt": 27.35529,
          "duration": 5,
          "URL": "https://dev.visualaid.pitchura.com/1-03074bcf-de33-4095-9321-1c044ed7890b-date-15-2-2023-time-20-34-53.jpeg",
          "PIPPosition": 2,
          "showPIP": false
        },
        {
          "type": 1,
          "startAt": 11.05141,
          "duration": 3,
          "URL": "https://dev.visualaid.pitchura.com/1-7cc47ea4-a26b-4a28-b052-aff0a7001243-date-15-2-2023-time-20-33-50.jpeg",
          "PIPPosition": 2,
          "showPIP": true
        },
        {
          "type": 1,
          "startAt": 19.32488,
          "duration": 4,
          "URL": "https://dev.visualaid.pitchura.com/1-a7122c10-c95b-4afe-b840-9bcb653beb2e-date-15-2-2023-time-20-34-19.jpeg",
          "PIPPosition": 3,
          "showPIP": true
        }
      ]
    }
  ]
} */