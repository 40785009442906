import { VisualAidModel } from "Model/VisualAidModel"
import { SegmentModel } from "Model/SegmentModel"
import { getBlobDuration, uuid } from "tools/Utils"
import { VideoDAO } from "Common/VideoDAO"
import API from "api-axios"
import { MediaStatus, MediaType } from "Common/Enums"
import { cloneDeep } from "lodash"
import { VisualAidDAO } from "Common/VisualAidDAO"
import { FileUploadEntry, uploaderRefType } from "Components/XJMediaUpload"
import { LogError } from "Controllers/Logging"
import { RoutingController } from "Controllers/RoutingController"

export type VideoKeys = keyof typeof VideoModel.prototype

class VideoCheckings {
    light: boolean
    sound: boolean
    frame: boolean

    constructor() {
        this.light = false
        this.sound = false
        this.frame = false
    }

    clone() {
        return cloneDeep(this)
    }

}

export const kVisualAidDefaultDuration = 2.0
export const kVisualAidMinimumDuration = 1.0
export const kVisualAidDefaultPeriodFromStart = 1.0
export const kVisualAidMinimumPeriodFromStart = 0.0
export const kVisualAidDefaultPeriodToEnd = 1.0
export const kVisualAidMinimumPeriodToEnd = 0.0
export const kVisualAidDefaultPeriodBetweenAids = 1.0
export const kVisualAidMinimumPeriodBetweenAids = 0.0

type PositionValidityOptions = {
    ignoreVisibility?: boolean
    duration?: number
    betweenAid?: number
    fromStart?: number
    fromEnd?: number
}

export interface VisualAidURLResolve {
    id: number
    url: string
}

type UrlUpdateFunction = (url: string, aids: VisualAidURLResolve[]) => void



export function getMediaTypeFromExtension(response: Response): string {

    const goodMediaTypes = [
        'video/mp4',
        'video/quicktime',
        'video/webm',
        'audio/webm',
        'video/x-msvideo',
        'image/jpeg',
        'image/png',
        'image/gif'
    ]


    const responseContentType = response.headers.get('content-type')

    if (responseContentType) {
        if (goodMediaTypes.indexOf(responseContentType) > -1) {
            return responseContentType

        }
    }
    function stripQuery(url: string): string {
        const queryIndex = url.indexOf('?')
        return queryIndex > -1 ? url.substring(0, queryIndex) : url
    }

    const url = response.url

    const extension = stripQuery(url).split('.').pop()?.toLowerCase()

    switch (extension) {
        case 'mp4':
            return 'video/mp4'
        case 'mov':
            return 'video/quicktime'
        case 'webm':
            return 'video/webm'
        case 'weba':
            return 'audio/webm'
        case 'avi':
            return 'video/x-msvideo'
        case 'jpeg':
            return 'image/jpeg'
        case 'png':
            return 'image/png'
        case 'jpg':
            return 'image/jpeg'

        case 'gif':
            return 'image/gif'

        default:
            {

                throw new Error(`can't determine MediaType: ${url}`)
            }
    }
}

export class VideoModel {

    private _id: number
    private _duration = 0
    private _isRecorded = false
    private _recordedAt: Date | null = null
    private _checkings: VideoCheckings = new VideoCheckings()
    private _visualAids: VisualAidModel[] = []

    private _segment: SegmentModel | null = null

    // local values 

    private _localBlob: Blob | null = null
    private _localBlobUrl = ""
    private _serverUrl: string | undefined
    private _urlUpdateCallbacks: UrlUpdateFunction[] = []     // collects callback for url update subscribers 

    private _extension = ""

    public localFileName: string

    private _uploadId = ""              // used only on client to identify blob upload before video model gets saved 
    private _sentToTranscode = false    // flag that prevents sending video to transcode on every save 
    private _mediaStatus: MediaStatus = MediaStatus.undefinded
    private _fileName = ""

    private statusDateTime: Date
    private uploadTime: Date | undefined
    private uploadedBy: number

    private _loaded = false

    get loaded() {
        return this._loaded || !this._localBlobUrl.isEmpty()
    }

    get localBlob() {
        return this._localBlob
    }

    public clone(): VideoModel {
        return cloneDeep(this)
    }


    // MARK: - Getters and setters

    get id() {
        return this._id
    }

    get duration() {
        return Number(this._duration.toFixed(1))
    }

    get isRecorded() {
        return this._isRecorded
    }

    get recordedAt() {
        return this._recordedAt
    }

    get checkings() {
        return this._checkings
    }

    get allVisualAids() {
        return this._visualAids
    }

    get visualAids() {
        return this._visualAids.filter((aid) => !aid.isRemoved)
    }

    set visualAids(aids: VisualAidModel[]) {
        this._visualAids = aids
    }

    get segment() {
        return this._segment
    }

    set segment(segment: SegmentModel | null) {
        this._segment = segment
    }

    public getMediaLocalBlobUrl() {
        return this._localBlobUrl
    }

    public get url() {
        let result = ""

        result = this._localBlobUrl             // 1. return local blob
        if (!result) {
            result = this._serverUrl ?? ""      // 2. return url from DB if video isn't cached 
        }

        return result
    }


    // MARK: - Media 




    async setMedia(blob: Blob, isRecorded: boolean, recordedAt: Date, duration: number | undefined, fileName?: string): Promise<FileUploadEntry | void> {

        this._localBlob = blob
        this._localBlobUrl = URL.createObjectURL(this._localBlob)
        this._isRecorded = isRecorded
        this._recordedAt = recordedAt
        this._extension = blob.type.includes('video/webm') ? 'webm' : 'mp4'

        this._uploadId = uuid()         // every new media gets its own upload id 
        this._sentToTranscode = false
        if (fileName) this.localFileName = fileName


        if (duration) {

            this._duration = duration 
            this.mediaStatus = MediaStatus.init

            try {
                return await this.uploadMedia()
            } catch (err) {
                throw (`Video upload failed: ${fileName}`)
            }
        }

        else {

            // if we got here there was some problem with the duration input 

            LogError(`Video::setMedia failed to get duration for filename: ${fileName}`)
            
            return getBlobDuration(this._localBlob)
                .then(async (duration: number) => {
                    this._duration = duration
                    this.mediaStatus = MediaStatus.init
                    try {
                        return await this.uploadMedia()
                    } catch (err) {
                        throw (`Video upload failed: ${fileName}`)
                    }
                })
        }


    }



    get extension() {
        return this._extension
    }



    get uploadId() {
        return this._uploadId
    }

    get mediaStatus() {
        return this._mediaStatus
    }

    set mediaStatus(status: MediaStatus) {
        this._mediaStatus = status
    }

    async loadVideo() {

        // lock makes sure only one private url request goes at the time 
        // const mutex = mutexRealm.createMutex(this.id.toString())

        try {

            //await mutex.acquire()

            const data = await (await API.get(`/media/${this._id}/privateURL`)).data as { url: string, aids: VisualAidURLResolve[] }

            this.updateServerUrl(data.url, data.aids)
        }

        catch (error) {

            LogError(`GET URLS ERROR (${this._id}): \n ` + error + ` \n ` + error.json())
        }

        finally {
            // release lock 
            // mutex.release()
        }

    }

    updateServerUrl(url: string, aids: VisualAidURLResolve[]) {

        this._serverUrl = url
        aids.forEach((item) => {

            // TODO: catch errors 
            const aid = this.visualAids.find((value) => value.id === item.id)
            if (aid) {
                aid.ServerUrl = item.url
            }
        })

        this._callUrlUpdateCallbacks(url, aids)  // notify subscribers when private url received 

        fetch(url)

            .then(async (response) => {

                if (response.ok) {


                    const contentType = getMediaTypeFromExtension(response)
                    const arrayBuffer = await response.arrayBuffer()
                    this._localBlob = new Blob([arrayBuffer], { type: contentType })
                    this._localBlobUrl = URL.createObjectURL(this._localBlob)
                    this._loaded = true
                }
                else {

                    LogError(`VideoModel:setServerrURL failed to fetch: ${url}`)
                    LogError(response.statusText)
                    LogError(response.status)

                }

            })
            .catch(err => {
                LogError(err)
            })
    }

    subscribeForUrlUpdates(callback: UrlUpdateFunction) {
        if (!this._urlUpdateCallbacks.contains(callback)) {
            this._urlUpdateCallbacks.push(callback)
        }
    }

    unsubscribeForUrlUpdates(callback: UrlUpdateFunction) {
        if (this._urlUpdateCallbacks.contains(callback)) {
            this._urlUpdateCallbacks.remove(callback)
        }
    }

    private _callUrlUpdateCallbacks(url: string, aids: VisualAidURLResolve[]) {
        this._urlUpdateCallbacks.forEach(callback => {
            callback(url, aids)
        })
    }

    /*  async getVideoPublicURL() {
 
         const URL = await (await API.get(`/media/${this._id}/publicURL`)).data.url
         return URL
 
     } */



    // MARK: - API 

    public static load(video: VideoDAO, segment: SegmentModel) {
        const result = new VideoModel()
        result.segment = segment

        result._id = video.id
        result._duration = video.duration
        result._isRecorded = video.isRecorded
        result._recordedAt = new Date(video.recordedAt)

        const checkings = new VideoCheckings()
        checkings.sound = video.soundCheck
        checkings.light = video.lightCheck
        checkings.frame = video.frameCheck
        result._checkings = checkings
        result._mediaStatus = video.status as MediaStatus
        result._fileName = video.fileName
        result.uploadTime = video.uploadTime
        result.uploadedBy = video.uploadedBy


        result.loadVideo()

        if (video.visualAids) {
            video.visualAids.forEach(aid => {
                if (aid) {
                    const loadedAid = VisualAidModel.load(aid, result)
                    result.addVisualAid(loadedAid)
                }
            })
        }

        return result
    }

    private getDAO(aids?: VisualAidDAO[]): VideoDAO {
        return {
            id: this._id,
            duration: this._duration,
            isRecorded: this._isRecorded,
            recordedAt: this._recordedAt,            // TODO: check if it the correct value appears on the DB 
            soundCheck: this._checkings.sound,
            lightCheck: this._checkings.light,
            frameCheck: this._checkings.frame,
            videoSegmentId: this._segment ? this._segment.id : 0,
            status: this._mediaStatus,
            fileName: this._fileName,
            uploadTime: this.uploadTime,
            uploadedBy: this.uploadedBy,
            visualAids: aids

        } as VideoDAO
    }

    public async save() {

        let result = false

        try {

            if (!this._segment) {
                throw new Error("VideoModel Save doesn't have a segment model")
            }

            //  const mutex = mutexRealm.createMutex(this.id ? this.id.toString() : this._uploadId)

            this._segment.updateStatus()

            // locking updating API calls 
            //     await mutex.acquire()

            const data = this.getDAO()

            if (!this._id) {
                // create
                this._id = await (await API.post(`/pitch/${this._segment.pitch.id}/segment/${this._segment.id}/video/create`, data)).data.id
            } else {
                // update
                await API.post(`/pitch/${this._segment.pitch.id}/segment/${this._segment.id}/video/${this._id}/update`, data)
            }

            // filter out all removed aids that weren't synced yet, i.e added locally and removed without getting to the backend 
            this._visualAids = this.allVisualAids.filter((va) => {
                return !(va.isRemoved && (va.id === undefined))
            })

            await Promise.allSettled(
                this.allVisualAids.map(va => va.save())
            )

            // if media is just uploaded - finish uploading 
            if (this._mediaStatus === MediaStatus.uploadComplete && !this._sentToTranscode) {


                await this.startTranscoding()
                this._sentToTranscode = true

            }

            result = true

        } catch (error: any) {
            LogError("CAUGHT error on Video create/update: " + error.json())

            throw new Error(error)
        }

        /*  finally {
             // release the lock
             mutex.release()
         } */

        return result
    }

    private async startTranscoding() {

        try {

            const response = await (await API.put(RoutingController.TranscodeVideo(this))).data

            if (response.result) {
                this.mediaStatus = response.status
            }

            this.fileName = response.filename

            this.getTranscodeStatus()

        }
        catch (err) {
            LogError(err)
        }

    }

    private async getTranscodeStatus() {

        try {

            const response = await (await API.get(RoutingController.GetVideoTranscodeStatus(this))).data

            if (response.status) {
                this.mediaStatus = response.status
            }

            if (!response.isFinal) {
                setTimeout(() => {
                    this.getTranscodeStatus()
                }, 10000)
            }

        }
        catch (err) {
            LogError(err)
        }

    }






    public uploaderRef: uploaderRefType

    private uploadEntry: FileUploadEntry

    public async uploadMedia(): Promise<FileUploadEntry | void> {

        this.uploadEntry = {} as FileUploadEntry

        //  const media = this._localBlob
        return new Promise((resolve, reject) => {

            this.uploaderRef.current?.upload(


                MediaType.video,
                this.localBlob!,
                this.extension,
                this.localFileName,
                // OnComplete 
                async (entry: FileUploadEntry) => {
                    this._fileName = entry.fileName!
                    this.mediaStatus = MediaStatus.uploadComplete


                    this.statusDateTime = new Date()
                    this.uploadTime = new Date()
                    this.uploadedBy = 0  // TODO: Add logged in user id 


                    this.uploadEntry = entry

                    /*  if (this.segment) {
                        await this.save()
                    } */

                    resolve(entry)

                },
                // onError
                () => {
                    this.mediaStatus = MediaStatus.uploadError
                    reject()

                },

                // onStarted 
                () => {
                    return
                }

            )
        })

    }




    // MARK: - Checkings 

    setLightStatus(status: boolean) {
        this._checkings.light = status
    }

    setSoundStatus(status: boolean) {
        this._checkings.sound = status
    }

    setFrameStatus(status: boolean) {
        this._checkings.frame = status
    }


    // MARK: - Visual Aids 

    createVisualAid(preferredStart = 0) {

        const defaultValidityOptions: PositionValidityOptions = {
            ignoreVisibility: true,
            duration: kVisualAidDefaultDuration,
            betweenAid: kVisualAidDefaultPeriodBetweenAids,
            fromStart: kVisualAidDefaultPeriodFromStart,
            fromEnd: kVisualAidDefaultPeriodToEnd
        }

        const minimalValidityOptions: PositionValidityOptions = {
            ignoreVisibility: true,
            duration: kVisualAidMinimumDuration,
            betweenAid: kVisualAidMinimumPeriodBetweenAids,
            fromStart: kVisualAidMinimumPeriodFromStart,
            fromEnd: kVisualAidMinimumPeriodToEnd
        }

        // check for available space on the timeline 
        const findPlaceNewForAid = (aid: VisualAidModel, from = 0, minimalValues = false) => {
            const result = false

            const options = minimalValues ? minimalValidityOptions : defaultValidityOptions

            aid.startsAt = from
            aid.duration = options.duration!

            if (this.aidHasValidPosition(aid, options)) {
                return true             // The position is correct - return 
            }

            // if positions is incorect - getting closest aid to start checking space after it 
            let aidFrom: VisualAidModel | null = null
            let indexFrom = -1
            for (const anAid of this.visualAids.sort(this.sortingVisualAidsMethod())) {
                indexFrom = indexFrom + 1
                if (anAid.endsAt + options.betweenAid! > from) {
                    aidFrom = anAid
                    break
                }
            }

            // checking space after next aid until the last aid 
            while (aidFrom) {
                aid.startsAt = aidFrom.endsAt + options.betweenAid!
                if (this.aidHasValidPosition(aid, options)) {
                    return true     // Found! - return 
                }

                aidFrom = this.getNextAid(aidFrom, true)
            }

            // not found - return false 
            return result
        }

        // creates new aid 
        const aid = new VisualAidModel(this)
        aid.duration = kVisualAidDefaultDuration

        // suggest a place in the video     
        // 0. if marker is not set - start from 0, otherwise - from the marker 

        aid.startsAt = preferredStart ? preferredStart : kVisualAidDefaultPeriodFromStart

        if (!this.aidHasValidPosition(aid, defaultValidityOptions)) {
            if (!preferredStart) {
                // 1. place one second after the last one, but not closer to than 1 second to the end
                const lastAid = this.visualAids.pop()
                if (lastAid) {
                    findPlaceNewForAid(aid, lastAid.endsAt + kVisualAidDefaultPeriodBetweenAids, false)
                }
            } else {
                // try find place for default, then minimum, then default from start
                if (!findPlaceNewForAid(aid, preferredStart, false) &&
                    !findPlaceNewForAid(aid, preferredStart, true) &&
                    !findPlaceNewForAid(aid, kVisualAidDefaultPeriodFromStart, false)) {
                    // if not found - minimum from start 
                    findPlaceNewForAid(aid, 0, true)
                }
            }
        }

        if (!this.aidHasValidPosition(aid, minimalValidityOptions)) {
            // if not found - create overlapping 
            aid.duration = kVisualAidDefaultDuration
            aid.startsAt = preferredStart ? preferredStart : kVisualAidDefaultPeriodFromStart
        }

        return aid
    }

    visibleAids() {
        return this.visualAids.filter(aid => aid.isVisible)
    }

    hasVisualAid(visualAid: VisualAidModel) {
        this.visualAids.forEach(element => {
            if (element.id === visualAid.id) {
                return true
            }
        })

        return false
    }

    getPreviousAid(aid: VisualAidModel, onlyVisible = true) {
        let result: VisualAidModel | null = null
        let source = this.visualAids
        if (onlyVisible) {
            source = this.visibleAids()
        }
        const index = source.indexOf(aid)
        if (index > 0) {
            result = source[index - 1]
        }

        return result
    }

    getNextAid(aid: VisualAidModel, onlyVisible = true) {
        let result: VisualAidModel | null = null
        let source = this.visualAids
        if (onlyVisible) {
            source = this.visibleAids()
        }
        const index = source.indexOf(aid)
        if (index < source.length - 1) {
            result = source[index + 1]
        }

        return result
    }

    findVisualAidById(id: number) {
        let result: VisualAidModel | null = null
        for (const aid of this._visualAids) {
            if (aid.id === id) {
                result = aid
                break
            }
        }

        return result
    }

    aidHasValidPosition(aid: VisualAidModel, options?: PositionValidityOptions) {
        return this.isValidPositionForAid(aid, aid.startsAt, aid.duration, options)
    }

    isValidPositionForAid(aid: VisualAidModel, newStartingTime: number, newDuration: number, options?: PositionValidityOptions) {

        // options
        const kIgnoreVisibility = options?.ignoreVisibility ?? false
        const kValidDuration = options?.duration ?? kVisualAidMinimumDuration
        const kValidBetween = options?.betweenAid ?? kVisualAidMinimumPeriodBetweenAids
        const kValidFromStart = options?.fromStart ?? kVisualAidMinimumPeriodFromStart
        const kValidFromEnd = options?.fromEnd ?? kVisualAidMinimumPeriodToEnd


        // time period to start of video
        if (newStartingTime < kValidFromStart) {
            return false
        }

        // min period to end of video
        if (newStartingTime + newDuration > this._duration - kValidFromEnd) {
            return false
        }

        // Min duration 
        if (newDuration < kValidDuration) {
            return false
        }

        // unless aid visibility is ignored, hidden ads postions are always correct
        if (!kIgnoreVisibility && !aid.isVisible) {
            return true
        }

        type AidPosition = { starts: number, duration: number }

        // collects aids positions excluding hidden aid and target aid
        const aidsPosition = this.visualAids.map((mapAid: VisualAidModel) => {
            if (mapAid.localID !== aid.localID && mapAid.isVisible) {
                return { starts: mapAid.startsAt, duration: mapAid.duration }
            } else {
                return undefined
            }
        }).filter((position) => {
            return position !== undefined
        }) as AidPosition[]

        const getPreviousAidPosition = () => {
            for (let i = aidsPosition.length - 1; i >= 0; i--) {
                const position = aidsPosition[i]
                if (position.starts < newStartingTime) {
                    return position
                }
            }
            return null
        }

        const getNextAidPosition = () => {
            for (let i = 0; i < aidsPosition.length; i++) {
                const position = aidsPosition[i]
                if (position.starts >= newStartingTime) {
                    return position
                }
            }
            return null
        }

        const previousAid = getPreviousAidPosition()
        if (previousAid) {
            // min period to previous aid
            if (newStartingTime < previousAid.starts + previousAid.duration + kValidBetween) {
                return false
            }
        }

        const nextAid = getNextAidPosition()
        if (nextAid) {
            // min period to next aid 
            if ((newStartingTime + newDuration) > (nextAid.starts - kValidBetween)) {
                return false
            }
        }

        return true
    }

    addVisualAid(aid: VisualAidModel) {
        let result = false

        // checks if position of aid is correct in relation to other aids 
        if (this.aidHasValidPosition(aid)) {
            this._visualAids.push(aid)
            aid.video = this
            this.sortVisualAids()
            result = true
        }

        return result
    }

    sortVisualAids() {
        this._visualAids.sort(this.sortingVisualAidsMethod())
    }

    sortingVisualAidsMethod() {
        return (a: VisualAidModel, b: VisualAidModel) => { return a.startsAt - b.startsAt }
    }

    removeVisualAid(visualAid: VisualAidModel) {
        visualAid.isRemoved = true
    }


    // MARK: - Visual Aid actions 

    handleAidMovement(aid: VisualAidModel, newStartingTime: number) {
        // change aid position as long as it's within the timeline boundaries 
        if (newStartingTime > 0 && newStartingTime + aid.duration < this._duration) {
            aid.startsAt = newStartingTime
        }

        if (this.isValidPositionForAid(aid, newStartingTime, aid.duration)) {
            aid.hasCorrectPosition = true
        } else {
            aid.hasCorrectPosition = false
        }
    }

    finishAidMovement(aid: VisualAidModel) {
        aid.useLastCorrectPosition()
    }

    hideVisualAid(aid: VisualAidModel) {
        aid.isVisible = false
    }

    unhideVisualAid(aid: VisualAidModel) {
        let result = true

        if (this.aidHasValidPosition(aid, { ignoreVisibility: true })) {
            aid.isVisible = true
        } else {
            result = false
        }

        return result
    }

    // used to update backend-used data to avoid overriding data from the frontend 
    set fileName(fileName: string) {
        this._fileName = fileName
    }

}