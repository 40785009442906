import { Box, CSSInterpolation, Palette, SimplePaletteColorOptions, Theme, ThemeOptions } from "@mui/material";
import { createTheme, SxProps, ZIndex } from "@mui/material/styles";
import { CSSProperties } from "@mui/material/styles/createMixins"
import { Typography } from "@mui/material/styles/createTypography";
import { Search24Icon } from "UI Elements/XJIcons";


// colors 

// TODO: - remove exports and use colors from theme 

export const SVGStyle: CSSProperties = { width: '100%', height: '100%', maxWidth: '100%', objectFit: 'cover' }


// Primary default 
export const primaryDefault = '#519A7E'
export const overlayPrimary = '#5535AF'

export const secondaryButton = '#EFF3F9'

export const primaryDefaultOverlay8 = '#519282'
export const primaryDefaultOverlay16 = '#528A86'
export const primaryDefaultOverlay24 = '#52828A'
export const primaryDefaultOverlay32 = '#527A8E'

// Secondary default 
export const secondaryDefault = '#3D4147'
export const overlaySecondary = '#8F9BE5'

export const secondaryDefaultOverlay8 = '#444854'
//export const secondaryDefaultOverlay16 = 'linear-gradient(0deg, rgba(143, 155, 229, 0.16), rgba(143, 155, 229, 0.16)), #3D4147'
export const secondaryDefaultOverlay24 = '#51576D'
export const secondaryDefaultOverlay32 = '#575E7A'
export const secondaryDefaultOverlay75 = '#C8CBD0'

export const tertiaryDefault = 'rgba(231, 235, 240, 1)'

export const secondaryShade20 = 'rgba(61, 65, 71, 0.2)'
export const secondaryShade40 = 'rgba(61, 65, 71, 0.4)'
export const secondaryLight85 = '#D6D8DC'
export const secondaryLight75 = 'rgba(186, 190, 196, 1)'
export const secondaryLight60 = '#9197A1'
export const secondaryLight90 = '#E4E5E7'

// white 
export const whiteDefault = '#FFFFFF'
export const overlayWhite = '#DBDDEC'

export const whiteOverlay8 = '#FCFCFD'
export const whiteOverlay12 = '#FBFBFD'
export const whiteOverlay16 = '#F9FAFC'
export const whiteOverlay24 = '#F6F7FA'
export const whiteOverlay32 = '#F4F4F9'
export const whiteOverlay40 = '#F1F2F7'
export const whiteOverlay60 = '#EAEBF3'

// light primary 
export const lightPrimaryDefault = '#F7F7F7'
export const overlayLight = '#B4BADF'

export const lightPrimaryOverlay8 = '#F2F2F5'
export const lightPrimaryOverlay12 = '#EFF0F4'
export const lightPrimaryOverlay16 = '#ECEDF3'
export const lightPrimaryOverlay24 = '#E7E8F1'
export const lightPrimaryOverlay32 = '#E2E3EF'
export const lightPrimaryOverlay48 = '#D7DAEB'

// dark primary 
export const overlayDark = '#36394A'

export const darkPrimaryDefault = '#7382A8'
export const darkPrimaryOverlay8 = '#6E7CA0'
export const darkPrimaryOverlay24 = '#647091'
export const darkPrimaryOverlay32 = '#5F6B8A' // used in dropshadowDropdownDeafult with alhpa 0.24
export const darkPrimaryLight80 = '#C0C7D8'
export const darkPrimaryLight90 = '#E0E3EB'
export const darkPrimaryDark50 = '#62739D'


export const primaryLight90 = '#DEEDE7'
export const primaryLight94 = '#F2F8F6'


// Denotive colors 
export const denotiveErrorDefault = 'rgba(224, 85, 52, 1)'
export const denotiveErrorOverlay8 = '#D5523E'
export const denotiveErrorOverlay16 = '#CA5048'
export const denotiveErrorOverlay24 = '#BF4D52'
export const denotiveErrorOverlay32 = '#B44B5B'
export const denotiveErrorLight80 = 'rgba(241, 181, 167, 1)'
export const denotiveErrorLight90 = 'rgba(248, 218, 211, 1)'
export const denotiveErrorShade20 = 'rgba(224, 85, 52, 0.2)'

export const denotiveWarningDefault = 'rgba(236, 186, 89, 1)'
export const denotiveWarningOverlay8 = '#E0AF60'
export const denotiveWarningOverlay16 = '#D4A567'
export const denotiveWarningOverlay24 = '#C89A6E'
export const denotiveWarningOverlay32 = '#BC8F75'
export const denotiveWarningDark50 = '#E5A01A'
export const denotiveWarningLight50 = '#FAECD1'



export const denotiveInfoDefault = 'rgba(80, 150, 247, 1)'
export const denotiveInfoOverlay8 = '#508EF1'
export const denotiveInfoOverlay16 = '#5186EB'
export const denotiveInfoOverlay24 = '#517FE6'
export const denotiveInfoOverlay32 = '#5277E0'
export const denotiveInfoLight90 = 'rgba(206, 226, 253, 1)'


// dropshadow colors 

const dropshadowBase = 'rgba(200, 202, 216, 1)'     // used in dropshadowCard and dropshadowButton colors 

const dropshadowCardDefaultColor = 'rgba(200, 202, 216, 0.14)'
const dropshadowCardHoverColor = 'rgba(200, 202, 216, 0.24)'
const dropshadowCardPressedColor = 'rgba(200, 202, 216, 0.28)'

export const dropshadowButtonDefault = '0px 2px 12px 0px  rgba(200, 202, 216, 0.32)'
export const dropshadowButtonHover = '0px 2px 12px 0px rgba(200, 202, 216, 0.36)'
export const dropshadowButtonPressed = '0px 4px 30px 0px rgba(200, 202, 216, 0.4)'

const dropshadowDropdownDefaultColor = 'rgba(95, 107, 138, 0.24)'

const backdropPrimary = 'rgba(242, 242, 242, 0.9)'


// UNDOING A BREAKING CHANGE 

export const copyPrimaryRegular: CSSProperties = {

    fontFamily: 'Source Sans Pro',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '28px',
    letterSpacing: '0px',
    textAlign: 'center'

}

export const copyPrimaryBold: CSSProperties = {

    fontFamily: 'Source Sans Pro',
    fontSize: '16px',
    fontWeight: '700',
    lineHeight: '28px',
    letterSpacing: '0px',
    textAlign: 'left'

}


export const copySecondaryRegular: CSSProperties = {

    fontFamily: 'Source Sans Pro, sans-serif',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '16px'
}

export const copySecondaryBold: CSSProperties = {
    fontFamily: 'Source Sans Pro, sans-serif',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '12px',
    lineHeight: '16px'
}


// MARK: - Fonts 

// TODO: - move to the theme 

export const secondaryPrimaryBold: CSSProperties =
{
    fontFamily: 'Source Sans Pro, sans-serif',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '12px',
    lineHeight: '16px',
    color: secondaryDefault
}

export const BInfoBold: CSSProperties =
{
    fontFamily: 'Source Sans Pro, sans-serif',
    fontWeight: '700',
    fontSize: '12px',
    lineHeight: '20px',
    color: secondaryDefault
}

export const BInfoRegular: CSSProperties =
{
    fontFamily: 'Source Sans Pro, sans-serif',
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '20px',
    color: darkPrimaryDefault
}

export const P16ptRegularSourceSansPro: SxProps =
{
    fontFamily: 'Source Sans Pro',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: 0,
    textAlign: 'center'
}

export const videoPlayerTickerFont: CSSProperties =
{
    fontFamily: 'Source Sans Pro, sans-serif',
    fontStyle: 'semibold',
    fontSize: '10px',
    lineHeight: '10px',
    color: darkPrimaryLight80
}


// LANDING PAGE 


export const landingCopyPrimary: CSSProperties =
{
    fontFamily: "Source Sans Pro",
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '32px',
    color: secondaryDefault,
    marginBottom: '16px'        // as paragraph spacing 
}

export const landingCopyPrimaryBold: CSSProperties =
{
    fontFamily: "Source Sans Pro",
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '32px',
    color: secondaryDefault

}

export const landingCopySecondary: CSSProperties =
{
    fontFamily: "Source Sans Pro",
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '20px',
    textAlign: 'center',
    color: secondaryDefault,
    marginBottom: '8px'         // as paragraph spacing 
}

export const landingCopySecondaryBold: CSSProperties =
{
    fontFamily: "Source Sans Pro",
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '20px',
    textAlign: 'center',
    color: secondaryDefault
}

export const landingH1: CSSProperties =
{
    fontFamily: 'Poppins',
    fontSize: '48px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '60px',  /* 125% */
    textTransform: 'uppercase',
    textAlign: 'center',
    color: secondaryDefault
}

export const landingH2: CSSProperties =
{
    fontFamily: 'Poppins',
    fontSize: '32px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineheight: '40px', /* 125% */
    textTransform: 'uppercase',
    textAlign: 'center',
    color: secondaryDefault
}

export const landingH3: CSSProperties =
{
    fontFamily: 'Poppins',
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineheight: '28px', /* 116.667% */
    textTransform: 'uppercase',
    color: secondaryDefault
}

export const landingH4: CSSProperties =
{
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineheight: '28px', /* 116.667% */
    textTransform: 'uppercase',
    color: secondaryDefault
}

export const landingLinkPrimaryDefault: CSSProperties = 
{
    fontFamily: "Source Sans Pro",
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '32px', /* 133.333% */
    textDecorationLine: 'underline',
    color: secondaryDefault
}

export const landingLinkSecondaryDefault: CSSProperties = 
{
    fontFamily: "Source Sans Pro",
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '20px', /* 125% */
    textDecorationLine: 'underline',
    color: secondaryDefault
}

export const TeleprompterFont: CSSProperties =
{
    fontFamily: 'Source Sans Pro, sans-serif',
    fontWeight: 700,
    fontSize: '32px',
    lineHeight: '50px',
    letterSpacing: 0,
    textAlign: 'left'
}

export const CountdownFont: CSSProperties =
{
    fontFamily: 'Poppins',
    fontSize: '48px',
    fontWeight: 700,
    // lineHeight: '28px',
    letterSpacing: '0px',
    // textAlign: 'center'
}


// Global CSS Classes 

export const Header: SxProps =
{
    marginTop: 8,
    marginBottom: 16,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: '40px'
}

export const HeaderRightButtons: SxProps =
{   
    display: 'flex',
    gap: 16
}

export const PageTitle: SxProps =
{
    display: 'flex',
    gap: 8,
    marginBottom: 16,
    alignItems: 'center',
    minHeight: '48px'
}



export const HeadlineH3: SxProps = 
{
    fontFamily: 'Poppins',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '16px', 
    textTransform: 'uppercase'

}

export const MainContent: SxProps =
{
    marginTop: 16
}

export const PageExplainSubTitle: SxProps =
{
    marginBottom: 16
}


// https://iamhosseindhv.com/notistack/api


export const boxShadow = "1px 4px 30px " + dropshadowBase;

export const selectBox: SxProps =
{
    width: '512px'
}

export const selectBoxAuto: SxProps =
{
    width: 'auto',
    minWidth: '250px'
}


export const searchIconAdornment = <Box mr={4}><Search24Icon color={darkPrimaryDefault} /></Box>


// MARK: - Theme augmentation 

// Colors 
interface PrimaryPaletteColorOption extends SimplePaletteColorOptions {
    light: string
    main: string
    dark: string
    contrastText: string

    default: string
    overlay8: string
    overlay16: string
    overlay24: string
    overlay32: string
    light90: string
    light94: string
}

interface SecondaryPaletteColorOption extends SimplePaletteColorOptions {
    light: string
    main: string
    dark: string
    contrastText: string

    default: string
    overlay8: string
    overlay24: string
    overlay32: string
    light75: string
}

interface ErrorPaletteColorOption {
    default: string
    overlay8: string
    overlay16: string
    overlay24: string
    overlay32: string
    light80: string
    light90: string
    shade20: string
}

interface WarningPaletteColorOption {
    default: string
    overlay8: string
    overlay16: string
    overlay24: string
    overlay32: string
}

interface InfoPaletteColorOption {
    default: string
    overlay8: string
    overlay16: string
    overlay24: string
    overlay32: string
    light90: string
}

interface DenotivePaletteColorOption {
    error: ErrorPaletteColorOption
    warning: WarningPaletteColorOption
    info: InfoPaletteColorOption
}

export interface WhitePaletteColorOptions {
    default: string
    overlay8: string
    overlay12: string
    overlay16: string
    overlay24: string
    overlay32: string
    overlay40: string
    overlay60: string
}

export interface LightPrimaryPaletteColorOptions {
    default: string
    overlay8: string
    overlay12: string
    overlay16: string
    overlay24: string
    overlay32: string
    overlay48: string
}

export interface DarkPrimaryPaletteColorOptions {
    default: string
    overlay8: string
    overlay24: string
    overlay32: string
    light80: string
    light90: string
    dark50: string 
}

interface IPalette extends Palette {
    primary: PrimaryPaletteColorOption
    secondary: SecondaryPaletteColorOption,
    denotive: DenotivePaletteColorOption,
    white: WhitePaletteColorOptions
    lightPrimary: LightPrimaryPaletteColorOptions
    darkPrimary: DarkPrimaryPaletteColorOptions
}

// Typography
interface Copy {
    regular: SxProps
    bold: SxProps
}

interface Link {
    primary: SxProps
    secondary: SxProps
}

interface ITypography extends Typography {
    primary: Copy
    secondary: Copy
    link: Link
}

// Dropshadow 

interface DropshadowOptions {
    default: string
    hover: string
    pressed: string
}

interface Dropshadow {
    card: DropshadowOptions
    button: DropshadowOptions
    dropdown: DropshadowOptions
}

// Theme 

interface ITheme extends Theme {
    palette: IPalette
    typography: ITypography
    dropshadow: Dropshadow
    zIndex: XJZIndex
}

interface XJZIndex extends ZIndex {
    loader: number,
    player: number,
    dropdown: number 
}

interface IThemeOptions extends ThemeOptions {
    palette: IPalette
    typography: ITypography
    dropshadow: Dropshadow
    zIndex: XJZIndex
}

export const theme = createTheme({

    breakpoints:
    {
        values:
        {
            xs: 0,
            sm: 600,
            md: 905,
            lg: 1240,
            xl: 1440
        }
    },

    typography:
        {
            //styleName: copy / primary / regular;
            fontFamily: 'Source Sans Pro',
            fontSize: 16,
            fontWeightRegular: 400,
            fontWeightBold: 700,

            h1:
            {
                fontFamily: 'Poppins, sans-serif',
                fontStyle: 'normal',
                fontWeight: 700,
                fontSize: '24px',
                lineHeight: '32px',
                textTransform: 'uppercase'
            },
            h2:
            {
                fontFamily: 'Poppins',
                fontStyle: 'normal',
                fontWeight: 700,
                fontSize: '16px',
                lineHeight: '28px',
                textTransform: 'uppercase'
            },
            h3:
            {
                fontFamily: 'Poppins',
                fontStyle: 'normal',
                fontWeight: 'bold',
                fontSize: '12px',
                lineHeight: '16px',
                textTransform: 'uppercase'
            },
            subtitle1:
            {
                fontFamily: 'Source Sans Pro, sans-serif;',
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: '16px',
                lineHeight: '28px'
            },
            primary: {
                regular: {
                    fontFamily: 'Source Sans Pro',
                    fontSize: '16px',
                    fontWeight: '400',
                    lineHeight: '28px',
                    letterSpacing: '0px',
                    textAlign: 'center'
                } as SxProps,
                bold: {
                    fontFamily: 'Source Sans Pro',
                    fontSize: '16px',
                    fontWeight: '700',
                    lineHeight: '28px',
                    letterSpacing: '0px',
                    textAlign: 'left'
                } as SxProps
            },
            secondary: {
                regular: {
                    fontFamily: 'Source Sans Pro, sans-serif',
                    fontStyle: 'normal',
                    fontWeight: 'normal',
                    fontSize: '12px',
                    lineHeight: '16px'
                },
                bold: {
                    fontFamily: 'Source Sans Pro, sans-serif',
                    fontStyle: 'normal',
                    fontWeight: 'bold',
                    fontSize: '12px',
                    lineHeight: '16px'
                }
            },
            link: {
                primary: {
                    fontFamily: 'Source Sans Pro, sans-serif',
                    fontSize: '16px',
                    fontWeight: '700',
                    lineHeight: '28px',
                    letterSpacing: 0,
                    textDecoration: 'underline'
                } as SxProps,
                secondary: {
                    fontFamily: 'Source Sans Pro, sans-serif',
                    fontSize: '12px',
                    fontWeight: '700',
                    lineHeight: '16px',
                    letterSpacing: 0,
                    textDecoration: 'underline'
                } as SxProps
            }
        } as ITypography,

    palette:
        {
            primary: {
                default: primaryDefault,
                overlay8: primaryDefaultOverlay8,
                overlay16: primaryDefaultOverlay16,
                overlay24: primaryDefaultOverlay24,
                overlay32: primaryDefaultOverlay32,
                light90: primaryLight90,
                light94: primaryLight94,

                main: primaryDefault,    // main is required to comply with primary color extension / augmentation 
                dark: darkPrimaryDefault
            },
            secondary: {
                default: secondaryDefault,
                overlay8: secondaryDefaultOverlay8,
                overlay24: secondaryDefaultOverlay24,
                overlay32: secondaryDefaultOverlay32,
                light75: secondaryLight75,

                main: secondaryDefault  // main is required to comply with secondary color extension / augmentation 
            },
            denotive: {
                error: {
                    default: denotiveErrorDefault,
                    overlay8: denotiveErrorOverlay8,
                    overlay16: denotiveErrorOverlay16,
                    overlay24: denotiveErrorOverlay24,
                    overlay32: denotiveErrorOverlay32,
                    light80: denotiveErrorLight80,
                    light90: denotiveErrorLight90,
                    shade20: denotiveErrorShade20
                },
                warning: {
                    default: denotiveWarningDefault,
                    overlay8: denotiveWarningOverlay8,
                    overlay16: denotiveWarningOverlay16,
                    overlay24: denotiveWarningOverlay24,
                    overlay32: denotiveWarningOverlay32
                },
                info: {
                    default: denotiveInfoDefault,
                    overlay8: denotiveInfoOverlay8,
                    overlay16: denotiveInfoOverlay16,
                    overlay24: denotiveInfoOverlay24,
                    overlay32: denotiveInfoOverlay32,
                    light90: denotiveInfoLight90
                }
            },
            background: {
                default: lightPrimaryDefault
            },
            white: {
                default: whiteDefault,
                overlay8: whiteOverlay8,
                overlay12: whiteOverlay12,
                overlay16: whiteOverlay16,
                overlay24: whiteOverlay24,
                overlay32: whiteOverlay32,
                overlay40: whiteOverlay40,
                overlay60: whiteOverlay60
            },
            lightPrimary: {
                default: lightPrimaryDefault,
                overlay8: lightPrimaryOverlay8,
                overlay12: lightPrimaryOverlay12,
                overlay16: lightPrimaryOverlay16,
                overlay24: lightPrimaryOverlay24,
                overlay32: lightPrimaryOverlay32,
                overlay48: lightPrimaryOverlay48
            },
            darkPrimary: {
                default: darkPrimaryDefault,
                overlay8: darkPrimaryOverlay8,
                overlay24: darkPrimaryOverlay24,
                overlay32: darkPrimaryOverlay32,
                light80: darkPrimaryLight80,
                light90: darkPrimaryLight90,
                dark50: darkPrimaryDark50
            }
        } as IPalette,

    spacing: 2,

    dropshadow: {
        card: {
            default: "0px 4px 30px 0px " + dropshadowCardDefaultColor,
            hover: '0px 4px 30px 0px ' + dropshadowCardHoverColor,
            pressed: '0px 4px 8px 0px ' + dropshadowCardPressedColor
        },
        button: {
            default: dropshadowButtonDefault,
            hover: dropshadowButtonHover,
            pressed: dropshadowButtonPressed
        }, 
        dropdown: {
            default: '1px 2px 20px 0px ' + dropshadowDropdownDefaultColor
        }
    },

    zIndex: {
        appBar: 1000,
        drawer: 900,
        modal: 3000, 
        tooltip: 500,
        snackbar: 2000,
        fab: 2000,

        loader: 4000,
        player: 400,
        dropdown: 800
    }

} as IThemeOptions) as ITheme

theme.components = {
    MuiCssBaseline: {
        styleOverrides: {
            body: {
                background: lightPrimaryDefault
            }

        }
    },
    MuiButtonBase:
    {
        defaultProps: {
            // The props to change the default for.
            disableRipple: true // No more ripple!,
        },
        styleOverrides:
        {
            root:
            {
                borderRadius: '0px'
            }
        }
    },
    MuiFormControlLabel: {
        styleOverrides: {
            root: {
                color: secondaryDefault,
                ':focus-visible': {
                    outline: '2px solid ' + secondaryLight60,
                    borderRadius: '2px',
                    zIndex: 2
                },
                ':hover': {
                    backgroundColor: whiteOverlay32,
                    borderRadius: '2px'
                }, 
                ':active': {
                    backgroundColor: whiteOverlay60
                }
                
                // '& .MuiRadio-root': {
                //     width: '20px',
                //     height: '20px',
                //     padding: 0,
                //     marginRight: '12px',
                //     marginLeft: '12px',
                //     borderRadius: '50%',
                //     background: '#FF572233',
                //     border: '3px solid #FFF',
                //     boxShadow: '0 0 0 1px #FF5722',
                //     outline: '0px',
                //     ':hover': {
                //         boxShadow: '0 0 0 1px #FF5722',
                //         outline: '0px',
                //         background: '#FF572233',
                //     },
                //     "& .MuiSvgIcon-root": {
                //         opacity: 0
                //     }
                // },
                // '& .Mui-checked': {
                //     boxShadow: '0 0 0 1px red',
                //     backgroundColor: 'black',
                //     borderWidth: '0.2rem',
                // },
            },
        }
    },
    MuiCard:
    {
        styleOverrides:
        {
            root:
            {
                padding: '32px',
                borderRadius: '2px',
                boxShadow: theme.dropshadow.card.default,
                ':hover':
                {
                    boxShadow: theme.dropshadow.card.hover
                }
            }
        }
    },
    MuiCardContent:
    {
        styleOverrides:
        {
            root:
            {
                padding: 0,
                ':last-child':
                {
                    paddingBottom: 0
                }
            }
        }
    },

    MuiCardActions:
    {
        styleOverrides:
        {
            root:
            {
                padding: 0,
                width: '100%'
            }
        }
    },
    MuiIconButton:
    {
        styleOverrides:
        {
            root:
            {

            }
        }
    },
    MuiSvgIcon:
    {
        styleOverrides:
        {
            fontSizeSmall:
            {
                width: '18x !important',
                height: '18px !important'
            }
        }
    },
    MuiTab:
    {
        styleOverrides:
        {
            root:
            {
                color: darkPrimaryDefault,
                paddingTop: 8,
                paddingBottom: 12,
                paddingRight: 4,
                paddingLeft: 4,
                minWidth: 'auto',
                marginRight: 32,
                marginLeft: 0
            }
        }
    },
    MuiInputBase:
    //MuiOutlinedInput:
    {
        styleOverrides:
        {
            root:
                {
                    ...theme.typography.secondary.bold,
                    border: '1px solid ' + whiteDefault,
                    backgroundColor: whiteDefault,
                    borderRadius: '2px',
                    '.Mui-focused': 
                    {
                    },
                    '&.MuiInputBase:hover':
                    {
                        background: whiteDefault,
                        border: '1px solid ' + secondaryDefault
                    },
                    '&.Mui-error':
                    {
                        background: whiteDefault,
                        border: '1px solid ' + denotiveErrorDefault
                    }
                } as CSSInterpolation
        }
    },
    MuiMenuItem:
    {
        styleOverrides:
        {
            root:
                {
                    ...copySecondaryBold
                } as CSSInterpolation
        }
    },
    MuiOutlinedInput:
    {
        styleOverrides:
        {
            root:
            {

                border: 0
            },


            focused:
            {

            }
        }
    },
    MuiSwitch:
    {
        styleOverrides:
        {
            root:
            {
                padding: 0,
                width: '52px'
            },
            switchBase:
            {
                '&.Mui-checked': {
                    '& .MuiSwitch-thumb': {
                        backgroundColor: whiteDefault
                    },
                    '+.MuiSwitch-track': {
                        opacity: 1,
                        border: '2px solid ' + primaryDefault
                    }
                }
            },
            thumb:
            {
                borderRadius: '100px',
                backgroundColor: secondaryDefault,
                position: 'relative',
                left: '-3px',
                top: '-3px'
            },
            track:
            {
                borderRadius: '32px',
                height: '32px',
                width: '52px',
                opacity: 1,
                backgroundColor: whiteDefault,
                border: '2px solid ' + secondaryDefault
            }
        }
    },
    MuiAutocomplete:
    {
        styleOverrides:
        {
            groupLabel:
            {

                ...copySecondaryBold

            },
            groupUl:
            {
                ...copySecondaryRegular

            }

        }

    }
}

export const GridSx = {

    item:
        {
            ...theme.typography.primary.regular,
            color: secondaryDefault,
            padding: 8,
            backgroundColor: whiteDefault,
            borderBottom: '1px solid ' + lightPrimaryOverlay48,

            display: 'flex',
            alignItems: 'center',

            textAlign: 'left'
        } as SxProps,

    itemBold:
        {
            ...theme.typography.primary.bold,
            color: secondaryDefault,
            padding: 8,
            backgroundColor: whiteDefault,
            borderBottom: '1px solid ' + lightPrimaryOverlay48,
            textAlign: 'left',
            display: 'flex',
            alignItems: 'center',
        } as SxProps,

    header:
        {
            padding: 8,
            textAlign: 'left',
            color: darkPrimaryDefault
        } as SxProps,

    itemDashboardButton:
    {
        "& Button":
        {
            marginY: 0,
            marginLeft: 4,
            marginRight: 4
        },
        paddingY: 6,
        paddingRight: 10
    }
}
