import { Box, OutlinedInput, SelectChangeEvent, SxProps, Typography } from "@mui/material"
import { ChangeEvent, Dispatch, useState } from "react"
import PhoneInput from "react-phone-input-2"
import 'react-phone-input-2/lib/style.css'
import { denotiveErrorDefault, secondaryDefault, copySecondaryRegular, copySecondaryBold } from "XJumpTheme"
import { XJSelect } from "./XJSelect"

interface IPropsXJInputBox {
    name: string,
    value: object | string,
    setValue: Dispatch<React.SetStateAction<object | string | undefined>>,
    ref?: React.RefObject<HTMLInputElement>,
    width?: number | string,
    options?: string[],
    validationError?: string,
    placeholder?: string
    phoneInput?: boolean,
    SetDirty?: () => void
    rows?: number
    autofocus?: boolean,
    sx?: SxProps
    onChange?: (value: string) => void
    startAdornment?: JSX.Element,
    actionIcon?: JSX.Element
}

export default function XJInputBox(props: IPropsXJInputBox) {

    const isValidationError = () => {

        if (props.validationError) {
            if (props.validationError.length > 0) {
                return true
            }

        }

        return false

    }

    const styles =
    {
        label:
        {

            textTransform: 'uppercase',
            color: !isValidationError() ? secondaryDefault : denotiveErrorDefault,
            fontWeight: 700,
            fontFamily: 'Poppins',
            fontSize: '12px',
            lineHeight: '16px',
            fontStyle: 'normal'

        }
    }

    const [errorMsg, setErrorMsg] = useState('')

    const formName = props.name as keyof typeof props.value



    const setValue = props.setValue
    let fieldValue = ''

    if (props.value && props.value[formName]) {
        fieldValue = props.value[formName]
    }
    else if (!props.value && props.options) {
        fieldValue = props.options[0]
    }
    else if (typeof (props.value) === 'string') {

        fieldValue = props.value
    }



    const onHandleChange = (event: ChangeEvent<HTMLInputElement>): void => {

        const value = event.currentTarget.value

        setValue({ ...props.value as Record<string, never>, [formName]: value })
        if (props.SetDirty) props.SetDirty()

        if (props.onChange) props.onChange(value)
    }



    /* const onHandleBlur = (event: React.FocusEvent<HTMLInputElement>) => {

        const value = event.target.value

        /*   if (event.relatedTarget && event.relatedTarget?.nodeName !== 'INPUT')
          {
              return
          } */

    /* if (props.error && props.validationError) {
        setErrorMsg(props.validationError)

    }
    else {
        setErrorMsg('')

    } */

    /*  setValue({ ...props.value, [formName]: value })
     if (props.SetDirty) props.SetDirty() */


    const onHandleSelectChange = (event: SelectChangeEvent): void => {
        setValue({ ...props.value as object, [formName]: event.target.value })
        if (props.SetDirty) props.SetDirty()

        if (props.onChange) props.onChange(event.target.value)

    }

    const check = props.validationError?.isEmpty()




    const TextFieldInput = () => {
        return <OutlinedInput
            fullWidth={true}
            sx={{ border: 0 }}
            color={'secondary'}
            //  error={!errorMsg.isEmpty()}
            value={fieldValue}
            onChange={(onHandleChange)}
            error={isValidationError()}

            //onBlur={onHandleBlur}
            placeholder={props.placeholder}
            name={props.name}
            autoComplete='off'

            autoFocus={props.autofocus}

            ref={props.ref ? props.ref : null}
            rows={props.rows ? props.rows : undefined}
            startAdornment={props.startAdornment ? props.startAdornment : []}
        />
    }


    const PhoneInputField = () => {

        return <PhoneInput

            country={'us'}
            copyNumbersOnly
            inputStyle={{ width: '250px', height: '50px', ...copySecondaryBold }}
            dropdownStyle={{ ...copySecondaryBold }}
            value={fieldValue}


            onChange={(value) => {

                setValue({ ...props.value as Record<string, never>, [formName]: value })
                if (props.SetDirty) props.SetDirty()
            }}

        />

    }

    /*  const PhoneInput = () => {
 
         return <XJPhoneNumberInput name={'Mobile phone'} values={props.value} setValue={setValue}  />
     } */

    const SelectFieldInput = () => {

        return <>
            {props.options && <XJSelect
                sx={{ border: 0 }}
                fullwidth

                value={fieldValue}
                options={props.options}

                onChange={onHandleSelectChange}
            />}
        </>
    }

    return <>
        <Box width={props.width ? props.width : '250px'} mb={16} sx={props.sx} >
            <Box sx={{ ml: 0, mb: 8 }} >
                <Typography variant='h3' sx={styles.label}>{props.name}</Typography>
            </Box>

            <Box width={props.width ? props.width : 'auto'}>
                <Box display={'flex'} gap={4} alignItems={'center'}>
                    {props.options ? SelectFieldInput() : props.phoneInput ? PhoneInputField() : TextFieldInput()}
                    {props.actionIcon ?? null}
                </Box>
            </Box>
            <Box sx={{ ...copySecondaryRegular, pl: 8, pt: 4, height: 8, color: denotiveErrorDefault }} >
                {props.validationError}
            </Box>
        </Box>
    </>

}