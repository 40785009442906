import { SxProps, Typography } from "@mui/material";
import { Box } from "@mui/material";
import { CustomContentProps, SnackbarContent } from "notistack"
import React from "react";
import { ToastSuccess } from "UI Elements/XJIcons";
import { HeadlineH3, secondaryDefault, secondaryLight60, whiteDefault } from "XJumpTheme";
import { styleToastComponent } from "./ToastSuccessComponent";




export const ToastWorkingComponent = React.forwardRef<HTMLDivElement, CustomContentProps>((props, ref) => {
    const {
        id,
        message,
        ...other
    } = props

    return <SnackbarContent ref={ref} role="alert" {...other}>
        <Box sx={{ ...styleToastComponent, backgroundColor: secondaryDefault, pl: '32px'}} >
            
            <Box>
                <Typography sx={{...HeadlineH3}} color={whiteDefault}>
                   working...
                </Typography>
            </Box>
        </Box>

    </SnackbarContent>
})

