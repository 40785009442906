import { Box, SxProps, Typography } from "@mui/material";
import { VideoDimensions } from "Common/Enums";
import { LogError } from "Controllers/Logging";
import ReactVideoRecorder from "Pages/Video/libs/ReactVideoRecorder";
import React from "react";
import { forwardRef, Ref, useCallback, useEffect, useImperativeHandle, useRef, useState } from "react";
import { ActionCallback, ErrorCallback } from "tools/Types";
import { IconSmallRecord } from "UI Elements/XJIcons";
import { copySecondaryBold, darkPrimaryDefault, whiteDefault } from "XJumpTheme";
import CameraSelector from "../CameraSelector";
import { RecordingStep } from "./VideoRecord";

class RecorderActions {
    initialized = false
    isRecording = false
    isCameraOn = false
    onStart: ActionCallback //= () => { return }
    onPause: ActionCallback// = () => {return }
    onStop: ActionCallback //= () => { return}
    onResume: ActionCallback// = () => {return }
    TurnOnCamera: (devideID: string | null) => void
    reset: ActionCallback //= () => { return}
    switchDevice: (deviceId: string) => void
}

interface RecorderProps {
    cameraID?: string
    onReady?: ActionCallback
    onRecordingStarted?: ActionCallback
    onRecordingStopped?: ActionCallback
    onCameraSwitch?: (cameraID: string) => void
    onError?: ErrorCallback
    onComplete: (blob: Blob) => void
    onCameraOn?: () => void
    onCameraOff?: () => void,
    isRecording?: boolean,
    step: RecordingStep
}

export interface RecorderAPI {
    reset: () => void
    start: () => void
    pause: () => void
    finish: () => void
    resume: () => void
    turnOnCamera: (deviceID: string | null) => void
    switchVideoInput: (deviceID: string) => void

    duration: () => number
    state: () => RecorderActions
}

export const MINIMUM_RECORD_DURATION = 500 // ms, pausing or finishing recording before that causes crash on the Recorder component 

export const VideoMonitor = forwardRef((props: RecorderProps, ref: Ref<RecorderAPI>) => {

    type RecorderActionsRenderProps = {
        isVideoInputSupported: boolean
        isInlineRecordingSupported: boolean
        thereWasAnError: boolean
        isRecording: boolean
        isCameraOn: boolean
        streamIsReady: boolean
        isConnecting: boolean
        isRunningCountdown: boolean
        countdownTime: number
        timeLimit: number
        showReplayControls: boolean
        replayVideoAutoplayAndLoopOff: boolean
        isReplayingVideo: boolean
        useVideoInput: boolean

        onTurnOnCamera: ActionCallback
        onTurnOffCamera: ActionCallback
        onOpenVideoInput: ActionCallback
        onStartRecording: ActionCallback
        onStopRecording: ActionCallback
        onPauseRecording: ActionCallback
        onResumeRecording: ActionCallback
        onStopReplaying: ActionCallback
        onConfirm: ActionCallback
        reset: ActionCallback
        switchDevice: (deviceId: string) => void
    }

    const actionsRef = useRef<RecorderActions>(new RecorderActions())
    const actions = actionsRef.current


    // MARK: - API 

    useImperativeHandle(ref, () => ({ reset, start, pause, finish, resume, turnOnCamera, switchVideoInput, duration, state }))

    const reset = () => {
        actions.reset()
        isTimerRunning.current = false
        elapsedDuration.current = 0
        timerStart.current = Date.now()
    }

    const start = () => {

        actions.onStart()
        elapsedDuration.current = 0
        timerStart.current = Date.now()
        isTimerRunning.current = true

    }

    const pause = () => {
        if (getElapsedTime() > MINIMUM_RECORD_DURATION) {

            elapsedDuration.current = getElapsedTime()
            // timerStart.current = elapsedDuration.current
            isTimerRunning.current = false

            actions.onPause()


        }
    }

    const finish = () => {

        actions.onStop()
        elapsedDuration.current = getElapsedTime()
        // timerStart.current = elapsedDuration.current

        isTimerRunning.current = false

    }

    const resume = () => {

        isTimerRunning.current = true
        timerStart.current = Date.now()

        actions.onResume()

    }

    const turnOnCamera = (deviceID: string | null) => {
        actions.TurnOnCamera(deviceID)
    }

    const onVideoDeviceChange = (deviceID: string) => {
        if (deviceID !== selectedVideoDeviceId) {
            props.onCameraSwitch && props.onCameraSwitch(deviceID)

            setSelectedVideoDeviceId(deviceID)
        }
    }

    const switchVideoInput = (videoDeviceId: string) => {
        if (!videoDeviceId.isEmpty()) {
            actions.switchDevice(videoDeviceId)
        }
    }

    const duration = () => {
        return Math.round(elapsedDuration.current / 1000)
    }

    const state = () => {
        return actionsRef.current
    }


    // MARK: - Callbacks

    const onRecordingStart = () => {
        props.onRecordingStarted && props.onRecordingStarted()
    }

    const onRecordingStopped = () => {
        props.onRecordingStopped && props.onRecordingStopped()
    }

    const onTurnOnCamera = () => {
        props.onCameraOn && props.onCameraOn()
    }

    const onTurnOffCamera = () => {
        props.onCameraOff && props.onCameraOff()
    }

    const onRecordingComplete = (videoBlob: Blob) => {
        const callback = props.onComplete
        if (callback) {
            callback(videoBlob)
        } else {
            console.log("ERROR: No callback provided in props")
        }
    }

    const onError = (error: Error) => {
        console.log("CAMERA ERROR:")
        console.log(error)

        props.onError && props.onError(error)
    }


    // MARK: - Timer

    const TIMER_UPDATE_PERIOD = 10      // milliseconds 

    const isTimerRunning = useRef(false)

    // const [timerStorage, setTimerStorage] = useState<number>(0) // keeps timer value between pause / resume sessions 

    const elapsedDuration = useRef(0)


    const timerStart = useRef(0)     // last timer session start

    const [timerCounter, setTimerCounter] = useState(0)         // this variable is used just to keep useEffect below running 



    const getElapsedTime = () => {
        const now = Date.now()
        return elapsedDuration.current + (isTimerRunning.current ? (now - timerStart.current) : 0)
    }

    // Get video devices 
    const [videoDevices, setVideoDevices] = useState<MediaDeviceInfo[]>([])
    const [selectedVideoDeviceId, setSelectedVideoDeviceId] = useState<string>(props.cameraID ?? '')

    useEffect(() => {
        navigator.mediaDevices
            .enumerateDevices()
            .then((mediaDevices) => {
                const videoDevices = mediaDevices.filter((x) => x.kind === 'videoinput')
                setVideoDevices(videoDevices)

                if (!videoDevices.find(vd => vd.deviceId === selectedVideoDeviceId)) {
                    setSelectedVideoDeviceId('')
                    if (videoDevices.length > 0) {
                        setSelectedVideoDeviceId(videoDevices[0].deviceId)
                    }
                }
            })
            .catch(err => {

                LogError(err)

            })
            .catch(err => {
                LogError(err)
            })

    }, [])

    useEffect(() => {

        switchVideoInput(selectedVideoDeviceId)

    }, [selectedVideoDeviceId])


    const [timestamp, setTimestamp] = useState('')

    useEffect(() => {

        let intervalId: ReturnType<typeof setInterval> | null = null 

        if (isTimerRunning.current) {
            const newTimerValue = getElapsedTime()

            // Formatting 
            // Minutes
            const minutes = Math.floor((newTimerValue % (60 * 60 * 1000)) / 60000).toString().padStart(2, '0')
            // Seconds
            const seconds = Math.floor((newTimerValue % 60000) / 1000).toString().padStart(2, '0')
            // Milliseconds
            const milliseconds = (newTimerValue % 1000).toString().padStart(3, '0')
            const newTimeStamp = `${minutes}:${seconds}.${milliseconds}`

            intervalId = setInterval(() => {
                setTimerCounter(() => timerCounter + 1)
                setTimestamp(newTimeStamp)
            }, TIMER_UPDATE_PERIOD)

        }
        return () => { if (intervalId) clearInterval(intervalId) }
    }, [isTimerRunning,
        timerCounter,
        getElapsedTime
    ])

    const showCameraSelector = videoDevices.length > 1


    // MARK: - Render 

    const controls = (controlProps: RecorderActionsRenderProps) => {

        actions.isRecording = controlProps.isRecording
        actions.isCameraOn = controlProps.isCameraOn

        actions.onStart = controlProps.onStartRecording
        actions.onPause = controlProps.onPauseRecording
        actions.onStop = controlProps.onStopRecording
        actions.onResume = controlProps.onResumeRecording
       // actions.TurnOnCamera = controlProps.onTurnOnCamera
        actions.reset = controlProps.reset
        actions.switchDevice = controlProps.switchDevice

        actions.initialized = true

        if (controlProps.isCameraOn && controlProps.streamIsReady) {
            props.onReady && props.onReady()
        }
    }

    const videoViews = {
        backgroundColor: whiteDefault,
        color: darkPrimaryDefault,
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'

    } as SxProps


    const CustomeView = (text: string) => {
        return <Box sx={videoViews}>
            <Typography variant="h3">
                {text}
            </Typography>
        </Box>
    }

    return (<>
        <Box width={"100%"} height={"100%"} mb={5}>
            <ReactVideoRecorder
                key={'recorderKey'}
                cameraID={selectedVideoDeviceId}
                isOnInitially
                countdownTime={0}
                showReplayControls={false}
                replayVideoAutoplayAndLoopOff={true}
                onCameraOn={onTurnOnCamera}
                onTurnOffCamera={onTurnOffCamera}
                renderLoadingView={() => CustomeView('Loading')}
                renderErrorView={() => CustomeView('Error occurred')}

                renderActions={controls}
                
                onStartRecording={onRecordingStart}
                onPauseRecording={onRecordingStopped}
                onResumeRecording={onRecordingStart}
                // onStopReplaying={onRestart}                 // using this callback for restart
                onRecordingComplete={onRecordingComplete}
                onError={onError}
            />
        </Box>


        {showCameraSelector &&
            <CameraSelector
                devices={videoDevices}
                selectedID={selectedVideoDeviceId}
                onSelect={onVideoDeviceChange}
            />}

        <Box mx={'auto'} display={((props.isRecording || props.step === RecordingStep.pause) && timestamp !== '') ? 'flex' : 'none'} gap={4} justifyContent={'center'} mt={5}>
            <IconSmallRecord />
            <Box sx={{ ...copySecondaryBold }} >{timestamp}</Box>
        </Box>

    </>)
})
